import { createGlobalStyle } from "styled-components";
import IBMPlexSansBold from "../fonts/IBMPlexSans-Bold.ttf";
import IBMPlexSansBoldItalic from "../fonts/IBMPlexSans-BoldItalic.ttf";
import IBMPlexSansExtraLight from "../fonts/IBMPlexSans-ExtraLight.ttf";
import IBMPlexSansExtraLightItalic from "../fonts/IBMPlexSans-ExtraLightItalic.ttf";
import IBMPlexSansItalic from "../fonts/IBMPlexSans-Italic.ttf";
import IBMPlexSansLight from "../fonts/IBMPlexSans-Light.ttf";
import IBMPlexSansLightItalic from "../fonts/IBMPlexSans-LightItalic.ttf";
import IBMPlexSansMedium from "../fonts/IBMPlexSans-Medium.ttf";
import IBMPlexSansMediumItalic from "../fonts/IBMPlexSans-MediumItalic.ttf";
import IBMPlexSansRegular from "../fonts/IBMPlexSans-Regular.ttf";
import IBMPlexSansSemiBold from "../fonts/IBMPlexSans-SemiBold.ttf";
import IBMPlexSansSemiBoldItalic from "../fonts/IBMPlexSans-SemiBoldItalic.ttf";
import IBMPlexSansThin from "../fonts/IBMPlexSans-Thin.ttf";
import IBMPlexSansThinItalic from "../fonts/IBMPlexSans-ThinItalic.ttf";
import InterBold from "../fonts/Inter-Bold.ttf";
import InterLight from "../fonts/Inter-Light.ttf";
import InterMedium from "../fonts/Inter-Medium.ttf";
import InterRegular from "../fonts/Inter-Regular.ttf";
import InterSemiBold from "../fonts/Inter-SemiBold.ttf";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "InterLight";
        src: url(${InterLight}) format("opentype");
    }

    @font-face {
        font-family: "InterRegular";
        src: url(${InterRegular}) format("opentype");
    }

    @font-face {
        font-family: "InterMedium";
        src: url(${InterMedium}) format("opentype");
    }

    @font-face {
        font-family: "InterSemiBold";
        src: url(${InterSemiBold}) format("opentype");
    }

    @font-face {
        font-family: "InterBold";
        src: url(${InterBold}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansBold"
        src: url(${IBMPlexSansBold}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansBoldItalic"
        src: url(${IBMPlexSansBoldItalic}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansExtraLight"
        src: url(${IBMPlexSansExtraLight}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansExtraLightItalic"
        src: url(${IBMPlexSansExtraLightItalic}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansItalic"
        src: url(${IBMPlexSansItalic}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansLight"
        src: url(${IBMPlexSansLight}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansLightItalic"
        src: url(${IBMPlexSansLightItalic}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansMedium"
        src: url(${IBMPlexSansMedium}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansMediumItalic"
        src: url(${IBMPlexSansMediumItalic}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansRegular"
        src: url(${IBMPlexSansRegular}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansSemiBold"
        src: url(${IBMPlexSansSemiBold}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansSemiBoldItalic"
        src: url(${IBMPlexSansSemiBoldItalic}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansThin"
        src: url(${IBMPlexSansThin}) format("opentype");
    }

    @font-face {
        font-family: "IBMPlexSansThinItalic"
        src: url(${IBMPlexSansThinItalic}) format("opentype");
    }

    :root {
        --stroke-hover: #2E5D38;
        --white: #FFFFFF;
        --black: #000000;

        --error-700: #A6000C;
        --error-600: #C3001B;
        --error-300: #FD5C6B;
        --error-50: #FFE6E6;
        --error-10: #FEE2E8;

        --success-900: #175A22;
        --success-800: #217B2F;
        --success-700: #38B94C;
        --success-600: #38B94C;
        --success-500: #44D75B;
        --success-300: #84E994;
        --success-200: #A5F1B1;
        --success-50: #EBFDEE;

        --dark-grey: #616161;

        --gray-900: #00242E;
        --gray-800: #1B3B45;
        --gray-700: #36535B;
        --gray-600: #516A71;
        --gray-500: #6C8187;
        --gray-400: #87989E;
        --gray-300: #A2AFB4;
        --gray-200: #BCC6C9;
        --gray-100: #D7DDDF;
        --gray-50: #F2F4F5;

        --cinza-900: #2B2D41;
        --cinza-800: #42455F;
        --cinza-300: #DEE0ED;
        --cinza-200: #F2F4F7;

        --secondary-900: #005362;
        --secondary-800: #006C7D;
        --secondary-700: #078093;
        --secondary-600: #1692A5;
        --secondary-500: #2B9FB1;
        --secondary-400: #4DB1C1;
        --secondary-300: #70C2CF;
        --secondary-200: #93D3DD;
        --secondary-100: #B8E3EA;
        --secondary-50: #DDF2F5;

        --primary-900: #470E89;
        --primary-800: #470E89;
        --primary-500: #470E89;
        --primary-400: #470E89;
        --primary-300: #470E89;
        --primary-200: #470E89;
        --primary-100: #470E89;
        --primary: #470E89;

        --warning-600: #D2A20B;
        --warning-500: #FFBB28;
        --warning-200: #FFEB8A;
        --warning-50: #FFFCE6;
        --warning: #FFAC4B;

        --gradiente-verde-claro: linear-gradient(55.73deg, #32C050 19.37%, #67D67E 77.71%);
        --gradiente-cinza: linear-gradient(to right, #14142A, #64688B);
        --gradiente-verde-esxuro: linear-gradient(to right, #1F9833, #32C050);

        --shadow-baixo-grande: 0px 0px 1px rgba(0, 36, 46, 0.06), 0px 20px 25px -5px rgba(0, 36, 46, 0.1), 0px 10px 10px -5px rgba(0, 36, 46, 0.04);
        --shadow-baixo-media: 0px 0px 1px rgba(0, 36, 46, 0.06), 0px 10px 15px -3px rgba(0, 36, 46, 0.1), 0px 4px 6px -2px rgba(0, 36, 46, 0.05);
        --shadow-baixo-pequena: 0px 0px 1px rgba(0, 36, 46, 0.06), 0px 4px 6px -1px rgba(20, 20, 42, 0.1), 0px 2px 4px -1px rgba(20, 20, 42, 0.06);

        --primaria: #EAFFEF;

        overflow-x: hidden;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
    }

    html,
    body,
    #root {
        height: 100%;
        scroll-behavior: smooth;
        width: 100%;

        > iframe {
            display: none;
        }
    }

    html {
        scroll-behavior: smooth;

        .swal2-modal.addPlan,
        .swal2-modal.addTask {
            padding: 52px 48px;

            .swal2-title,
            .swal2-icon {
                padding: 0;
                margin-top: 0;
                margin-bottom: 20px;
            }

            .loading {
                animation: rotation 0.8s infinite linear;

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(359deg);
                    }
                }
            }

            .swal2-html-container {
                font-family: "InterRegular", sans-serif;
                font-size: 16px;
                line-height: 24px;
                color: var(--primary-900);
                margin: 0;
            }
        }

        @media (max-width: 1080px) {
            font-size: 93.75%;

            body, input, textarea, button {
                font-size: 100%;
            }
        }

        @media (max-width: 720px) {
            font-size: 87.5%;

            body, input, textarea, button {
                font-size: 100%;
            }
        }
    }

    body {
        background: #FFFFFF;
        --webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
