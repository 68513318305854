import { User } from "@sentry/react";
import { MixPanelFieldConstants } from "app/constants/mix-panel.constants";
import { addMixPanelAttributeIfDefined } from "app/contexts/authentication/tracking.helper";
import { dispatchPeopleToMixPanel } from "app/providers/event.provider";

export function getPhoneAttributes(user: User): Object {
  const mixPanelAttributes = {};

  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.PHONE,
    user.phone
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.PHONE_CALLING_CODE,
    user.calling_code
  );

  return mixPanelAttributes;
}

export function handleCompleteSignUpPeopleDispatchToMixPanel(user: User): void {
  const peopleAttributes = getPhoneAttributes(user);

  dispatchPeopleToMixPanel(peopleAttributes);
}
