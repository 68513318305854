import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  && {
    display: inline-flex;
    height: 54px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #470e89;
    text-transform: none;
    box-shadow: none;

    &:hover {
      background: #470e89;
    }

    color: #fff;
    font-family: "InterMedium", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 156%;
    letter-spacing: -0.16px;

    &:disabled,
    &[disabled] {
      color: #fff;
      cursor: not-allowed;
    }
  }
`;
