import { useContextSelector } from "use-context-selector";
import { ContentFeedbackContext } from "..";
import { ContentFeedbackContextOptions } from "../types";

export function useContentFeedback() {
  const state = useContextSelector(
    ContentFeedbackContext,
    (contentFeedback: ContentFeedbackContextOptions) => contentFeedback.state
  );

  const handleFeedbackResponse = useContextSelector(
    ContentFeedbackContext,
    (contentFeedback: ContentFeedbackContextOptions) =>
      contentFeedback.handleFeedbackResponse
  );

  return {
    state,
    handleFeedbackResponse,
  };
}
