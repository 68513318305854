import { Button, Dialog, Typography } from "@mui/material";
import { shade } from "polished";
import styled, { css } from "styled-components";

export const CustomTypography: any = styled(Typography)`
  && {
    ${({ p }: any) =>
      p &&
      css`
        font-family: "InterBold", sans-serif;
        font-size: 14px;
        line-height: 19.6px;
        color: var(--gray-600);
        padding: 0;
      `}

    ${({ h3 }: any) =>
      h3 &&
      css`
        font-family: "InterRegular", sans-serif;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -2%;
        color: var(--black);
        text-align: center;
      `}
        
        ${({ h5 }: any) =>
      h5 &&
      css`
        font-family: "InterLight", sans-serif;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -2%;
        color: #262626;
        text-align: center;
      `}
        
        ${({ bottomText }: any) =>
      bottomText &&
      css`
        font-family: "InterLight", sans-serif;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -2%;
        color: #262626;
        text-align: center;
        display: flex;
        align-items: center;
      `}
  }
`;

export const CustomDialog: any = styled(Dialog)`
  && {
    .MuiDialog-paper {
      align-items: center;
      background: var(--white);
      border: 1px solid #e2e2e2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 2.5rem;
      width: 100%;
      max-width: 30rem;
    }
  }
`;

export const CustomButton: any = styled(Button)`
  && {
    text-transform: none;
    min-height: 45px;
    outline: none;
    width: 100%;

    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    line-height: 23.12px;

    border-radius: 20px;
    padding: 10px 16px;
    text-transform: unset;
    margin-bottom: 1rem;

    &:hover {
      background: ${shade(0.1, "#470e89")};
    }

    svg {
      color: white;
    }

    ${({ variant }: any) => {
      const styles = {
        red: css`
          background: #470e89;
          color: var(--white) !important;
        `,
        white: css`
          background: var(--white) !important;
          color: #404040 !important;
          border: 1px solid #404040;
        `,
      };
      return styles[variant] || "";
    }}
  }
`;
