export const TabViewStyles = Object.freeze({
  padding: "0 !important",

  ".MuiTabs-indicator": {
    display: "none",
  },

  ".MuiTabs-scroller": {
    margin: "auto 0 !important",
    padding: "0 3px 3px 0",

    "& > div": {
      gap: "24px",
      // width: "fit-content",
    },
  },

  "&.arrows": {
    position: "relative",

    "&.displayArrow": {
      "& .MuiButtonBase-root": {
        display: "inherit",
      },
    },

    "& .MuiButtonBase-root": {
      display: "none",
      background: "rgba(0, 0, 0, 0.35)",
      position: "absolute",
      height: "97.5%",
      zIndex: "100",
      transition: "all 0.3s ease-out",

      "& .MuiSvgIcon-root": {
        fill: "white",
        width: "2.5rem",
        height: "2.5rem",
      },
    },

    "& .MuiButtonBase-root:nth-child(1)": {
      left: 0,
    },

    "& .MuiButtonBase-root:last-child": {
      right: 0,
    },
  },

  ".MuiTabs-flexContainer": {
    // overflow
  },

  ".MuiButtonBase-root": {
    // display: "none",

    svg: {
      fill: "var(--secondary-900)",
      width: "40px",
      height: "40px",
    },
  },
});
