import { User } from "app/entities/user.entity";
import { AppRoutes } from "app/enums/app-route.enum";

export function isEmailOdgoDomain(email?: string): boolean {
  const pattern = /@odgo\.com\.br$/;

  return email ? pattern.test(email) : false;
}

export function isEmpty(data: any) {
  return data === null || data === undefined || data === "";
}

function useAuthenticationHelper() {
  /**
   * @param user
   * @returns {string}
   */
  function getNextBehavior(
    user?: User,
    webviewMode?: boolean
  ): string | undefined {
    if (webviewMode) {
      return undefined;
    }

    if (isEmpty(user?.phone) || isEmpty(user?.calling_code)) {
      return AppRoutes.CompleteSignUp;
    }

    return undefined;
  }

  return {
    getNextBehavior,
  };
}

export default useAuthenticationHelper;
