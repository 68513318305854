import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const TitleTypography: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 18px;
    color: black;
  }
`;

export const TaskDocumentCard: any = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 16px;
    padding: 15px;

    border-radius: 5px;
    background: #f7f7f7;

    .checkbox {
      padding: 0;
    }
  }
`;

export const TaskDocumentContent: any = styled(Grid)`
  && {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TaskDocumentTitle: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    line-height: 140%;
    color: var(--Black, #000);
  }
`;

export const TaskDocumentLink: any = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    width: 100%;
    max-width: fit-content;
    outline: none;

    p {
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 140%;
      color: #346f39;
      text-decoration: underline;
    }
  }
`;
