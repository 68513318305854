import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { clickWithStopPropagation } from "app/helpers/click.helper";
import { NavigationLinkOptions } from "./types";

export default function NavigationLink({
  link,
  isPopoverOpen,
  handlePopoverOpen,
  handlePopoverClose,
}: NavigationLinkOptions) {
  const { href, title, className, childrenLinks, icon } = link;

  const defaultLinkOptions: Object = {
    rel: "noreferrer",
  };

  return (
    <>
      {childrenLinks ? (
        <Popover
          closeOnEsc
          closeOnBlur
          isOpen={isPopoverOpen}
          onClose={handlePopoverClose}
        >
          <PopoverTrigger>
            <span
              onMouseEnter={handlePopoverOpen}
              className="flex row gap-1 cursor-pointer text-[#4B5563]"
            >
              {title}
              {icon}
            </span>
          </PopoverTrigger>

          <PopoverContent style={{ zIndex: 1500 }}>
            <PopoverBody
              onMouseLeave={handlePopoverClose}
              onClick={(event) =>
                clickWithStopPropagation(event, handlePopoverClose)
              }
            >
              <div className="flex flex-col gap-3 py-8 px-[128px] w-screen bg-[#FFFFFF]">
                <h2 className="text-lg font-medium text=[#111827]">
                  Quero imigrar para...
                </h2>

                <div className="flex row items-center gap-8">
                  {childrenLinks.map(({ href, title, image, alt }, index) => (
                    <a key={index} href={href} {...defaultLinkOptions}>
                      <div>
                        <img src={image} alt={alt} className="rounded-xl" />
                        <p className="mt-[10px]">{title}</p>
                        <p className="text-xs font-medium text-[#8B11A5]">
                          Saber tudo
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <a href={href} className={className} {...defaultLinkOptions}>
          {title}
        </a>
      )}
    </>
  );
}
