import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledButton: any = styled(Button)`
  && {
    background: transparent;
    box-shadow: none;
    padding: 0;
    width: max-content;
    min-width: 40px;
    outline: none;

    color: var(--gray-70, #374151);
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "InterRegular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.14px;
    text-transform: none;

    &:hover {
      background: inherit;
      box-shadow: none;
    }

    span {
      margin-right: 0;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
