import { ArticleActions } from "../enums/actions.enum";
import { ArticleReducerActionOptions, ArticleReducerOptions } from "../types";

export const articleReducer = (
  prevState: ArticleReducerOptions,
  action: ArticleReducerActionOptions
): ArticleReducerOptions => {
  switch (action.type) {
    case ArticleActions.RestoreParams:
      return {
        ...prevState,
        ...action,
      };

    default:
      return prevState;
  }
};
