import { Grid } from "@mui/material";
import { TextAreaField } from "resources/assets/styles/styles";
import { InputError } from "../../input-error";
import { InputLabel } from "../../input-label";
import { TextAreaInputOptions } from "./types";

export function TextAreaInput({
  options,
  label,
  error,
}: TextAreaInputOptions): JSX.Element {
  return (
    <>
      <Grid container overflow="unset" style={{ flexDirection: "column" }}>
        {label && (
          <InputLabel
            title={label}
            inputName={options.name}
            isRequired={options.required}
          />
        )}

        <TextAreaField
          {...options}
          multiline
          rows={3}
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: {
              border: "none",
            },
          }}
        />

        {!!error && <InputError title={error} />}
      </Grid>
    </>
  );
}
