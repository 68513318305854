import { Grid } from "@mui/material";
import styled from "styled-components";

export const ComponentContainer: any = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
  }
`;

export const ComponentCard: any = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px;
    width: 100%;

    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    justify-content: center;
    align-items: left;
    border: 1px solid #dcdcdc;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
`;
