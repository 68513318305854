import { Button, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledButton: any = styled(Button)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #1f2937;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #f3f4f6;

    min-height: 40px;
    width: 100%;
    text-align: center;

    border-radius: 100px;
    border: none;
    box-shadow: none !important;
    text-transform: none;

    &:hover {
      background: #f3f4f6;
    }
  }
`;

export const Title: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 156%; /* 28.08px */
    letter-spacing: -0.18px;
  }
`;

export const CommentLabel: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #374151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 156%; /* 24.96px */
    letter-spacing: -0.16px;
  }
`;
