import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { IconButton } from "@mui/material";
import { ReactComponent as Line } from "resources/assets/icons/line.svg";
import { Container, StyledTypography } from "./styles";
import { EngageProps } from "./types";

export function Engage({
  likes,
  dislikes,
  onLike,
  onDislike,
}: EngageProps): JSX.Element {
  return (
    <Container container wrap="nowrap" spacing={1}>
      <IconButton onClick={onLike}>
        <ThumbUpAltOutlinedIcon style={{ color: "green" }} />
      </IconButton>

      <StyledTypography>{likes} Likes</StyledTypography>

      <Line
        style={{
          margin: "0px 2px 0px 8px",
        }}
      />

      <IconButton onClick={onDislike}>
        <ThumbDownAltOutlinedIcon style={{ color: "red" }} />
      </IconButton>

      <StyledTypography>{dislikes} Dislikes</StyledTypography>
    </Container>
  );
}
