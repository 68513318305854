import Slide, { SlideProps } from "@mui/material/Slide";
import { forwardRef } from "react";

const Transition = forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props}>
    {props.children}
  </Slide>
));

export default Transition;
