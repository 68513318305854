import { Grid } from "@mui/material";
import useWindowSize from "app/hooks/use-window-size.hook";
import { FC } from "react";
import Background from "resources/assets/images/background-signup-screen.png";
import { AuthenticationHeading } from "resources/components/authentication/heading";
import {
  SubtitleWelcome,
  TitleWelcome,
} from "resources/components/welcome/styles";
import { CustomBox, CustomGrid, Footer } from "./styles";
import { AuthenticationLayoutProviderOptions } from "./types";
import usePaddingHelper from "app/helpers/padding.helper";

const AuthenticationLayout: FC<AuthenticationLayoutProviderOptions> = ({
  backgroundImage,
  fixedFooterElement,
  children,
}): JSX.Element => {
  const { isMobile } = useWindowSize();
  const { horizontalPadding } = usePaddingHelper();

  return (
    <>
      <CustomBox>
        <CustomGrid container>
          <div
            style={{
              display: !isMobile ? "block" : "none",
              height: !isMobile ? "calc(100vh - 64px)" : "100px",
              width: "100%",
              overflow: "hidden",
              borderTopRightRadius: !isMobile ? "40px" : "0px",
              borderBottomRightRadius: !isMobile ? "40px" : "0px",
              backgroundImage: `url(${backgroundImage ?? Background})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        </CustomGrid>

        <CustomGrid container main flexDirection="column">
          {children}

          {fixedFooterElement && (
            <Footer
              style={{
                paddingLeft: isMobile ? horizontalPadding : "0px",
                paddingRight: isMobile ? horizontalPadding : "0px",
                paddingTop: isMobile ? "24px" : "0px",
                paddingBottom: isMobile ? "24px" : "0px",
                marginTop: !isMobile ? "32px" : "0px",
              }}
            >
              {fixedFooterElement}
            </Footer>
          )}
        </CustomGrid>
      </CustomBox>
    </>
  );
};

export default AuthenticationLayout;

export function AuthenticationElement({
  progressBarValue,
  title,
  subtitle,
  onBackButtonClick,
  children,
}: {
  progressBarValue?: number;
  title?: string | JSX.Element;
  subtitle?: string;
  onBackButtonClick?: () => void;
  children?: JSX.Element | JSX.Element[];
}): JSX.Element {
  const { isMobile } = useWindowSize();
  const width = isMobile ? undefined : "380px";

  return (
    <Grid container flexDirection={"column"} minWidth={width} maxWidth={width}>
      <AuthenticationHeading
        value={progressBarValue}
        onBackButtonClick={onBackButtonClick}
      />

      <Grid container flexDirection={"column"} gap={"8px"} mb={"24px"}>
        <TitleWelcome maxWidth={isMobile ? "90%" : "80%"}>{title}</TitleWelcome>

        <SubtitleWelcome>{subtitle}</SubtitleWelcome>
      </Grid>

      {children}
    </Grid>
  );
}
