import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton, InputAdornment, InputBase } from "@mui/material";
import { useState } from "react";
import { FormularyControl } from "resources/assets/styles/styles";
import { InputError } from "../../input-error";
import { InputLabel } from "../../input-label";
import { TextInputOptions } from "./types";

export function TextInput({
  label,
  error,
  startAdornmentIcon,
  ...props
}: TextInputOptions): JSX.Element {
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  const inputType: string | undefined =
    props.type === "password"
      ? viewPassword
        ? "text"
        : "password"
      : props.type;

  /**
   * @returns {JSX.Element}
   */
  const StartAdornment = (): JSX.Element => (
    <>
      {!!startAdornmentIcon && (
        <InputAdornment position="start">
          <IconButton aria-label="toggle" edge="start">
            {startAdornmentIcon}
          </IconButton>
        </InputAdornment>
      )}
    </>
  );

  /**
   * @returns {JSX.Element}
   */
  const EndAdornment = (): JSX.Element => (
    <>
      {props.type === "password" && (
        <InputAdornment position="end">
          <IconButton aria-label="toggle" edge="end">
            {viewPassword ? (
              <VisibilityOff onClick={() => setViewPassword(!viewPassword)} />
            ) : (
              <Visibility onClick={() => setViewPassword(!viewPassword)} />
            )}
          </IconButton>
        </InputAdornment>
      )}
    </>
  );

  return (
    <>
      <Grid container overflow="unset" style={{ flexDirection: "column" }}>
        {label && (
          <InputLabel
            title={label}
            subtitle={props.secondaryLabel}
            onSubtitleClick={props.onSecondaryLabelClick}
            inputName={props.name}
            isRequired={props.required}
          />
        )}

        <FormularyControl error={!!error} variant="standard">
          <InputBase
            {...props}
            type={inputType ?? "text"}
            startAdornment={props.startAdornment ?? <StartAdornment />}
            endAdornment={props.endAdornment ?? <EndAdornment />}
          />
        </FormularyControl>

        {!!error && <InputError title={error} />}
      </Grid>
    </>
  );
}
