import * as Sentry from "@sentry/react";
import {
  EnvironmentConfiguration,
  IS_PRODUCTION_ENVIRONMENT,
} from "configuration/environment.configuration";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import { SentryConstants } from "../constants/sentry.constants";
import api from "./api.provider";

const getUser = () => localStorage.getItem("@AILU:user");

export const dispatchGA = (data) => ReactGA.event(loadUser(data));

export const dispatchToRDStation = (data) =>
  api.post("/api/services/notify-events", data);

export const dispatchToAppcues = (event, data) =>
  window.Appcues.track(event, loadUser(data));

export const dispatchToMixpanel = (event: string, data?: any) => {
  if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
    mixpanel.track(event, data);
  }
};

export const dispatchPeopleToMixPanel = (params: any) => {
  if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
    mixpanel.people.set(params);
  }
};

export const dispatchPeopleToMixPanelOnce = (params: any) => {
  if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
    mixpanel.people.set_once(params);
  }
};

export const notify = (event, category, data) => {
  if (!IS_PRODUCTION_ENVIRONMENT) {
    return;
  }

  const userData = getUser();

  if (!userData) return;

  const user = JSON.parse(userData);
  const eventPayload = { email: user.email, ...data };

  // sending to google analitycs
  dispatchGA({ action: event, category });

  // sending to Appcues
  dispatchToAppcues(event, data);

  // sending to  Rd station
  dispatchToRDStation({ event, data: eventPayload });

  // sending to Mixpanel
  dispatchToMixpanel(event, eventPayload);
};

export const loadUser = (data) => {
  const userData = getUser();

  if (!userData) {
    return null;
  }

  const user = JSON.parse(userData);

  return {
    cf_odgo_user_id: user.id.toString(),
    cf_user_name: user.name,
    cf_user_email: user.email,
    cf_user_onesignal_id: user.onesignal_id,
    ...data,
  };
};

export const interceptorSentry = (error: any) => {
  let body = error.config?.data && JSON.parse(error.config?.data);

  if (body && body.image) {
    body = { ...body, image: `${body.image.slice(0, 500)}...` };
  }

  Sentry.withScope((scope: any) => {
    const responseDataMessage =
      error.response.data?.message || error.response.data?.error || "";
    scope.setExtras({
      [SentryConstants.PATH]: `${error.config?.baseURL}${error.config?.url}`,
      [SentryConstants.METHOD]: error.config?.method,
      [SentryConstants.BODY]: body || "",
      [SentryConstants.RESPONSE_STATUS]: error.response.status,
      [SentryConstants.RESPONSE_DATA]: error.response.data || "",
      [SentryConstants.RESPONSE_DATA_MESSAGE]: responseDataMessage,
    });
    const errorTitle = `${error.config?.url} - ${responseDataMessage}`;
    Sentry.captureMessage(errorTitle, "error");
  });
};
