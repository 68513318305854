import { ReactComponent as LinkIcon } from "resources/assets/icons/link-icon.svg";
import { ComponentCard, ComponentContainer } from "../styles";
import { OdNewsCardHeader, OdNewsCardLink, OdNewsCardTitle } from "./styles";
import { OdNewsOptions } from "./types";
import RichText from "../rich-text";

export default function OdNewsList({ items }): JSX.Element {
  return (
    <ComponentContainer>
      {items.map((item: OdNewsOptions) => (
        <ComponentCard
          key={item.title}
          sx={{ alignItems: "flex-start !important" }}
        >
          <OdNewsCardHeader>
            {item.cover && (
              <img
                src={`${process.env.REACT_APP_STRAPI_URL}${item.cover.data.attributes.url}`}
                alt="OdNewsImage"
              />
            )}

            <OdNewsCardTitle>{item.title}</OdNewsCardTitle>
          </OdNewsCardHeader>

          <RichText body={item.description} />

          <OdNewsCardLink href={item.link} rel="noreferrer" target="_blank">
            {"Confira a matéria"}
            <LinkIcon />
          </OdNewsCardLink>
        </ComponentCard>
      ))}
    </ComponentContainer>
  );
}
