export interface RequiredPropertyProps {
  prop: string;
  emptyValue: any;
}

function useObjectHelper() {
  function parseJSONOrReturnOriginal(jsonString: string): any {
    try {
      const parsedObject = JSON.parse(jsonString);

      if (parsedObject && typeof parsedObject === "object") {
        return parsedObject;
      }
    } catch (e) {}

    return jsonString;
  }

  /**
   * @param {object} entityObject
   * @param {string[]} requiredProperties
   * @returns {boolean}
   */
  function hasRequiredProperties(
    entityObject: object,
    requiredProperties: RequiredPropertyProps[]
  ): boolean {
    return requiredProperties.every(
      (requiredProperty: RequiredPropertyProps) =>
        entityObject[requiredProperty.prop] !== null &&
        entityObject[requiredProperty.prop] !== undefined &&
        entityObject[requiredProperty.prop] !== requiredProperty.emptyValue
    );
  }

  /**
   * @param originalObject
   * @param items
   * @returns {Object}
   */
  function getObjectItems(originalObject: Object, items: string[]): Object {
    const newObject = {};

    items.forEach((item) => {
      if (item in originalObject) {
        newObject[item] = originalObject[item];
      }
    });

    return newObject;
  }

  return {
    parseJSONOrReturnOriginal,
    hasRequiredProperties,
    getObjectItems,
  };
}

export default useObjectHelper;
