import { first } from "lodash";
import { useEffect, useState } from "react";
import { PhoneInputConstants } from "./constants";
import { PhoneCountryProps } from "./types";

export function findCountryByCallingCode(
  callingCode?: string
): PhoneCountryProps | undefined {
  const loadedCountry: PhoneCountryProps | undefined = first(
    PhoneInputConstants.COUNTRIES.filter(
      (country: PhoneCountryProps) => country.dialCode === callingCode
    )
  );

  return loadedCountry;
}

export function isMaskedPhoneValueValid(value: string, mask?: string): boolean {
  const expectedLength = mask?.replace(/[^9]/g, "").length;
  const actualLength = value.replace(/\D/g, "").length;

  return expectedLength === actualLength;
}

export const usePhoneComplete = (
  phoneValue: string,
  callingCodeValue?: string
) => {
  const [isComplete, setIsComplete] = useState(false);

  const loadedCountry: PhoneCountryProps | undefined =
    findCountryByCallingCode(callingCodeValue);

  useEffect((): void => {
    const isPhoneValid: boolean = isMaskedPhoneValueValid(
      phoneValue,
      loadedCountry?.mask
    );

    setIsComplete(isPhoneValid);
  }, [phoneValue, loadedCountry?.mask, callingCodeValue]);

  return {
    isComplete,
  };
};
