import { PhoneInputConstants } from "resources/components/form/inputs/phone/constants";
import { PhoneCountryProps } from "resources/components/form/inputs/phone/types";

export interface ExtractedPhoneNumber {
  number: string;
  callingCode: string;
}

function usePhoneHelper() {
  function extractCallingCodeAndNumber(
    fullNumber?: string
  ): ExtractedPhoneNumber | undefined {
    if (fullNumber) {
      // Remove todos os caracteres não numéricos do número completo
      const numericOnly = fullNumber.replace(/\D/g, "");

      // Encontra o código de chamada correspondente ao número completo
      const foundCountry = PhoneInputConstants.COUNTRIES.find((country) =>
        numericOnly.startsWith(country?.dialCode?.replace(/\D/g, ""))
      );

      if (foundCountry) {
        const callingCode = foundCountry.dialCode;
        const number = fullNumber.slice(callingCode.length).trim();

        return {
          number,
          callingCode,
        };
      }

      return {
        number: fullNumber,
        callingCode: "",
      };
    }

    return undefined;
  }

  /**
   * @param firstItem
   * @param secondItem
   * @returns {1 | -1 | 0}
   */
  function compareByPt(
    firstItem: PhoneCountryProps,
    secondItem: PhoneCountryProps
  ): 1 | -1 | 0 {
    const countryA = firstItem.pt.toUpperCase();
    const countryB = secondItem.pt.toUpperCase();

    if (countryA < countryB) {
      return -1;
    }

    if (countryA > countryB) {
      return 1;
    }

    return 0;
  }

  function mergePhoneNumber(callingCode: string, phoneNumber: string) {
    const merged = `${callingCode}${phoneNumber}`;

    return merged.trim();
  }

  function extractSimbolsAndSpaces(fullNumber?: string): string | undefined {
    if (fullNumber) {
      // Remove parênteses, espaços e outros caracteres não numéricos
      const numericOnly = fullNumber.replace(/[^\d]/g, "");

      return numericOnly;
    }

    return undefined;
  }

  return {
    compareByPt,
    mergePhoneNumber,
    extractSimbolsAndSpaces,
    extractCallingCodeAndNumber,
  };
}

export function formatCallingCode(callingCode: string): string {
  if (callingCode.includes("+")) {
    return callingCode;
  }

  return callingCode
    ? `+${callingCode}`
    : PhoneInputConstants.BRAZIL_CALLING_CODE;
}

export default usePhoneHelper;
