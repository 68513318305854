import { HelmetHandler } from "resources/components/handlers/helmet";
import { CenteredContainer } from "./styles";
import { CircularProgress } from "@mui/material";
import { circularProgressClasses } from "@mui/material/CircularProgress";

export default function SpinnerScreenPage(): JSX.Element {
  return (
    <>
      <HelmetHandler
        options={{
          title: "Ailu",
        }}
      />

      <div className="loading">
        <CenteredContainer>
          <CircularProgress
            variant="determinate"
            sx={{
              color: "#470E89",
              opacity: 0.5,
            }}
            size={60}
            thickness={4}
            value={100}
          />
          <CircularProgress
            sx={{
              color: "#470E89",

              position: "absolute",
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={60}
            thickness={4}
          />
        </CenteredContainer>
      </div>
    </>
  );
}
