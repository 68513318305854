export const AuthenticationContextConstants = Object.freeze({
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  TURN_SIGN_OUT_FLAG_OFF: "TURN_SIGN_OUT_FLAG_OFF",
  CHANGE_NEXT_BEHAVIOR: "CHANGE_NEXT_BEHAVIOR",
  CHANGE_SIGN_UP_STEP: "CHANGE_SIGN_UP_STEP",
  CHANGE_USER: "CHANGE_USER",
  CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
  RESTORE_TOKEN: "RESTORE_TOKEN",

  SIGN_UP_STEPPER_KEY: "sign_up",
  PORTUGAL_IMMIGRATION_STEPPER_KEY: "portugal_immigration",
  COMMUNITY_REASON_STEPPER_KEY: "community_reason",
});
