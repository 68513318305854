import { Avatar, Grid } from "@mui/material";
import { CustomPopover } from "resources/components/custom-popover";
import {
  AuthorContainer,
  AuthorName,
  PopoverContentContainer,
  PopoverDescriptionTypography,
  PopoverTitleTypography,
} from "./styles";

export default function AuthorsBio({ authorsBio }: any): JSX.Element {
  const urlAvatar = authorsBio.data?.attributes?.avatar?.data?.attributes?.url;

  return (
    <AuthorContainer>
      <Avatar
        src={urlAvatar ? `${process.env.REACT_APP_STRAPI_URL}${urlAvatar}` : ""}
        sx={{ width: "30px", height: "30px" }}
      />

      <Grid container alignItems={"center"} justifyContent="space-between">
        <CustomPopover
          content={
            <PopoverContentContainer>
              <PopoverTitleTypography>
                {authorsBio.data.attributes.name}
              </PopoverTitleTypography>

              <PopoverDescriptionTypography>
                {authorsBio.data.attributes.description}
              </PopoverDescriptionTypography>
            </PopoverContentContainer>
          }
        >
          <AuthorName>
            Por <u>{authorsBio.data.attributes.name}</u>
          </AuthorName>
        </CustomPopover>
      </Grid>
    </AuthorContainer>
  );
}
