export enum SocialAuthenticationTypes {
  Facebook = "facebook",
  Google = "google",
  LinkedIn = "linkedin",
}

export enum SocialAuthenticationSourceTypes {
  Mobile = "mobile",
  Web = "web",
}
