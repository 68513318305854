import { Typography } from "@mui/material";
import styled from "styled-components";

const windowHeight = window.innerHeight;

export const Title: any = styled(Typography)`
  && {
    color: #111827;
    font-family: "InterSemiBold", sans-serif;
    font-size: ${windowHeight < 1024 ? "20px" : "24px"};
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

    span {
      color: #8b11a5;
    }
  }
`;

export const FooterTitle: any = styled(Typography)`
  && {
    color: #6b7280;
    text-align: center;
    font-family: "InterRegular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
    letter-spacing: -0.12px;
  }
`;
