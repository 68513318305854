import { Grid } from "@mui/material";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { useContentFeedback } from "app/contexts/contents/feedback/hooks/content-feedback.hook";
import { Line } from "resources/assets/styles/styles";
import { Engage } from "./engage";
import { Feedback } from "./form";
import { FeedbackQuestion } from "./question";
import { SuccessMessage } from "./success-message";
import { ContentFeedbackProps } from "./types";

export function ContentFeedback({}: ContentFeedbackProps): JSX.Element {
  const { state: authenticationState } = useAuthentication();
  const { state: contentFeedbackState, handleFeedbackResponse } =
    useContentFeedback();

  const url = window.location.href;
  const shareUrl = encodeURIComponent(
    authenticationState.user?.id
      ? `${url}?referrer_id=${authenticationState.user?.id}`
      : url
  );
  const sharingTitle = encodeURIComponent(
    "Confira este conteúdo que vi na AILU!"
  );

  const whatsAppUrl = `https://wa.me/?text=${sharingTitle}%20${shareUrl}`;
  const telegramUrl = `https://t.me/share/url?url=${shareUrl}&text=${sharingTitle}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${sharingTitle}`;

  const isWrong: boolean | null | undefined =
    contentFeedbackState.contentFeedbackResponse?.attributes.is_wrong;
  const comment: string | undefined =
    contentFeedbackState.contentFeedbackResponse?.attributes.comment;

  function enableFeedbackSuccess(): boolean {
    if (isWrong === false) {
      return true;
    }

    if (isWrong === true && comment) {
      return true;
    }

    return false;
  }

  function enabledFeedbackQuestion(): boolean {
    if (isWrong === undefined || isWrong === null) {
      return true;
    }

    if (isWrong === true && !comment) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Grid mt={"32px"}>
        <Engage
          likes={contentFeedbackState.likes}
          dislikes={contentFeedbackState.dislikes}
          onLike={() =>
            authenticationState.isAuthenticated
              ? handleFeedbackResponse({
                  is_liked: contentFeedbackState.isLiked ? null : true,
                })
              : () => {}
          }
          onDislike={() =>
            authenticationState.isAuthenticated
              ? handleFeedbackResponse({
                  is_liked: contentFeedbackState.isDisliked ? null : false,
                })
              : () => {}
          }
        />

        <Line mt={"16px"} mb={"16px"} />

        {enabledFeedbackQuestion() && (
          <FeedbackQuestion
            question={"Algo errado no conteúdo?"}
            response={isWrong}
            onYes={() =>
              authenticationState.isAuthenticated
                ? handleFeedbackResponse({ is_wrong: true })
                : () => {}
            }
            onNo={() =>
              authenticationState.isAuthenticated
                ? handleFeedbackResponse({ is_wrong: false })
                : () => {}
            }
          />
        )}

        {authenticationState.isAuthenticated && (
          <>
            {isWrong === true && !comment && <Feedback />}

            {enableFeedbackSuccess() && (
              <Grid mt={"16px"}>
                <SuccessMessage
                  title={"Agradecemos seu feedback!"}
                  subtitle={
                    "Compartilhe em suas redes e ajude outras pessoas com as dicas da Ailu."
                  }
                  whatsAppUrl={whatsAppUrl}
                  telegramUrl={telegramUrl}
                  twitterUrl={twitterUrl}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
