import { Grid } from "@mui/material";
import { ComponentCard, ComponentContainer } from "../styles";
import { ItemTitle, NumberIndicator } from "./styles";
import { ListItemOptions } from "./types";
import RichText from "../rich-text";

export default function List({ items }): JSX.Element {
  return (
    <ComponentContainer>
      {items.map((item: ListItemOptions, index: any) => (
        <ComponentCard
          sx={{
            gap: "16px !important",
            p: {
              fontFamily: "'InterRegular', sans-serif",
              fontSize: "16px",
              lineHeight: "156%",
              letterSpacing: " -0.32px",
              color: "var(--primria-escuros-preto-cinza-e-branco-600, #595959)",
            },
          }}
        >
          <Grid container gap="12px">
            <NumberIndicator>
              <p>{index + 1}</p>
            </NumberIndicator>

            <ItemTitle>{item.title}</ItemTitle>
          </Grid>

          <RichText body={item.body} />
        </ComponentCard>
      ))}
    </ComponentContainer>
  );
}
