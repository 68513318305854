import useSEOHelper from "app/helpers/seo.helper";
import useStrapiHelper from "app/helpers/strapi.helper";
import { generateArticleQuery } from "app/queries/content.graphql";
import { FC, useEffect, useReducer } from "react";
import { createContext } from "use-context-selector";
import { ArticleActions } from "./enums/actions.enum";
import { useTypedQuery } from "./hooks/typed-article-query.hook";
import { articleReducer } from "./reducers/article.reducer";
import {
  ArticleContextOptions,
  ArticleProviderOptions,
  ArticleReducerOptions,
} from "./types";

export const ArticleContext = createContext<ArticleContextOptions>(
  {} as ArticleContextOptions
);

const articleInitialState: ArticleReducerOptions = {
  contentResponse: undefined,
  contentId: undefined,
  htmlMetaTags: undefined,
};

const ArticleProvider: FC<ArticleProviderOptions> = ({
  postId,
  mainAttribute,
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(articleReducer, articleInitialState);

  const { splitContentId } = useStrapiHelper();
  const contentId: string = splitContentId(postId);

  const GET_ARTICLE_BY_ID = generateArticleQuery(mainAttribute);

  const fetchPayload = {
    skip: !contentId,
    variables: {
      id: contentId,
    },
  };
  const { data: articleResponse } = useTypedQuery(
    mainAttribute,
    GET_ARTICLE_BY_ID,
    fetchPayload
  );

  const { getContentSEO } = useSEOHelper();
  const htmlMetaTags = getContentSEO(articleResponse?.[mainAttribute].data);

  useEffect(() => {
    if (articleResponse) {
      dispatch({
        type: ArticleActions.RestoreParams,
        contentId,
        htmlMetaTags,
        contentResponse: articleResponse?.[mainAttribute]?.data,
      });
    }
  }, [articleResponse]);

  return (
    <ArticleContext.Provider
      value={{
        state,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export default ArticleProvider;
