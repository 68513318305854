import { LinkingContextConstants } from "./constants";
import { LinkingReducerActionOptions, LinkingReducerOptions } from "./types";

export const linkingReducer = (
  prevState: LinkingReducerOptions,
  action: LinkingReducerActionOptions
): LinkingReducerOptions => {
  switch (action.type) {
    case LinkingContextConstants.RESTORE_PARAMS:
      return {
        ...prevState,
        utmSource: action.utmSource,
        utmCampaign: action.utmCampaign,
        gclid: action.gclid,
        fbclid: action.fbclid,
        referrerId: action.referrerId,
        referrer: action.referrer,
      };

    case LinkingContextConstants.CLEAN_PARAMS:
      return {
        ...prevState,
        utmSource: null,
        utmCampaign: null,
        gclid: null,
        fbclid: null,
        referrerId: null,
        referrer: null,
      };

    default:
      return prevState;
  }
};
