import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledTypography: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
    color: #374151;
  }
`;

export const Container: any = styled(Grid)`
  && {
    border-radius: 100px;
    border: 1px solid #e4e7eb;
    background: #fff;
    padding: 0px 8px;
    width: fit-content;
    align-items: center;
    justify-content: center;
  }
`;
