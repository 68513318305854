import * as YupSettings from "yup";

YupSettings.setLocale({
  mixed: {
    required: "Campo obrigatório",
    default: "Campo inválido",
  },
  string: {
    email: "E-mail inválido",
    min: "Verifique a quantidade mínima",
    max: "Verifique a quantidade máxima",
  },
  number: {
    min: "Campo muito curto",
    max: "Campo muito longo",
  },
});

export default YupSettings;
