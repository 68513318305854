import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { StorageKeys } from "app/enums/storage-key.enum";
import useStorageProvider from "app/providers/storage.provider";
import { NavigateFunction, useNavigate } from "react-router-dom";

function useContentHelper() {
  const navigate: NavigateFunction = useNavigate();
  const { state: authenticationState } = useAuthentication();
  const { getItem, setItem } = useStorageProvider();

  const viewedContentIds: number[] | undefined = getViewedContentIds();

  function getViewedContentIds(): number[] | undefined {
    const storageContentViewed: number[] | undefined = getItem(
      StorageKeys.ContentViewed as any
    );

    return storageContentViewed;
  }

  const categoryFilters = (categories: any[]) => {
    let filter: string = "";

    if (categories && categories.length > 0) {
      const newFilter = categories
        .map((category) => `categories.id = ${category.id}`)
        .join(" OR ");

      filter = newFilter;
    }

    return filter;
  };

  function onContentClick(Id: any, hitId: any, route: string) {
    if (!authenticationState.isAuthenticated) {
      const hasId = viewedContentIds?.find((id) => id === Id);

      let hitIds: number[] = [];

      hitIds = [...(viewedContentIds || []), Id];

      if (
        viewedContentIds === null ||
        viewedContentIds === undefined ||
        viewedContentIds?.length < 3
      ) {
        if (!hasId) {
          setItem(StorageKeys.ContentViewed as any, JSON.stringify(hitIds));
        }

        navigate(`${route}/${hitId}`, {
          state: {
            postId: hitId,
          },
        });
      }
    } else {
      navigate(`${route}/${hitId}`, {
        state: {
          postId: hitId,
        },
      });
    }
  }

  return {
    onContentClick,
    categoryFilters,
  };
}

export default useContentHelper;
