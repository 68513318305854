import { User } from "app/entities/user.entity";
import { StorageKeys } from "app/enums/storage-key.enum";
import useStorageProvider from "./storage.provider";

function useAuthenticationProvider() {
  const { getItem, setItem, multiRemove } = useStorageProvider();

  /**
   * @returns {User | undefined}
   */
  function getUser(): User | undefined {
    const user: User | undefined = getItem(StorageKeys.User as any);

    return user;
  }

  /**
   * @returns {string | null}
   */
  function getToken(): string | null {
    const token: string | null = getItem(StorageKeys.AccessToken as any);

    return token;
  }

  /**
   * @returns {void}
   */
  function setUser(user: User): void {
    setItem(StorageKeys.User as any, JSON.stringify(user));
  }

  /**
   * @returns {void}
   */
  function setToken(accessToken: string): void {
    setItem(StorageKeys.AccessToken as any, accessToken);
  }

  /**
   * @returns {void}
   */
  function createSession(user: User, accessToken: string): void {
    setUser(user);
    setToken(accessToken);
  }

  /**
   * @returns {void}
   */
  function removeSession(): void {
    const keysToRemove: any[] = [StorageKeys.AccessToken, StorageKeys.User];

    //  Remove storage items
    multiRemove(keysToRemove);
  }

  return {
    getUser,
    getToken,
    setUser,

    createSession,
    removeSession,
  };
}

export default useAuthenticationProvider;
