import { Grid, Tabs } from "@mui/material";
import { TabViewScrollableOptions } from "./types";
import { TabViewStyles } from "./styles";
import { useState } from "react";

export function TabViewScrollable({
  children,
  extraStyles,
}: TabViewScrollableOptions): JSX.Element {
  const [viewArrows, setViewArrows] = useState<any>(false);

  return (
    <Grid container flexWrap="nowrap">
      <Tabs
        value={0}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable tabs"
        className={`arrows${viewArrows ? " displayArrow" : ""}`}
        onMouseEnter={() => setViewArrows(true)}
        onMouseLeave={() => setViewArrows(false)}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
        sx={{ ...TabViewStyles, ...extraStyles }}
      >
        {children}
      </Tabs>
    </Grid>
  );
}
