import { Grid, Typography } from "@mui/material";
import { Separator } from "./styles";
import {
  AuthenticationFooterItemOptions,
  AuthenticationFooterOptions,
} from "./types";

export function AuthenticationFooter({
  items,
  gridOptions,
}: AuthenticationFooterOptions): JSX.Element {
  return (
    <>
      <Grid
        container
        {...gridOptions}
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {items.map((item: AuthenticationFooterItemOptions, index: number) => (
          <Typography
            key={index}
            variant="body1"
            sx={{
              fontFamily: "'InterMedium', sans-serif",
              fontSize: "16px",
              lineHeight: "156%",
              letterSpacing: "-0.16px",
              color: "var(--foundation-gray-gray-600, #4B5563)",
              marginTop: "26px",
              alignItems: "center",

              "@media screen and (max-width: 767px)": {
                marginTop: "24px",
              },
            }}
          >
            <span
              key={index}
              onClick={item.onClick}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {item.title && `${item.title} `}

              {item.subtitle && (
                <strong
                  onClick={item.onClick}
                  style={{
                    color: "var(--foundation-secondary-secondary-900, #8B11A5)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {item.subtitle}
                </strong>
              )}
            </span>

            {index < items.length - 1 && <Separator>{" | "}</Separator>}
          </Typography>
        ))}
      </Grid>
    </>
  );
}
