import { Typography } from "@mui/material";
import styled from "styled-components";

export const SubtitleInfo: any = styled(Typography)`
  && {
    color: rgba(75, 85, 99, 0.75);
    font-feature-settings: "clig" off, "liga" off;
    font-family: "InterMedium", sans-serif;
    font-size: 12px;
    line-height: 156%;
    letter-spacing: -0.12px;
  }
`;
