import { Box, Grid } from "@mui/material";
import styled, { css } from "styled-components";

export const CustomBox: any = styled(Box)`
  && {
    display: flex;
    flex-wrap: nowrap;

    @media screen and (max-width: 950px) {
      flex-direction: column;
    }
  }
`;

export const CustomGrid: any = styled(Grid)`
  ${({ main }: any) =>
    main &&
    css`
      flex-direction: column;

      @media screen and (min-width: 951px) {
        max-width: 64.5%;
        position: relative;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 950px) {
        background-color: #fff;
        padding: 40px 20px;
      }
    `}
`;

export const Footer = styled(Grid)`
  && {
    @media screen and (max-width: 949px) {
      border-top: 1px solid #e4e7eb;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: #fff;
      z-index: 1000;
    }
  }
`;
