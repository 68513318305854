import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const Container: any = styled(Grid)`
  && {
    padding: 24px 20px;
    border-radius: 8px;
    background: #f3f4f6;
  }
`;

export const Title: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
`;

export const Subtitle: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #6b7280;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.14px;
  }
`;
