type HTTP_METHOD = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

/**
 * HTTP Methods
 * @var HTTP_METHOD
 */
export const GET_METHOD: HTTP_METHOD = "GET";
export const POST_METHOD: HTTP_METHOD = "POST";
export const PATCH_METHOD: HTTP_METHOD = "PATCH";
export const DELETE_METHOD: HTTP_METHOD = "DELETE";
export const PUT_METHOD: HTTP_METHOD = "PUT";

/**
 * Base codes
 * @var string
 */
export const CONTENT_TYPE_KEY: string = "Content-Type";
export const ACCEPT_KEY: string = "Accept";
export const ACCESS_CONTROL_ALLOW_ORIGIN_KEY: string =
  "Access-Control-Allow-Origin";
export const CONTENT_TYPE_DEFAULT_VALUE: string = "application/json";
export const CONTENT_TYPE_MULTIPART: string = "multipart/form-data";
export const BEARER_TOKEN_PREFIX: string = "Bearer ";

/**
 * Pagination constants
 */
export const LIMIT: number = 10;

export const API: string = "/api";
export const VERSION_ONE: string = "/v1";
export const VERSION_TWO: string = "/v2";
export const URL_WHATSAPP_VALIDATION: string =
  "https://wpp-wb.odgo.com.br/is-wpp-number";

export const RestAPIConstants = Object.freeze({
  OAUTH_TOKEN: "oauth/token",

  SIGN_UP: `${API}/users/signup`,
  WHATSAPP_VALIDATION: URL_WHATSAPP_VALIDATION,

  FORGOT_PASSWORD: `${API}/users/recovery-password`,
  RESET_PASSWORD: `${API}/users/recovery-password/reset`,

  PROFILE: `${API}/users/profile`,
  CHANGE_PASSWORD: `${API}/users/change-password`,

  USER_EVENT: `${API}/users/user-event`,

  STATES: `${API}/states`,
  CITIES: `${API}/cities`,

  SIGNUP: `${API}/tools/signup`,
  ONBOARDING: `${API}/tools/onboarding`,
  USER_TASKS: `${API}/user-tasks`,
  DETAILS: "details",

  TOOLS: `${API}/tools`,
  PARTNERS: `${API}/partners`,
  USER_DETAILS: "user-details",
  SAVE_RESPONSE: "save-response",

  SUBTASKS: `${API}/subtasks`,

  UPDATE: "update",

  EXCHANGE_SERVICES: `${API}/services/exchange`,

  SETTINGS: `${API}/settings`,

  UPLOAD_FILES: `${API}/files/upload`,

  NIF: `${API}/nif`,

  GET_POSTS: `${API}/services/posts`,

  VISAS: `${API}/tools/visa`,
  VISA_DETAIL: `${API}/tools/visa`,

  REFERRED_USERS: `${API}/users/referrals`,

  POSTS: `${API}/posts`,
  POST_ALERTS: `${API}/post-alerts`,
  POST_COMMENTS: `${API}/posts/comments`,
  POST_CATEGORIES: `${API}/posts/categories`,

  PLANS: `${API}/subscriptions/plans`,
  CHECKOUT: `${API}/subscriptions/checkout`,
  SUBSCRIPTIONS: `${API}/subscriptions`,
  SUBSCRIPTIONS_INTENT: `${API}/subscriptions/intent`,
  CUSTOMER_PORTAL: `${API}/subscriptions/customer-portal`,

  UPDATE_REDIRECTOR: `${API}/users/update-redirector`,
});
