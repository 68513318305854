import { WindowConstants } from "app/constants/window.constants";
import { useState, useEffect } from "react";

export default function useWindowSize() {
  const isClient = typeof window === "object";

  function getIsMobile() {
    return window.innerWidth < 951;
  }

  function getWindowSize() {
    const queries = Object.values(WindowConstants);
    const matchingQuery = queries.find(
      ({ key, value }) => window.matchMedia(value).matches
    );
    return matchingQuery || WindowConstants.DEFAULT;
  }

  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const isBrowser = typeof window !== "undefined";
  const isDeviceMobile = /Mobi|Android/i.test(navigator.userAgent);

  useEffect((): any => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      const newIsMobile = getIsMobile();
      if (isMobile !== newIsMobile) {
        setIsMobile(newIsMobile);
      }

      const newWindowSize = getWindowSize();
      if (newWindowSize && windowSize.key !== newWindowSize.key) {
        setWindowSize(newWindowSize);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile, windowSize]);

  return {
    isMobile,
    windowSize,
    isBrowser,
    isDeviceMobile,
  };
}
