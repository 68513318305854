import useStringHelper from "app/helpers/string.helper";
import { Helmet } from "react-helmet";
import { HelmetHandlerOptions } from "./types";

const TAG_COMPONENTS: Record<string, React.ElementType> = {
  meta: "meta",
  link: "link",
};

export function HelmetHandler({
  options,
  htmlTags,
}: HelmetHandlerOptions): JSX.Element {
  const { formatPageTitle } = useStringHelper();

  return (
    <Helmet>
      <html lang="pt" />
      <meta name="google" content="notranslate" />
      <title>{formatPageTitle(options.title)}</title>

      {htmlTags?.map((item, index) => {
        const TagName = TAG_COMPONENTS[item.tag];

        return <TagName key={index} {...item.attributes} />;
      })}
    </Helmet>
  );
}
