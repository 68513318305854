import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { AppRoutes } from "app/enums/app-route.enum";
import { useMixPanelTrackPageView } from "app/hooks/mixpanel-page-view.hook";
import { LayoutWrapper } from "navigators";
import { AppRouteConstants } from "navigators/constants/app-route.constants";
import { RouteTypes } from "navigators/enums/route-type.enum";
import { AppRoute } from "navigators/types";
import CompleteSignUpPage from "pages/private/complete-sign-up";
import { createElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const nextBehaviorScreens: Record<string, () => JSX.Element> = {
  [AppRoutes.CompleteSignUp]: CompleteSignUpPage,
};

export function PrivateNavigator(): JSX.Element {
  // Track MixPanel Page View
  useMixPanelTrackPageView();

  const { state: authenticationState } = useAuthentication();

  return (
    <>
      <Routes>
        {authenticationState.nextBehavior ? (
          <>
            <Route
              path={authenticationState.nextBehavior}
              element={createElement(
                nextBehaviorScreens[authenticationState.nextBehavior]
              )}
            />

            <Route
              path={AppRoutes.NotFound}
              element={<Navigate to={authenticationState.nextBehavior} />}
            />
          </>
        ) : (
          AppRouteConstants.filter(
            (route: AppRoute) =>
              route.type === RouteTypes.Private ||
              route.type === RouteTypes.Common
          ).map((route: AppRoute) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <LayoutWrapper
                  layout={route?.layout}
                  element={createElement(route.element)}
                />
              }
            />
          ))
        )}

        <Route
          path={AppRoutes.NotFound}
          element={<Navigate to={AppRoutes.Download} />}
        />
      </Routes>
    </>
  );
}
