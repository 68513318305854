import { User } from "@sentry/react";
import { MixPanelFieldConstants } from "app/constants/mix-panel.constants";
import { dispatchPeopleToMixPanel } from "app/providers/event.provider";

export function getMixPanelPeopleOnboardingAttributes(user: User): Object {
  const mixPanelAttributes = {};

  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.PHONE_CALLING_CODE,
    user.calling_code
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.PHONE,
    user.phone
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.AGE_RANGE,
    user.settings.age_range
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.EDUCATION_LEVEL,
    user.settings.education_level
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.IMMIGRATION_MOMENT,
    user.settings.migration_status
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.IMMIGRATION_WAY,
    user.settings.immigration_companions
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.IMMIGRATION_OBJECTIVE,
    user.settings.migration_deal
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.IMMIGRATION_WORK_TYPE,
    user.settings.professional_interest
  );

  return mixPanelAttributes;
}

export function addMixPanelAttributeIfDefined(
  attributes: Object,
  key: string,
  value: string | undefined | null | number
) {
  if (value) {
    attributes[key] = value;
  }
}

export function getMixPanelPeopleAttributes(user: User): Object {
  const mixPanelAttributes = {};

  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.INVITED_BY,
    user.settings.referrer_id
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.OCCUPATION,
    user.occupation
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.PHONE,
    user.phone
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.BIRTH_DATE,
    user.birthday
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.IMMIGRATION_LEVEL,
    user.migration_level
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.MEMBER_STATUS,
    user.member_status
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.USERS_INVITED,
    user.invited_users_count
  );
  addMixPanelAttributeIfDefined(
    mixPanelAttributes,
    MixPanelFieldConstants.SIGN_UP_REFERRER,
    user.settings.referrer
  );

  return mixPanelAttributes;
}

export function handleProfileMixPanelEvents(
  userInitialAttributes: Object,
  user: User
): void {
  const onboardingAttributes = getMixPanelPeopleOnboardingAttributes(user);
  const peopleAttributes = getMixPanelPeopleAttributes(user);
  const mixPanelAttributes = {
    ...onboardingAttributes,
    ...userInitialAttributes,
    ...peopleAttributes,
  };

  dispatchPeopleToMixPanel(mixPanelAttributes);
}
