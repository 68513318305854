import { Grid, Typography } from "@mui/material";
import { components } from "react-select";

const { SingleValue }: any = components;

export function SelectSingleValue({ children, ...props }) {
  const { data } = props;

  const Icon = data.icon;

  return (
    <SingleValue {...props}>
      <Grid container alignItems={"center"} gap={"4px"}>
        {Icon ? (
          <Icon
            style={{
              width: "24px",
              borderRadius: "4px",
            }}
          />
        ) : (
          data.flag
        )}

        <Typography
          style={{
            color: "#000",
            fontFamily: "'InterRegular', sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "156%",
            letterSpacing: "-0.16px",
          }}
        >
          {data.value}
        </Typography>
      </Grid>
    </SingleValue>
  );
}
