import { Box } from "@mui/material";
import Transition from "resources/components/transition";
import { CustomButton, CustomDialog, CustomTypography } from "./styles";

export function Dialog({
  open,
  onClose,
  topIcon,
  title,
  body,
  buttons,
  bottomText,
  bottomTextIcon,
}: any) {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {topIcon && <Box mb="2rem">{topIcon}</Box>}
      <CustomTypography h3 mb="2rem">
        {title}
      </CustomTypography>
      {body && (
        <CustomTypography h5 mb="2rem">
          {body}
        </CustomTypography>
      )}
      {buttons &&
        buttons.map((button) => (
          <CustomButton
            type="button"
            variant={button.variant || "red"}
            onClick={button.onClick}
            href={button.externalLink}
            target={button.externalLink ? "_blank" : "_self"}
          >
            {button.title}
          </CustomButton>
        ))}
      {bottomText && (
        <CustomTypography bottomText mt="2rem">
          {bottomTextIcon && <Box mr="0.5rem">{bottomTextIcon}</Box>}
          <p>{bottomText}</p>
        </CustomTypography>
      )}
    </CustomDialog>
  );
}
