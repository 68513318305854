import { Grid } from "@mui/material";
import { ReactComponent as Email } from "resources/assets/icons/email.svg";
import { AuthenticationElement } from "resources/layouts/authentication";
import { AuthenticationFooter } from "../authentication/footer";
import { Social } from "../authentication/social";
import { Button } from "../buttons/main";
import { Notice } from "./styles";
import { WelcomePartialOptions } from "./types";

export function WelcomePartial({
  title,
  subtitle,
  isSignUp,
  progressBarValue,
  onSignInClick,
  onSignUpClick,
  onBackButtonClick,
}: WelcomePartialOptions): JSX.Element {
  return (
    <AuthenticationElement
      title={title}
      subtitle={subtitle}
      progressBarValue={progressBarValue}
      onBackButtonClick={onBackButtonClick}
    >
      <Grid container flexDirection={"column"}>
        <Grid container flexDirection={"column"} gap={"12px"}>
          <Button
            title={`${isSignUp ? "Continuar" : "Entrar"} com meu e-mail`}
            sx={{
              justifyContent: "flex-start !important",
              borderRadius: "12px !important",
              border: "1px solid var(--gray-30, #D1D5DB)",
              background:
                "var(--foundation-primary-primary-900, #FFFFFF) !important",
              padding: "8px 24px !important",

              span: {
                fontFamily: "'InterMedium', sans-serif !important",
                color: "#1F2937 !important",
              },
            }}
            startIcon={<Email />}
            onClick={onSignInClick}
          />

          <Social signUp={isSignUp} />
        </Grid>

        {isSignUp && (
          <Notice sx={{ marginTop: "24px" }}>
            {"Ao continuar, você concorda com a "}
            <a href="https://ailu.com.br/termos-e-politica">
              {"Termos de uso e Política de Privacidade"}
            </a>
            {" da Ailu"}
          </Notice>
        )}

        <AuthenticationFooter
          gridOptions={{
            justifyContent: "center",
          }}
          items={
            !isSignUp
              ? [
                  {
                    title: "Ainda não tem uma conta?",
                    subtitle: "Criar Conta",
                    onClick: onSignUpClick ?? (() => {}),
                  },
                ]
              : ([
                  {
                    title: "Já possui uma conta?",
                    subtitle: "Entrar aqui",
                    onClick: onBackButtonClick,
                  },
                ] as any)
          }
        />
      </Grid>
    </AuthenticationElement>
  );
}
