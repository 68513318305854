import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import useRouterQuery from "app/hooks/router-query.hook";
import SpinnerScreenPage from "pages/common/spinner";
import { ComponentType, createElement } from "react";
import NavigationHeader from "resources/components/navigation-header";
import { PrivateNavigator } from "./private";
import { PublicNavigator } from "./public";

export function LayoutWrapper({
  layout,
  element,
}: {
  layout?: ComponentType;
  element: JSX.Element;
}): JSX.Element {
  if (layout) {
    return createElement(layout, {}, element);
  }

  return element;
}

export function MainNavigator(): JSX.Element {
  const { state: authenticationState } = useAuthentication();

  const routerQuery: URLSearchParams = useRouterQuery();
  const webviewMode: boolean = Boolean(
    routerQuery.get("webviewMode") === "true"
  );

  if (authenticationState.isLoading) {
    return <SpinnerScreenPage />;
  }

  return (
    <>
      {!webviewMode && <NavigationHeader />}

      {authenticationState.isAuthenticated ? (
        <PrivateNavigator />
      ) : (
        <PublicNavigator />
      )}
    </>
  );
}
