import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { AppNavigationRouteConstants } from "app/constants/route.constants";
import ArticleProvider from "app/contexts/contents/article";
import { ArticleMainAttributes } from "app/contexts/contents/article/enums/main-attribute.enum";
import { useArticle } from "app/contexts/contents/article/hooks/article.hook";
import ContentCommentProvider from "app/contexts/contents/comment";
import { ContentCommentMainAttributes } from "app/contexts/contents/comment/enums/main-attribute.enum";
import ContentFeedbackProvider from "app/contexts/contents/feedback";
import { ContentFeedbackMainAttributes } from "app/contexts/contents/feedback/enums/main-attribute.enum";
import { FreeArticle } from "app/entities/article.entity";
import { AlgoliaIndexesNames } from "app/enums/algolia-index.enum";
import useContentHelper from "app/helpers/content.helper";
import useDateHelper from "app/helpers/date.helper";
import useStrapiHelper from "app/helpers/strapi.helper";
import useRouterQuery from "app/hooks/router-query.hook";
import useWindowSize from "app/hooks/use-window-size.hook";
import useNavigationProvider from "app/providers/navigation.provider";
import { useParams } from "react-router-dom";
import { ContainerScrollbar } from "resources/components/container-scrollbar";
import { ContentComments } from "resources/components/contents/comments";
import { ContentFeedback } from "resources/components/contents/feedback";
import { ContentRelatedPostsSlider } from "resources/components/contents/slider-related-posts";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthorsBio from "resources/packages/contents/components/authors-bio";
import Button from "resources/packages/contents/components/button";
import List from "resources/packages/contents/components/list";
import OdNewsList from "resources/packages/contents/components/od-news-list";
import RichText from "resources/packages/contents/components/rich-text";
import TaskDocumentList from "resources/packages/contents/components/task-document-list";
import TaskHighlightList from "resources/packages/contents/components/task-highlight-list";
import TaskStepByStep from "resources/packages/contents/components/task-step-by-step";
import { Video } from "resources/packages/contents/components/video";
import { ArticleContentType } from "resources/packages/contents/enums/article-content-type.enum";
import { ArticleQueryAttributes } from "resources/packages/contents/enums/article-query-attribute.enum";
import {
  ContentContainer,
  CoverImg,
  DescriptionTypography,
  TitleTypography,
  UpdatedAtTypography,
} from "resources/packages/contents/styled/styles";

const ArticleComponentMap: {
  [key in ArticleContentType]: React.ComponentType<any>;
} = {
  [ArticleContentType.RichText]: RichText,
  [ArticleContentType.List]: List,
  [ArticleContentType.OdNewsList]: OdNewsList,
  [ArticleContentType.TaskHighlightList]: TaskHighlightList,
  [ArticleContentType.TaskStepByStep]: TaskStepByStep,
  [ArticleContentType.TaskDocumentList]: TaskDocumentList,
  [ArticleContentType.Button]: Button,
  [ArticleContentType.Video]: Video,
};

const postGridOptions: any = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};

export default function ViewFreeArticle(): JSX.Element {
  const { id: postId }: any = useParams();

  return (
    <ArticleProvider
      postId={postId}
      mainAttribute={ArticleMainAttributes.FreeArticle}
    >
      <ElementView />
    </ArticleProvider>
  );
}

function ElementView(): JSX.Element {
  const { state: articleState } = useArticle<FreeArticle>();

  const { categoryFilters, onContentClick: onViewContent } = useContentHelper();
  const { isMobile } = useWindowSize();

  const { onBackButtonPress } = useNavigationProvider();
  const { formatPtBrDate } = useDateHelper();
  const { getImageUrl } = useStrapiHelper();

  const routerQuery: URLSearchParams = useRouterQuery();
  const webviewMode: boolean = Boolean(
    routerQuery.get("webviewMode") === "true"
  );

  return (
    <>
      <HelmetHandler
        options={{
          title: articleState.contentResponse?.attributes.title ?? "Conteúdos",
        }}
        htmlTags={articleState.htmlMetaTags}
      />

      {articleState.contentResponse && (
        <ContainerScrollbar styles={{ alignItems: "center" }}>
          <Grid container justifyContent={"flex-end"}>
            {!webviewMode && (
              <CloseIcon
                onClick={onBackButtonPress}
                style={{ cursor: "pointer" }}
              />
            )}
          </Grid>

          <ContentContainer>
            <TitleTypography>
              {articleState.contentResponse.attributes.title}
            </TitleTypography>

            <DescriptionTypography>
              {articleState.contentResponse.attributes.description}
            </DescriptionTypography>

            {articleState.contentResponse.attributes.updatedAt && (
              <UpdatedAtTypography>
                {`Última atualização - ${formatPtBrDate(
                  articleState.contentResponse.attributes.updatedAt
                )}`}
              </UpdatedAtTypography>
            )}

            {articleState.contentResponse.attributes?.cover?.data && (
              <CoverImg
                src={getImageUrl(
                  articleState.contentResponse.attributes.cover.data?.attributes
                    .url
                )}
                alt={
                  articleState.contentResponse.attributes.cover.data?.attributes
                    .alternativeText
                }
              />
            )}

            {articleState.contentResponse.attributes.authorsBio.data && (
              <AuthorsBio
                authorsBio={articleState.contentResponse.attributes.authorsBio}
              />
            )}

            {articleState.contentResponse.attributes.content.map(
              (content: any) => {
                const Component =
                  ArticleComponentMap[content.__typename as ArticleContentType];

                return Component ? <Component {...content} /> : null;
              }
            )}

            {articleState.contentId && (
              <>
                <ContentFeedbackProvider
                  contentId={articleState.contentId}
                  mainAttribute={
                    ContentFeedbackMainAttributes.FreeArticleFeedbacks
                  }
                  queryAttribute={ArticleQueryAttributes.FreeArticle}
                >
                  <ContentFeedback />
                </ContentFeedbackProvider>

                <Grid
                  container
                  mt={"16px"}
                  sx={isMobile ? { width: "95vw" } : {}}
                >
                  <Grid item {...postGridOptions}>
                    <ContentRelatedPostsSlider
                      title={"Relacionadas"}
                      contentId={Number(articleState.contentId)}
                      categoryFilter={categoryFilters(
                        articleState?.contentResponse?.attributes?.categories
                          ?.data
                      )}
                      indexName={AlgoliaIndexesNames.FreeArticles}
                      onClick={(event: any) =>
                        onViewContent(
                          event.split("-")[0],
                          event,
                          AppNavigationRouteConstants.FREE_ARTICLE
                        )
                      }
                    />
                  </Grid>
                </Grid>

                <ContentCommentProvider
                  contentId={articleState.contentId}
                  mainAttribute={
                    ContentCommentMainAttributes.FreeArticleComments
                  }
                  queryAttribute={ArticleQueryAttributes.FreeArticle}
                >
                  <ContentComments />
                </ContentCommentProvider>
              </>
            )}
          </ContentContainer>
        </ContainerScrollbar>
      )}
    </>
  );
}
