import { Grid } from "@mui/material";
import {
  DescriptionTypography,
  TitleTypography,
} from "resources/packages/contents/styled/styles";
import { VideoProps } from "./types";

export function Video({
  embedUrl,
  title,
  description,
}: VideoProps): JSX.Element {
  return (
    <>
      {embedUrl && (
        <Grid mt={"16px"} mb={"16px"}>
          {title && (
            <TitleTypography
              style={{
                fontSize: "16px",
              }}
            >
              {title}
            </TitleTypography>
          )}

          {description && (
            <DescriptionTypography
              style={{
                fontSize: "14px",
              }}
            >
              {description}
            </DescriptionTypography>
          )}

          <iframe
            width={"100%"}
            height="315"
            src={embedUrl}
            title="YouTube video player"
            frameBorder={"0"}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </Grid>
      )}
    </>
  );
}
