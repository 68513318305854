import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const ClassifiedsCardHeader: any = styled(Grid)`
  && {
    display: flex;
    gap: 16px;
  }
`;

export const ClassifiedsCardTitle: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.36px;
    color: var(--primria-escuros-preto-cinza-e-branco-800, var(--Black, #000));
  }
`;

export const ClassifiedsCardLink: any = styled.a`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 0;
    width: fit-content;
    text-transform: none;
    text-decoration: none;
    outline: none;

    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    line-height: 156%;
    letter-spacing: -0.32px;
    color: var(--primria-escuros-preto-cinza-e-branco-800, var(--Black, #000));
    text-decoration-line: underline;
  }
`;
