import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { AppNavigationRouteConstants } from "app/constants/route.constants";
import ArticleProvider from "app/contexts/contents/article";
import { ArticleMainAttributes } from "app/contexts/contents/article/enums/main-attribute.enum";
import { useArticle } from "app/contexts/contents/article/hooks/article.hook";
import ContentCommentProvider from "app/contexts/contents/comment";
import { ContentCommentMainAttributes } from "app/contexts/contents/comment/enums/main-attribute.enum";
import ContentFeedbackProvider from "app/contexts/contents/feedback";
import { ContentFeedbackMainAttributes } from "app/contexts/contents/feedback/enums/main-attribute.enum";
import { TaskArticle } from "app/entities/article.entity";
import { AlgoliaIndexesNames } from "app/enums/algolia-index.enum";
import useContentHelper from "app/helpers/content.helper";
import useDateHelper from "app/helpers/date.helper";
import useRouterQuery from "app/hooks/router-query.hook";
import useWindowSize from "app/hooks/use-window-size.hook";
import useNavigationProvider from "app/providers/navigation.provider";
import { useParams } from "react-router-dom";
import { ContainerScrollbar } from "resources/components/container-scrollbar";
import { ContentComments } from "resources/components/contents/comments";
import { ContentFeedback } from "resources/components/contents/feedback";
import { ContentRelatedPostsSlider } from "resources/components/contents/slider-related-posts";
import { HelmetHandler } from "resources/components/handlers/helmet";
import RichText from "resources/packages/contents/components/rich-text";
import TaskDocumentList from "resources/packages/contents/components/task-document-list";
import TaskHighlightList from "resources/packages/contents/components/task-highlight-list";
import TaskStepByStep from "resources/packages/contents/components/task-step-by-step";
import { Video } from "resources/packages/contents/components/video";
import { ArticleQueryAttributes } from "resources/packages/contents/enums/article-query-attribute.enum";
import {
  ContentContainer,
  DescriptionTypography,
  TitleTypography,
  UpdatedAtTypography,
} from "resources/packages/contents/styled/styles";

const postGridOptions: any = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};

export default function ViewTaskArticle(): JSX.Element {
  const { id: postId }: any = useParams();

  return (
    <ArticleProvider
      postId={postId}
      mainAttribute={ArticleMainAttributes.TaskArticle}
    >
      <ElementView />
    </ArticleProvider>
  );
}

function ElementView(): JSX.Element {
  const { state: articleState } = useArticle<TaskArticle>();

  const { categoryFilters, onContentClick: onViewContent } = useContentHelper();
  const { isMobile } = useWindowSize();

  const { onBackButtonPress } = useNavigationProvider();
  const { formatPtBrDate } = useDateHelper();

  const routerQuery: URLSearchParams = useRouterQuery();
  const webviewMode: boolean = Boolean(
    routerQuery.get("webviewMode") === "true"
  );

  return (
    <>
      <HelmetHandler
        options={{
          title: articleState.contentResponse?.attributes.title ?? "Conteúdos",
        }}
        htmlTags={articleState.htmlMetaTags}
      />

      {articleState.contentResponse && (
        <ContainerScrollbar styles={{ alignItems: "center" }}>
          <Grid container justifyContent={"flex-end"}>
            {!webviewMode && (
              <CloseIcon
                onClick={onBackButtonPress}
                style={{ cursor: "pointer" }}
              />
            )}
          </Grid>

          {articleState.contentResponse?.attributes && (
            <ContentContainer>
              <TitleTypography>
                {articleState.contentResponse?.attributes?.title}
              </TitleTypography>

              <DescriptionTypography>
                {articleState.contentResponse?.attributes?.description}
              </DescriptionTypography>

              {articleState.contentResponse.attributes.updatedAt && (
                <UpdatedAtTypography>
                  {`Última atualização - ${formatPtBrDate(
                    articleState.contentResponse.attributes.updatedAt
                  )}`}
                </UpdatedAtTypography>
              )}

              {articleState.contentResponse?.attributes?.highlights && (
                <TaskHighlightList
                  highlights={
                    articleState.contentResponse?.attributes?.highlights
                      ?.highlights
                  }
                />
              )}

              {articleState.contentResponse?.attributes?.steps && (
                <TaskStepByStep
                  steps={articleState.contentResponse.attributes?.steps}
                />
              )}

              {articleState.contentResponse?.attributes?.richTextAfterSteps && (
                <RichText
                  body={
                    articleState.contentResponse.attributes?.richTextAfterSteps
                  }
                />
              )}

              {articleState.contentResponse?.attributes?.documents && (
                <TaskDocumentList
                  documents={articleState.contentResponse.attributes?.documents}
                />
              )}

              {articleState.contentResponse?.attributes?.video && (
                <Video
                  embedUrl={
                    articleState.contentResponse.attributes.video.embedUrl
                  }
                  title={articleState.contentResponse.attributes.video.title}
                  description={
                    articleState.contentResponse.attributes.video.description
                  }
                />
              )}

              {articleState.contentResponse?.attributes
                ?.richTextAfterDocuments && (
                <RichText
                  body={
                    articleState.contentResponse.attributes
                      ?.richTextAfterDocuments
                  }
                />
              )}

              {articleState.contentId && (
                <>
                  <ContentFeedbackProvider
                    contentId={articleState.contentId}
                    mainAttribute={
                      ContentFeedbackMainAttributes.TaskArticleFeedbacks
                    }
                    queryAttribute={ArticleQueryAttributes.TaskArticle}
                  >
                    <ContentFeedback />
                  </ContentFeedbackProvider>

                  <Grid
                    container
                    mt={"16px"}
                    sx={isMobile ? { width: "95vw" } : {}}
                  >
                    <Grid item {...postGridOptions}>
                      <ContentRelatedPostsSlider
                        title={"Relacionadas"}
                        contentId={Number(articleState.contentId)}
                        categoryFilter={categoryFilters(
                          articleState?.contentResponse?.attributes?.categories
                            ?.data
                        )}
                        indexName={AlgoliaIndexesNames.TaskArticles}
                        onClick={(event: any) =>
                          onViewContent(
                            event.split("-")[0],
                            event,
                            AppNavigationRouteConstants.TASK_ARTICLE
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <ContentCommentProvider
                    contentId={articleState.contentId}
                    mainAttribute={
                      ContentCommentMainAttributes.TaskArticleComments
                    }
                    queryAttribute={ArticleQueryAttributes.TaskArticle}
                  >
                    <ContentComments />
                  </ContentCommentProvider>
                </>
              )}
            </ContentContainer>
          )}
        </ContainerScrollbar>
      )}
    </>
  );
}
