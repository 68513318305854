export enum MixpanelEvents {
  PageView = "Page View",
  SneakPeekPLG = "Sneak Peek (PLG)",
  UserSignIn = "User SignIn",
  UserSignUp = "User SignUp",
  CommunitySurveyVote = "Community Survey Vote",
  CommunityUserReturn = "Community User Return",
  CommunityComment = "Community Comment",
  CommunityReferralLinkCopy = "Community Referral Link Copy",
  ContentSearchResultClick = "Content Search Result Click",
  InvitationCopy = "Invitation Copy",
  UserInvitedStarted = "User Invited Started",
  ImmigrationPlanUpdated = "Immigration Plan Updated",
  ToolUseIntention = "Tool Use Intention",
  JobFeedHighlightClick = "Job Feed Highlight Click",
  ImmigrationPlanCategoryUpdated = "Immigration Plan Updated - Category",
  OnboardingDecision = "Joined WhatsApp?",
  CommunityInvitationRejected = "Community Invitation Rejected",
  NewQuickFeedback = "New Quick Feedback",
  JobFeedReaction = "Job Feed Reaction",
  JobFeedInterestedRegistered = "Job Feed Interested Registered",
  JobFeedInterestedRemoved = "Job Feed Interested Removed",
  NewJobFeedComment = "New Job Feed Comment",
  FilterSelectedCategories = "Job Feed Filter Selected Categories",
  JobFeedNewAlert = "Job Feed New Alert",
  JobFeedNewSuggestion = "Job Feed New Suggestion",
  LegalFeedSelectedSubjects = "Legal Feed Selected Subjects",
  LegalFeedNewQuestion = "Legal Feed New Question",
  LegalFeedReaction = "Legal Feed Reaction",
  TourInteraction = "Tour Interaction",
  NewOnboardingStepUpdate = "New Onboarding Step Update",
  GuidedExperienceContentGuide = "Guided Experience ContentGuide",
  GuidedExperiencePreparationLevel = "Guided Experience PreparationLevel",
  GuidedExperienceRewardDownloaded = "Guided Experience Reward Downloaded",
  GuidedExperienceRewardViewed = "Guided Experience Reward Viewed",
  GuidedExperienceTask = "Guided Experience Task",
  GuidedExperienceVideoQuestionPresented = "Guided Experience Video Question Presented",
  GuidedExperienceVideoContactInteracted = "Guided Experience Video Contact Interacted",
  GuidedExperienceVideoQuestionSubmitted = "Guided Experience Video Question Submitted",
  GuidedExperienceVideoSubmitted = "Guided Experience Video Submitted",
  GuidedExperienceVideoAsk = "Guided Experience Video Ask",
  DownloadStarted = "Download Started",
}

export function formatMixpanelEvent(
  event: MixpanelEvents,
  dynamicValue: string
): string {
  if (event === MixpanelEvents.CommunitySurveyVote) {
    return `Community ${dynamicValue} Vote`;
  }

  if (event === MixpanelEvents.ImmigrationPlanCategoryUpdated) {
    return `Immigration Plan Updated - ${dynamicValue}`;
  }

  return event;
}
