import { Grid, Stack, Typography } from "@mui/material";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { AuthenticationTypes } from "app/enums/authentication-type.enum";
import useWindowSize from "app/hooks/use-window-size.hook";
import QRCode from "react-qr-code";
import HandsClap from "resources/assets/gifs/hands-clap.gif";
import { ReactComponent as ArrowUpRight } from "resources/assets/icons/arrow-up-right.svg";
import { Button } from "resources/components/buttons/main";
import { HelmetHandler } from "resources/components/handlers/helmet";
import { Person } from "resources/components/person";
import { Subtitle } from "resources/components/welcome/styles";
import AuthenticationLayout from "resources/layouts/authentication";
import { SignUpQrCodeConstants } from "./constants";
import { getMountedUrl } from "./helpers/download.helper";
import { FooterTitle, Title } from "./styles";

const PAGE_TITLE = "Baixe o App Ailu";

export default function DownloadPage(): JSX.Element {
  const { isMobile } = useWindowSize();
  const width = isMobile ? undefined : "380px";

  const { state: authenticationState } = useAuthentication();

  const mountedUrl = getMountedUrl(
    SignUpQrCodeConstants.BASE_URL,
    authenticationState?.user
  );

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout
        fixedFooterElement={
          <Grid
            container
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FooterTitle maxWidth={335}>
              Atualmente, a experiência da Ailu só pode ser acessada no app para
              Android ou iOS.
            </FooterTitle>
          </Grid>
        }
      >
        <Grid
          container
          flexDirection={"column"}
          minWidth={width}
          maxWidth={width}
          mt={"24px"}
        >
          {authenticationState.authenticationType ===
          AuthenticationTypes.SignUp ? (
            <>
              <img
                src={HandsClap}
                alt={"hands clap"}
                style={{
                  width: "54px",
                  height: "54px",
                  transform: "rotateY(180deg)",
                }}
              />

              <Grid mt={"24px"} mb={"8px"}>
                <Subtitle>{"Conta criada com sucesso!"}</Subtitle>
              </Grid>
            </>
          ) : (
            authenticationState.user && (
              <Grid mb={"24px"}>
                <Person
                  avatar={authenticationState.user.avatar}
                  name={authenticationState.user.name}
                  preName={"Boas vindas, "}
                />
              </Grid>
            )
          )}

          <Grid container flexDirection={"column"} mb={"24px"}>
            <Title mt={"8px"}>{"Baixe nosso app agora e"}</Title>
            <Title>
              <span>{"navegue rumo à Portugal"}</span>
            </Title>
          </Grid>

          <Grid
            container
            sx={{
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              marginBottom: "24px",
            }}
          >
            {isMobile && (
              <Button
                title={"Baixar App na loja"}
                endIcon={<ArrowUpRight />}
                fullWidth
                onClick={() => window.open(mountedUrl)}
              />
            )}

            {!isMobile && (
              <Stack
                width={"100%"}
                style={{
                  flexDirection: "column",
                  gap: 24,
                  borderRadius: 16,
                  backgroundColor: "#F9FAFB",
                  padding: "24px 16px",
                }}
              >
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <QRCode
                    value={mountedUrl}
                    style={{
                      width: "178px",
                      height: "178px",
                    }}
                  />
                </Grid>

                <Grid container flexDirection={"column"} gap={"12px"}>
                  <Typography
                    sx={{
                      color: "#374151",
                      fontFamily: "'InterSemiBold', sans-serif",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "140%",
                      letterSpacing: "-0.12px",
                    }}
                  >
                    {"Instruções"}
                  </Typography>

                  <Grid container flexDirection={"column"}>
                    {SignUpQrCodeConstants.INSTRUCTIONS.map(
                      (instruction, index) => (
                        <Typography
                          sx={{
                            opacity: 0.9,
                            color: "#374151",
                            fontFamily: "'InterRegular', sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "160%",
                            letterSpacing: "-0.14px",
                          }}
                        >
                          <span>{`${index + 1}. ${instruction}`}</span>
                        </Typography>
                      )
                    )}
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Grid>

          <Grid
            container
            mt={"12px"}
            sx={{
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Grid
              container
              flexDirection={"column"}
              alignItems={"center"}
              gap={"12px"}
            >
              <Typography
                sx={{
                  color: "#374151",
                  fontFamily: "'InterRegular', sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  textAlign: "center",
                  lineHeight: "156%",
                  letterSpacing: "-0.12px",
                }}
              >
                {"Disponível para"}
              </Typography>

              <Grid container gap={"16px"} maxWidth={"max-content"}>
                {SignUpQrCodeConstants.USEFUL_LINKS.map((item) => (
                  <img
                    style={{ cursor: "pointer" }}
                    src={item.icon}
                    alt={"store"}
                    onClick={() => window.open(item.link)}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AuthenticationLayout>
    </>
  );
}
