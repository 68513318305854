import usePaddingHelper from "app/helpers/padding.helper";
import {
  ScrollbarContainerStyles,
  ScrollbarContainerWithNavbarStyles,
} from "resources/assets/styles/styles";
import { StyledGrid } from "./styles";
import { ContainerScrollbarOptions } from "./types";

export function ContainerScrollbar({
  children,
  className,
  styles,
  disableNavbar,
}: ContainerScrollbarOptions): JSX.Element {
  const { padding } = usePaddingHelper();

  return (
    <StyledGrid
      container
      className={className}
      sx={{
        padding,
        ...(disableNavbar
          ? ScrollbarContainerStyles
          : ScrollbarContainerWithNavbarStyles),
        ...styles,
      }}
    >
      {children}
    </StyledGrid>
  );
}
