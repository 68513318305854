import { Typography } from "@mui/material";
import { useState } from "react";
import { ReactComponent as CheckedCircle } from "resources/assets/icons/checked_circle.svg";
import { ContentInfoModal } from "resources/components/contents/info-modal";
import { ComponentContainer } from "../styles";
import {
  TaskDocumentCard,
  TaskDocumentContent,
  TaskDocumentLink,
  TaskDocumentTitle,
  TitleTypography,
} from "./styles";
import { TaskDocumentOptions } from "./types";
import RichText from "../rich-text";

export default function TaskDocumentList({ documents }): JSX.Element {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [infoDialog, setInfoDialog] = useState<string>("");

  const { documents: documentsList, description } = documents;

  const onLinkClick = (url: string): void => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleStateDialog = (
    stateDialog: boolean,
    infoDialog: string
  ): void => {
    setOpenDialog(!stateDialog);
    setInfoDialog(infoDialog);
  };

  const DocumentTypeConstants = Object.freeze({
    FILE: {
      buttonTitle: "Ver Arquivo",
      buttonClick: (url: string) => onLinkClick(url),
    },
    INFO: {
      buttonTitle: "Saiba Mais",
      buttonClick: (info: string) => handleStateDialog(openDialog, info),
    },
    LINK: {
      buttonTitle: "Ver Link",
      buttonClick: (url: string) => onLinkClick(url),
    },
  });

  return (
    <>
      <ComponentContainer sx={{ gap: "10px !important" }}>
        <TitleTypography>Documentos necessários</TitleTypography>

        <RichText style={{ margin: "14px 0px" }} body={description} />

        {documentsList.map((document: TaskDocumentOptions, index: any) => (
          <TaskDocumentCard>
            <CheckedCircle />

            <TaskDocumentContent>
              <TaskDocumentTitle>{document.title}</TaskDocumentTitle>

              {document.action && document.actionValue && (
                <TaskDocumentLink
                  onClick={() =>
                    DocumentTypeConstants[document.action].buttonClick(
                      document.actionValue
                    )
                  }
                >
                  <Typography component="p">
                    {DocumentTypeConstants[document.action].buttonTitle}
                  </Typography>
                </TaskDocumentLink>
              )}
            </TaskDocumentContent>
          </TaskDocumentCard>
        ))}
      </ComponentContainer>

      <ContentInfoModal
        open={openDialog}
        close={() => handleStateDialog(openDialog, "")}
        info={infoDialog}
      />
    </>
  );
}
