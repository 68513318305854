import { MixPanelParamConstants } from "app/constants/mix-panel.constants";
import { RestAPIConstants } from "app/constants/rest-api.constants";
import { UserSetting } from "app/entities/user-setting.entity";
import { User } from "app/entities/user.entity";
import { MixpanelEvents } from "app/enums/mix-panel.enum";

export function hasAnySetting(settingsKeys: string[], settings: UserSetting) {
  return settingsKeys.some((key) => settings[key] !== undefined);
}

export function isUserOnboardingStarted(user: User) {
  const settingsKeys = [
    "age_range",
    "education_level",
    "migration_status",
    "immigration_companions",
    "migration_deal",
    "professional_interest",
    "interested_joining_community",
    "accepted_community_answered_at",
  ];

  return hasAnySetting(settingsKeys, user.settings);
}

export function getMountedUrl(redirectUrl: string, user?: User): string {
  const baseUrl = `${process.env.REACT_APP_API_URL}${RestAPIConstants.UPDATE_REDIRECTOR}`;

  const eventProperties = JSON.stringify({
    name: MixpanelEvents.DownloadStarted,
    properties: {
      [MixPanelParamConstants.ONBOARDING_STARTED]: user
        ? isUserOnboardingStarted(user)
        : false,
    },
  });

  const urlParams = {
    user_id: user?.id,
    redirect_url: redirectUrl,
    event_properties: eventProperties,
  };

  const params = new URLSearchParams(urlParams as any).toString();
  const mountedUrl = `${baseUrl}?${params}`;

  return mountedUrl;
}
