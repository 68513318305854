import { AppIcons } from "configuration/asset.configuration";

export const PhoneInputConstants = Object.freeze({
  BRAZIL_COUNTRY_CODE: "BR",
  BRAZIL_CALLING_CODE: "+55",

  DEFAULT_COUNTRY: {
    value: "+55",
    label: "Brasil",
    flag: "🇧🇷",
    code: "BR",
    dialCode: "+55",
    icon: AppIcons.CountryFlags.Brazil,
    mask: "(99) 99999 9999",
  },

  COUNTRIES: [
    {
      pt: "Afeganistão",
      en: "Afghanistan",
      flag: "🇦🇫",
      code: "AF",
      dialCode: "+93",
      mask: "999 999 9999",
    },
    {
      pt: "Ilhas Aland",
      en: "Åland Islands",
      flag: "🇦🇽",
      code: "AX",
      dialCode: "+358",
      mask: "999 9999999",
    },
    {
      pt: "Albânia",
      en: "Albania",
      flag: "🇦🇱",
      code: "AL",
      dialCode: "+355",
      mask: "999 999 9999",
    },
    {
      pt: "Argélia",
      en: "Algeria",
      flag: "🇩🇿",
      code: "DZ",
      dialCode: "+213",
      mask: "9999 99 99 99",
    },
    {
      pt: "Samoa Americana",
      en: "American Samoa",
      flag: "🇦🇸",
      code: "AS",
      dialCode: "+1684",
      mask: "(999) 999-9999",
    },
    {
      pt: "Andorra",
      en: "Andorra",
      flag: "🇦🇩",
      code: "AD",
      dialCode: "+376",
      mask: "999 999",
    },
    {
      pt: "Angola",
      en: "Angola",
      flag: "🇦🇴",
      code: "AO",
      dialCode: "+244",
      mask: "999 999 999",
    },
    {
      pt: "Anguila",
      en: "Anguilla",
      flag: "🇦🇮",
      code: "AI",
      dialCode: "+1264",
      mask: "(999) 999-9999",
    },
    {
      pt: "Antígua e Barbuda",
      en: "Antigua and Barbuda",
      flag: "🇦🇬",
      code: "AG",
      dialCode: "+1268",
      mask: "(999) 999-9999",
    },
    {
      pt: "Argentina",
      en: "Argentina",
      flag: "🇦🇷",
      code: "AR",
      dialCode: "+54",
      mask: "999 99-9999-9999",
    },
    {
      pt: "Armênia",
      en: "Armenia",
      flag: "🇦🇲",
      code: "AM",
      dialCode: "+374",
      mask: "999 999999",
    },
    {
      pt: "Aruba",
      en: "Aruba",
      flag: "🇦🇼",
      code: "AW",
      dialCode: "+297",
      mask: "999 9999",
    },
    {
      pt: "Austrália",
      en: "Australia",
      flag: "🇦🇺",
      code: "AU",
      dialCode: "+61",
      mask: "9999 999 999",
    },
    {
      pt: "Áustria",
      en: "Austria",
      flag: "🇦🇹",
      code: "AT",
      dialCode: "+43",
      mask: "9999 999999",
    },
    {
      pt: "Azerbaijão",
      en: "Azerbaijan",
      flag: "🇦🇿",
      code: "AZ",
      dialCode: "+994",
      mask: "999 999 99 99",
    },
    {
      pt: "Bahamas",
      en: "Bahamas",
      flag: "🇧🇸",
      code: "BS",
      dialCode: "+1242",
      mask: "(999) 999-999",
    },
    {
      pt: "Bahrein",
      en: "Bahrain",
      flag: "🇧🇭",
      code: "BH",
      dialCode: "+973",
      mask: "9999 9999",
    },
    {
      pt: "Bangladesh",
      en: "Bangladesh",
      flag: "🇧🇩",
      code: "BD",
      dialCode: "+880",
      mask: "99999-999999",
    },
    {
      pt: "Barrados",
      en: "Barbados",
      flag: "🇧🇧",
      code: "BB",
      dialCode: "+1246",
      mask: "(999) 999-9999",
    },
    {
      pt: "Bielorrússia",
      en: "Belarus",
      flag: "🇧🇾",
      code: "BY",
      dialCode: "+375",
      mask: "9 999 999-99-99",
    },
    {
      pt: "Bélgica",
      en: "Belgium",
      flag: "🇧🇪",
      code: "BE",
      dialCode: "+32",
      mask: "9999 99 99 99",
    },
    {
      pt: "Belize",
      en: "Belize",
      flag: "🇧🇿",
      code: "BZ",
      dialCode: "+501",
      mask: "999-9999",
    },
    {
      pt: "Benin",
      en: "Benin",
      flag: "🇧🇯",
      code: "BJ",
      dialCode: "+229",
      mask: "99 99 99 99",
    },
    {
      pt: "Bermudas",
      en: "Bermuda",
      flag: "🇧🇲",
      code: "BM",
      dialCode: "+1441",
      mask: "(999) 999-9999",
    },
    {
      pt: "Butão",
      en: "Bhutan",
      flag: "🇧🇹",
      code: "BT",
      dialCode: "+975",
      mask: "99 99 99 99",
    },
    {
      pt: "Bolívia, Estado Plurinacional da Bolívia",
      en: "Bolivia, Plurinational State of bolivia",
      flag: "🇧🇴",
      code: "BO",
      dialCode: "+591",
      mask: "99999999",
    },
    {
      pt: "Bósnia e Herzegovina",
      en: "Bosnia and Herzegovina",
      flag: "🇧🇦",
      code: "BA",
      dialCode: "+387",
      mask: "999 999 999",
    },
    {
      pt: "Botsuana",
      en: "Botswana",
      flag: "🇧🇼",
      code: "BW",
      dialCode: "+267",
      mask: "99 999 999",
    },
    {
      pt: "Ilha Bouvet",
      en: "Bouvet Island",
      flag: "🇧🇻",
      code: "BV",
      dialCode: "+47",
      mask: "(99) 99999-9999",
    },
    {
      pt: "Brasil",
      en: "Brazil",
      flag: "🇧🇷",
      code: "BR",
      dialCode: "+55",
      mask: "(99) 99999 9999",
      enabled: true,
      icon: AppIcons.CountryFlags.Brazil,
    },
    {
      pt: "Território Britânico do Oceano Índico",
      en: "British Indian Ocean Territory",
      flag: "🇮🇴",
      code: "IO",
      dialCode: "+246",
      mask: "(999) 999-9999",
    },
    {
      pt: "Brunei Darussalam",
      en: "Brunei Darussalam",
      flag: "🇧🇳",
      code: "BN",
      dialCode: "+673",
      mask: "999 9999",
    },
    {
      pt: "Bulgária",
      en: "Bulgaria",
      flag: "🇧🇬",
      code: "BG",
      dialCode: "+359",
      mask: "999 999 999",
    },
    {
      pt: "Burkina Faso",
      en: "Burkina Faso",
      flag: "🇧🇫",
      code: "BF",
      dialCode: "+226",
      mask: "99 99 99 99",
    },
    {
      pt: "Burundi",
      en: "Burundi",
      flag: "🇧🇮",
      code: "BI",
      dialCode: "+257",
      mask: "99 99 99 99",
    },
    {
      pt: "Camboja",
      en: "Cambodia",
      flag: "🇰🇭",
      code: "KH",
      dialCode: "+855",
      mask: "999 999 999",
    },
    {
      pt: "Camarões",
      en: "Cameroon",
      flag: "🇨🇲",
      code: "CM",
      dialCode: "+237",
      mask: "9 99 99 99 99",
    },
    {
      pt: "Canadá",
      en: "Canada",
      flag: "🇨🇦",
      code: "CA",
      dialCode: "+1",
      mask: "(999) 999-9999",
    },
    {
      pt: "Cabo Verde",
      en: "Cape Verde",
      flag: "🇨🇻",
      code: "CV",
      dialCode: "+238",
      mask: "999 99 99",
    },
    {
      pt: "Ilhas Cayman",
      en: "Cayman Islands",
      flag: "🇰🇾",
      code: "KY",
      dialCode: "+345",
      mask: "999 9999",
    },
    {
      pt: "República Centro-Africana",
      en: "Central African Republic",
      flag: "🇨🇫",
      code: "CF",
      dialCode: "+236",
      mask: "99 99 99 99",
    },
    {
      pt: "Chade",
      en: "Chad",
      flag: "🇹🇩",
      code: "TD",
      dialCode: "+235",
      mask: "99 99 99 99",
    },
    {
      pt: "Chile",
      en: "Chile",
      flag: "🇨🇱",
      code: "CL",
      dialCode: "+56",
      mask: "(9) 9999 9999",
    },
    {
      pt: "China",
      en: "China",
      flag: "🇨🇳",
      code: "CN",
      dialCode: "+86",
      mask: "999 9999 9999",
    },
    {
      pt: "Ilha do Natal",
      en: "Christmas Island",
      flag: "🇨🇽",
      code: "CX",
      dialCode: "+61",
      mask: "9999 999 999",
    },
    {
      pt: "Ilhas Cocos (Keeling)",
      en: "Cocos (Keeling) Islands",
      flag: "🇨🇨",
      code: "CC",
      dialCode: "+61",
      mask: "9999 999 999",
    },
    {
      pt: "Colômbia",
      en: "Colombia",
      flag: "🇨🇴",
      code: "CO",
      dialCode: "+57",
      mask: "999 9999999",
    },
    {
      pt: "Comores",
      en: "Comoros",
      flag: "🇰🇲",
      code: "KM",
      dialCode: "+269",
      mask: "999 99 99",
    },
    {
      pt: "Congo",
      en: "Congo",
      flag: "🇨🇬",
      code: "CG",
      dialCode: "+242",
      mask: "9999 999 999",
    },
    {
      pt: "Congo, República Democrática do Congo",
      en: "Congo, The Democratic Republic of the Congo",
      flag: "🇨🇩",
      code: "CD",
      dialCode: "+243",
      mask: "99 999 9999",
    },
    {
      pt: "Ilhas Cook",
      en: "Cook Islands",
      flag: "🇨🇰",
      code: "CK",
      dialCode: "+682",
      mask: "99 999",
    },
    {
      pt: "Costa Rica",
      en: "Costa Rica",
      flag: "🇨🇷",
      code: "CR",
      dialCode: "+506",
      mask: "9999 9999",
    },
    {
      pt: "Cote d'Ivoire",
      en: "Cote d'Ivoire",
      flag: "🇨🇮",
      code: "CI",
      dialCode: "+225",
      mask: "99 99 99 99",
    },
    {
      pt: "Croácia",
      en: "Croatia",
      flag: "🇭🇷",
      code: "HR",
      dialCode: "+385",
      mask: "999 999 9999",
    },
    {
      pt: "Cuba",
      en: "Cuba",
      flag: "🇨🇺",
      code: "CU",
      dialCode: "+53",
      mask: "99 999 999",
    },
    {
      pt: "Chipre",
      en: "Cyprus",
      flag: "🇨🇾",
      code: "CY",
      dialCode: "+357",
      mask: "99 999999",
    },
    {
      pt: "República Checa",
      en: "Czech Republic",
      flag: "🇨🇿",
      code: "CZ",
      dialCode: "+420",
      mask: "999 999 999",
    },
    {
      pt: "Dinamarca",
      en: "Denmark",
      flag: "🇩🇰",
      code: "DK",
      dialCode: "+45",
      mask: "99 99 99 99",
    },
    {
      pt: "Djibuti",
      en: "Djibouti",
      flag: "🇩🇯",
      code: "DJ",
      dialCode: "+253",
      mask: "99 99 99 99",
    },
    {
      pt: "Dominica",
      en: "Dominica",
      flag: "🇩🇲",
      code: "DM",
      dialCode: "+1767",
      mask: "(999) 999-9999",
    },
    {
      pt: "Equador",
      en: "Ecuador",
      flag: "🇪🇨",
      code: "EC",
      dialCode: "+593",
      mask: "999 999 9999",
    },
    {
      pt: "Egito",
      en: "Egypt",
      flag: "🇪🇬",
      code: "EG",
      dialCode: "+20",
      mask: "9999 999 9999",
    },
    {
      pt: "El Salvador",
      en: "El Salvador",
      flag: "🇸🇻",
      code: "SV",
      dialCode: "+503",
      mask: "9999 9999",
    },
    {
      pt: "Guiné Equatorial",
      en: "Equatorial Guinea",
      flag: "🇬🇶",
      code: "GQ",
      dialCode: "+240",
      mask: "999 999 999",
    },
    {
      pt: "Eritreia",
      en: "Eritrea",
      flag: "🇪🇷",
      code: "ER",
      dialCode: "+291",
      mask: "99 999 999",
    },
    {
      pt: "Estônia",
      en: "Estonia",
      flag: "🇪🇪",
      code: "EE",
      dialCode: "+372",
      mask: "9999 9999",
    },
    {
      pt: "Etiópia",
      en: "Ethiopia",
      flag: "🇪🇹",
      code: "ET",
      dialCode: "+251",
      mask: "999 999 9999",
    },
    {
      pt: "Ilhas Malvinas (Malvinas)",
      en: "Falkland Islands (Malvinas)",
      flag: "🇫🇰",
      code: "FK",
      dialCode: "+500",
      mask: "99999",
    },
    {
      pt: "Ilhas Faroe",
      en: "Faroe Islands",
      flag: "🇫🇴",
      code: "FO",
      dialCode: "+298",
      mask: "999999",
    },
    {
      pt: "Fiji",
      en: "Fiji",
      flag: "🇫🇯",
      code: "FJ",
      dialCode: "+679",
      mask: "999 9999",
    },
    {
      pt: "Finlândia",
      en: "Finland",
      flag: "🇫🇮",
      code: "FI",
      dialCode: "+358",
      mask: "999 9999999",
    },
    {
      pt: "França",
      en: "France",
      flag: "🇫🇷",
      code: "FR",
      dialCode: "+33",
      mask: "99 99 99 99 99",
    },
    {
      pt: "Guiana Francesa",
      en: "French Guiana",
      flag: "🇬🇫",
      code: "GF",
      dialCode: "+594",
      mask: "9999 99 99 99",
    },
    {
      pt: "Polinésia Francesa",
      en: "French Polynesia",
      flag: "🇵🇫",
      code: "PF",
      dialCode: "+689",
      mask: "99 99 99 99",
    },
    {
      pt: "Gabão",
      en: "Gabon",
      flag: "🇬🇦",
      code: "GA",
      dialCode: "+241",
      mask: "99 99 99 99",
    },
    {
      pt: "Gâmbia",
      en: "Gambia",
      flag: "🇬🇲",
      code: "GM",
      dialCode: "+220",
      mask: "999 9999",
    },
    {
      pt: "Geórgia",
      en: "Georgia",
      flag: "🇬🇪",
      code: "GE",
      dialCode: "+995",
      mask: "999 99 99 99",
    },
    {
      pt: "Alemanha",
      en: "Germany",
      flag: "🇩🇪",
      code: "DE",
      dialCode: "+49",
      mask: "99999 9999999",
    },
    {
      pt: "Gana",
      en: "Ghana",
      flag: "🇬🇭",
      code: "GH",
      dialCode: "+233",
      mask: "999 999 9999",
    },
    {
      pt: "Gibraltar",
      en: "Gibraltar",
      flag: "🇬🇮",
      code: "GI",
      dialCode: "+350",
      mask: "99999999",
    },
    {
      pt: "Grécia",
      en: "Greece",
      flag: "🇬🇷",
      code: "GR",
      dialCode: "+30",
      mask: "999 999 9999",
    },
    {
      pt: "Gronelândia",
      en: "Greenland",
      flag: "🇬🇱",
      code: "GL",
      dialCode: "+299",
      mask: "99 99 99",
    },
    {
      pt: "Granada",
      en: "Grenada",
      flag: "🇬🇩",
      code: "GD",
      dialCode: "+1473",
      mask: "(999) 999-9999",
    },
    {
      pt: "Guadalupe",
      en: "Guadeloupe",
      flag: "🇬🇵",
      code: "GP",
      dialCode: "+590",
      mask: "9999 99 99 99",
    },
    {
      pt: "Guam",
      en: "Guam",
      flag: "🇬🇺",
      code: "GU",
      dialCode: "+1671",
      mask: "(999) 999-9999",
    },
    {
      pt: "Guatemala",
      en: "Guatemala",
      flag: "🇬🇹",
      code: "GT",
      dialCode: "+502",
      mask: "9999 9999",
    },
    {
      pt: "Guernsey",
      en: "Guernsey",
      flag: "🇬🇬",
      code: "GG",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      pt: "Guiné",
      en: "Guinea",
      flag: "🇬🇳",
      code: "GN",
      dialCode: "+224",
      mask: "999 99 99 99",
    },
    {
      pt: "Guiné-Bissau",
      en: "Guinea-Bissau",
      flag: "🇬🇼",
      code: "GW",
      dialCode: "+245",
      mask: "999 999 999",
    },
    {
      pt: "Guiana",
      en: "Guyana",
      flag: "🇬🇾",
      code: "GY",
      dialCode: "+592",
      mask: "999 9999",
    },
    {
      pt: "Haiti",
      en: "Haiti",
      flag: "🇭🇹",
      code: "HT",
      dialCode: "+509",
      mask: "99 99 9999",
    },
    {
      pt: "Honduras",
      en: "Honduras",
      flag: "🇭🇳",
      code: "HN",
      dialCode: "+504",
      mask: "9999-9999",
    },
    {
      pt: "Hong Kong",
      en: "Hong Kong",
      flag: "🇭🇰",
      code: "HK",
      dialCode: "+852",
      mask: "9999 9999",
    },
    {
      pt: "Hungria",
      en: "Hungary",
      flag: "🇭🇺",
      code: "HU",
      dialCode: "+36",
      mask: "(99) 999 9999",
    },
    {
      pt: "Islândia",
      en: "Iceland",
      flag: "🇮🇸",
      code: "IS",
      dialCode: "+354",
      mask: "999 9999",
    },
    {
      pt: "Índia",
      en: "India",
      flag: "🇮🇳",
      code: "IN",
      dialCode: "+91",
      mask: "99999 99999",
    },
    {
      pt: "Indonésia",
      en: "Indonesia",
      flag: "🇮🇩",
      code: "ID",
      dialCode: "+62",
      mask: "9999-999-999",
    },
    {
      pt: "Irã, República Islâmica do Golfo Pérsico",
      en: "Iran, Islamic Republic of Persian Gulf",
      flag: "🇮🇷",
      code: "IR",
      dialCode: "+98",
      mask: "9999 999 9999",
    },
    {
      pt: "Iraque",
      en: "Iraq",
      flag: "🇮🇶",
      code: "IQ",
      dialCode: "+964",
      mask: "9999 999 9999",
    },
    {
      pt: "Irlanda",
      en: "Ireland",
      flag: "🇮🇪",
      code: "IE",
      dialCode: "+353",
      mask: "999 999 9999",
    },
    {
      pt: "Ilha de Man",
      en: "Isle of Man",
      flag: "🇮🇲",
      code: "IM",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      pt: "Israel",
      en: "Israel",
      flag: "🇮🇱",
      code: "IL",
      dialCode: "+972",
      mask: "999-999-9999",
    },
    {
      pt: "Itália",
      en: "Italy",
      flag: "🇮🇹",
      code: "IT",
      dialCode: "+39",
      mask: "999 999 9999",
      icon: AppIcons.CountryFlags.Italy,
    },
    {
      pt: "Jamaica",
      en: "Jamaica",
      flag: "🇯🇲",
      code: "JM",
      dialCode: "+1876",
      mask: "(999) 999-9999",
    },
    {
      pt: "Japão",
      en: "Japan",
      flag: "🇯🇵",
      code: "JP",
      dialCode: "+81",
      mask: "999-9999-9999",
    },
    {
      pt: "Jersey",
      en: "Jersey",
      flag: "🇯🇪",
      code: "JE",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      pt: "Jordânia",
      en: "Jordan",
      flag: "🇯🇴",
      code: "JO",
      dialCode: "+962",
      mask: "99 9999 9999",
    },
    {
      pt: "Cazaquistão",
      en: "Kazakhstan",
      flag: "🇰🇿",
      code: "KZ",
      dialCode: "+7",
      mask: "(999) 999 9999",
    },
    {
      pt: "Quênia",
      en: "Kenya",
      flag: "🇰🇪",
      code: "KE",
      dialCode: "+254",
      mask: "9999 999999",
    },
    {
      pt: "Kiribati",
      en: "Kiribati",
      flag: "🇰🇮",
      code: "KI",
      dialCode: "+686",
      mask: "99999999",
    },
    {
      pt: "Coreia, República Popular Democrática da Coreia",
      en: "Korea, Democratic People's Republic of Korea",
      flag: "🇰🇵",
      code: "KP",
      dialCode: "+850",
      mask: "(9) 99999",
    },
    {
      pt: "Coreia, República da Coreia do Sul",
      en: "Korea, Republic of South Korea",
      flag: "🇰🇷",
      code: "KR",
      dialCode: "+82",
      mask: "999-9999-9999",
    },
    {
      pt: "Kosovo",
      en: "Kosovo",
      flag: "🇽🇰",
      code: "XK",
      dialCode: "+383",
      mask: "999 999 999",
    },
    {
      pt: "Kuwait",
      en: "Kuwait",
      flag: "🇰🇼",
      code: "KW",
      dialCode: "+965",
      mask: "999 99999",
    },
    {
      pt: "Quirguistão",
      en: "Kyrgyzstan",
      flag: "🇰🇬",
      code: "KG",
      dialCode: "+996",
      mask: "9999 999 999",
    },
    {
      pt: "Laos",
      en: "Laos",
      flag: "🇱🇦",
      code: "LA",
      dialCode: "+856",
      mask: "999 99 999 999",
    },
    {
      pt: "Letônia",
      en: "Latvia",
      flag: "🇱🇻",
      code: "LV",
      dialCode: "+371",
      mask: "99 999 999",
    },
    {
      pt: "Líbano",
      en: "Lebanon",
      flag: "🇱🇧",
      code: "LB",
      dialCode: "+961",
      mask: "99 999 999",
    },
    {
      pt: "Lesoto",
      en: "Lesotho",
      flag: "🇱🇸",
      code: "LS",
      dialCode: "+266",
      mask: "9999 9999",
    },
    {
      pt: "Libéria",
      en: "Liberia",
      flag: "🇱🇷",
      code: "LR",
      dialCode: "+231",
      mask: "999 999 9999",
    },
    {
      pt: "Jamahiriya Árabe Líbia",
      en: "Libyan Arab Jamahiriya",
      flag: "🇱🇾",
      code: "LY",
      dialCode: "+218",
      mask: "999-9999999",
    },
    {
      pt: "Liechtenstein",
      en: "Liechtenstein",
      flag: "🇱🇮",
      code: "LI",
      dialCode: "+423",
      mask: "999 999 999",
    },
    {
      pt: "Lituânia",
      en: "Lithuania",
      flag: "🇱🇹",
      code: "LT",
      dialCode: "+370",
      mask: "(9-999) 9999",
    },
    {
      pt: "Luxemburgo",
      en: "Luxembourg",
      flag: "🇱🇺",
      code: "LU",
      dialCode: "+352",
      mask: "999 999 999",
    },
    {
      pt: "Macau",
      en: "Macao",
      flag: "🇲🇴",
      code: "MO",
      dialCode: "+853",
      mask: "9999 9999",
    },
    {
      pt: "Macedônia",
      en: "Macedonia",
      flag: "🇲🇰",
      code: "MK",
      dialCode: "+389",
      mask: "999 999 999",
    },
    {
      pt: "Madagáscar",
      en: "Madagascar",
      flag: "🇲🇬",
      code: "MG",
      dialCode: "+261",
      mask: "999 99 999 99",
    },
    {
      pt: "Malawi",
      en: "Malawi",
      flag: "🇲🇼",
      code: "MW",
      dialCode: "+265",
      mask: "9999 99 99 99",
    },
    {
      pt: "Malásia",
      en: "Malaysia",
      flag: "🇲🇾",
      code: "MY",
      dialCode: "+60",
      mask: "999-999 9999",
    },
    {
      pt: "Maldivas",
      en: "Maldives",
      flag: "🇲🇻",
      code: "MV",
      dialCode: "+960",
      mask: "999-9999",
    },
    {
      pt: "Mali",
      en: "Mali",
      flag: "🇲🇱",
      code: "ML",
      dialCode: "+223",
      mask: "99 99 99 99",
    },
    {
      pt: "Malta",
      en: "Malta",
      flag: "🇲🇹",
      code: "MT",
      dialCode: "+356",
      mask: "9999 9999",
    },
    {
      pt: "Ilhas Marshall",
      en: "Marshall Islands",
      flag: "🇲🇭",
      code: "MH",
      dialCode: "+692",
      mask: "999-9999",
    },
    {
      pt: "Martinica",
      en: "Martinique",
      flag: "🇲🇶",
      code: "MQ",
      dialCode: "+596",
      mask: "9999 99 99 99",
    },
    {
      pt: "Mauritânia",
      en: "Mauritania",
      flag: "🇲🇷",
      code: "MR",
      dialCode: "+222",
      mask: "99 99 99 99",
    },
    {
      pt: "Maurício",
      en: "Mauritius",
      flag: "🇲🇺",
      code: "MU",
      dialCode: "+230",
      mask: "9999 9999",
    },
    {
      pt: "Maiote",
      en: "Mayotte",
      flag: "🇾🇹",
      code: "YT",
      dialCode: "+262",
      mask: "9999 99 99 99",
    },
    {
      pt: "México",
      en: "Mexico",
      flag: "🇲🇽",
      code: "MX",
      dialCode: "+52",
      mask: "999-999 9999",
    },
    {
      pt: "Micronésia, Estados Federados da Micronésia",
      en: "Micronesia, Federated States of Micronesia",
      flag: "🇫🇲",
      code: "FM",
      dialCode: "+691",
      mask: "999 9999",
    },
    {
      pt: "Moldávia",
      en: "Moldova",
      flag: "🇲🇩",
      code: "MD",
      dialCode: "+373",
      mask: "9999 99 999",
    },
    {
      pt: "Mônaco",
      en: "Monaco",
      flag: "🇲🇨",
      code: "MC",
      dialCode: "+377",
      mask: "99 99 99 99 99",
    },
    {
      pt: "Mongólia",
      en: "Mongolia",
      flag: "🇲🇳",
      code: "MN",
      dialCode: "+976",
      mask: "9999 9999",
    },
    {
      pt: "Montenegro",
      en: "Montenegro",
      flag: "🇲🇪",
      code: "ME",
      dialCode: "+382",
      mask: "999 999 999",
    },
    {
      pt: "Montserrate",
      en: "Montserrat",
      flag: "🇲🇸",
      code: "MS",
      dialCode: "+1664",
      mask: "(999) 999-9999",
    },
    {
      pt: "Marrocos",
      en: "Morocco",
      flag: "🇲🇦",
      code: "MA",
      dialCode: "+212",
      mask: "9999-999999",
    },
    {
      pt: "Moçambique",
      en: "Mozambique",
      flag: "🇲🇿",
      code: "MZ",
      dialCode: "+258",
      mask: "99 999 9999",
    },
    {
      pt: "Mianmar",
      en: "Myanmar",
      flag: "🇲🇲",
      code: "MM",
      dialCode: "+95",
      mask: "99 999 9999",
    },
    {
      pt: "Namíbia",
      en: "Namibia",
      flag: "🇳🇦",
      code: "NA",
      dialCode: "+264",
      mask: "999 999 99999",
    },
    {
      pt: "Nauru",
      en: "Nauru",
      flag: "🇳🇷",
      code: "NR",
      dialCode: "+674",
      mask: "999 9999",
    },
    {
      pt: "Nepal",
      en: "Nepal",
      flag: "🇳🇵",
      code: "NP",
      dialCode: "+977",
      mask: "999-9999999",
    },
    {
      pt: "Holanda",
      en: "Netherlands",
      flag: "🇳🇱",
      code: "NL",
      dialCode: "+31",
      mask: "99 99999999",
    },
    {
      pt: "Nova Caledônia",
      en: "New Caledonia",
      flag: "🇳🇨",
      code: "NC",
      dialCode: "+687",
      mask: "99.99.99",
    },
    {
      pt: "Nova Zelândia",
      en: "New Zealand",
      flag: "🇳🇿",
      code: "NZ",
      dialCode: "+64",
      mask: "999 999 9999",
    },
    {
      pt: "Nicarágua",
      en: "Nicaragua",
      flag: "🇳🇮",
      code: "NI",
      dialCode: "+505",
      mask: "9999 9999",
    },
    {
      pt: "Níger",
      en: "Niger",
      flag: "🇳🇪",
      code: "NE",
      dialCode: "+227",
      mask: "99 99 99 99",
    },
    {
      pt: "Nigéria",
      en: "Nigeria",
      flag: "🇳🇬",
      code: "NG",
      dialCode: "+234",
      mask: "9999 999 9999",
    },
    {
      pt: "Niu",
      en: "Niue",
      flag: "🇳🇺",
      code: "NU",
      dialCode: "+683",
      mask: "999 9999",
    },
    {
      pt: "Ilha Norfolk",
      en: "Norfolk Island",
      flag: "🇳🇫",
      code: "NF",
      dialCode: "+672",
      mask: "9 99999",
    },
    {
      pt: "Ilhas Marianas do Norte",
      en: "Northern Mariana Islands",
      flag: "🇲🇵",
      code: "MP",
      dialCode: "+1670",
      mask: "(999) 999-9999",
    },
    {
      pt: "Noruega",
      en: "Norway",
      flag: "🇳🇴",
      code: "NO",
      dialCode: "+47",
      mask: "999 99 999",
    },
    {
      pt: "Omã",
      en: "Oman",
      flag: "🇴🇲",
      code: "OM",
      dialCode: "+968",
      mask: "9999 9999",
    },
    {
      pt: "Paquistão",
      en: "Pakistan",
      flag: "🇵🇰",
      code: "PK",
      dialCode: "+92",
      mask: "9999 9999999",
    },
    {
      pt: "Palaú",
      en: "Palau",
      flag: "🇵🇼",
      code: "PW",
      dialCode: "+680",
      mask: "999 9999",
    },
    {
      pt: "Território Palestino, Ocupado",
      en: "Palestinian Territory, Occupied",
      flag: "🇵🇸",
      code: "PS",
      dialCode: "+970",
      mask: "9999 999 999",
    },
    {
      pt: "Panamá",
      en: "Panama",
      flag: "🇵🇦",
      code: "PA",
      dialCode: "+507",
      mask: "9999-9999",
    },
    {
      pt: "Papua Nova Guiné",
      en: "Papua New Guinea",
      flag: "🇵🇬",
      code: "PG",
      dialCode: "+675",
      mask: "9999 9999",
    },
    {
      pt: "Paraguai",
      en: "Paraguay",
      flag: "🇵🇾",
      code: "PY",
      dialCode: "+595",
      mask: "9999 999999",
    },
    {
      pt: "Peru",
      en: "Peru",
      flag: "🇵🇪",
      code: "PE",
      dialCode: "+51",
      mask: "999 999 999",
    },
    {
      pt: "Filipinas",
      en: "Philippines",
      flag: "🇵🇭",
      code: "PH",
      dialCode: "+63",
      mask: "9999 999 9999",
    },
    {
      pt: "Polônia",
      en: "Poland",
      flag: "🇵🇱",
      code: "PL",
      dialCode: "+48",
      mask: "999 999 999",
    },
    {
      pt: "Portugal",
      en: "Portugal",
      flag: "🇵🇹",
      code: "PT",
      dialCode: "+351",
      mask: "999 999 999",
      enabled: true,
      icon: AppIcons.CountryFlags.Portugal,
    },
    {
      pt: "Porto Rico",
      en: "Puerto Rico",
      flag: "🇵🇷",
      code: "PR",
      dialCode: "+1939",
      mask: "(999) 999-9999",
    },
    {
      pt: "Catar",
      en: "Qatar",
      flag: "🇶🇦",
      code: "QA",
      dialCode: "+974",
      mask: "9999 9999",
    },
    {
      pt: "Romênia",
      en: "Romania",
      flag: "🇷🇴",
      code: "RO",
      dialCode: "+40",
      mask: "9999 999 999",
    },
    {
      pt: "Rússia",
      en: "Russia",
      flag: "🇷🇺",
      code: "RU",
      dialCode: "+7",
      mask: "(999) 999-99-99",
    },
    {
      pt: "Ruanda",
      en: "Rwanda",
      flag: "🇷🇼",
      code: "RW",
      dialCode: "+250",
      mask: "9999 999 999",
    },
    {
      pt: "São Bartolomeu",
      en: "Saint Barthelemy",
      flag: "🇧🇱",
      code: "BL",
      dialCode: "+590",
      mask: "9999 99 99 99",
    },
    {
      pt: "Santa Helena, Ascensão e Tristão Da Cunha",
      en: "Saint Helena, Ascension and Tristan Da Cunha",
      flag: "🇸🇭",
      code: "SH",
      dialCode: "+290",
      mask: "99999",
    },
    {
      pt: "São Cristóvão e Nevis",
      en: "Saint Kitts and Nevis",
      flag: "🇰🇳",
      code: "KN",
      dialCode: "+1869",
      mask: "(999) 999-9999",
    },
    {
      pt: "Santa Lúcia",
      en: "Saint Lucia",
      flag: "🇱🇨",
      code: "LC",
      dialCode: "+1758",
      mask: "(999) 999-9999",
    },
    {
      pt: "São Martinho",
      en: "Saint Martin",
      flag: "🇲🇫",
      code: "MF",
      dialCode: "+590",
      mask: "9999 99 99 99",
    },
    {
      pt: "São Pedro e Miquelon",
      en: "Saint Pierre and Miquelon",
      flag: "🇵🇲",
      code: "PM",
      dialCode: "+508",
      mask: "999 99 99",
    },
    {
      pt: "São Vicente e Granadinas",
      en: "Saint Vincent and the Grenadines",
      flag: "🇻🇨",
      code: "VC",
      dialCode: "+1784",
      mask: "(999) 999-9999",
    },
    {
      pt: "Samoa",
      en: "Samoa",
      flag: "🇼🇸",
      code: "WS",
      dialCode: "+685",
      mask: "99 99999",
    },
    {
      pt: "São Marinho",
      en: "San Marino",
      flag: "🇸🇲",
      code: "SM",
      dialCode: "+378",
      mask: "99 99 99 99",
    },
    {
      pt: "São Tomé e Príncipe",
      en: "Sao Tome and Principe",
      flag: "🇸🇹",
      code: "ST",
      dialCode: "+239",
      mask: "999 9999",
    },
    {
      pt: "Arábia Saudita",
      en: "Saudi Arabia",
      flag: "🇸🇦",
      code: "SA",
      dialCode: "+966",
      mask: "999 999 9999",
    },
    {
      pt: "Senegal",
      en: "Senegal",
      flag: "🇸🇳",
      code: "SN",
      dialCode: "+221",
      mask: "99 999 99 99",
    },
    {
      pt: "Sérvia",
      en: "Serbia",
      flag: "🇷🇸",
      code: "RS",
      dialCode: "+381",
      mask: "999 9999999",
    },
    {
      pt: "Seychelles",
      en: "Seychelles",
      flag: "🇸🇨",
      code: "SC",
      dialCode: "+248",
      mask: "9 999 999",
    },
    {
      pt: "Serra Leoa",
      en: "Sierra Leone",
      flag: "🇸🇱",
      code: "SL",
      dialCode: "+232",
      mask: "(999) 999999",
    },
    {
      pt: "Cingapura",
      en: "Singapore",
      flag: "🇸🇬",
      code: "SG",
      dialCode: "+65",
      mask: "9999 9999",
    },
    {
      pt: "Eslováquia",
      en: "Slovakia",
      flag: "🇸🇰",
      code: "SK",
      dialCode: "+421",
      mask: "9999 999 999",
    },
    {
      pt: "Eslovénia",
      en: "Slovenia",
      flag: "🇸🇮",
      code: "SI",
      dialCode: "+386",
      mask: "999 999 999",
    },
    {
      pt: "Ilhas Salomão",
      en: "Solomon Islands",
      flag: "🇸🇧",
      code: "SB",
      dialCode: "+677",
      mask: "99 99999",
    },
    {
      pt: "Somália",
      en: "Somalia",
      flag: "🇸🇴",
      code: "SO",
      dialCode: "+252",
      mask: "9 9999999",
    },
    {
      pt: "África do Sul",
      en: "South Africa",
      flag: "🇿🇦",
      code: "ZA",
      dialCode: "+27",
      mask: "999 999 9999",
    },
    {
      pt: "Sudão do Sul",
      en: "South Sudan",
      flag: "🇸🇸",
      code: "SS",
      dialCode: "+211",
      mask: "9999 999 999",
    },
    {
      pt: "Espanha",
      en: "Spain",
      flag: "🇪🇸",
      code: "ES",
      dialCode: "+34",
      mask: "999 99 99 99",
    },
    {
      pt: "Sri Lanka",
      en: "Sri Lanka",
      flag: "🇱🇰",
      code: "LK",
      dialCode: "+94",
      mask: "999 999 9999",
    },
    {
      pt: "Sudão",
      en: "Sudan",
      flag: "🇸🇩",
      code: "SD",
      dialCode: "+249",
      mask: "999 999 9999",
    },
    {
      pt: "Suriname",
      en: "Suriname",
      flag: "🇸🇷",
      code: "SR",
      dialCode: "+597",
      mask: "999-9999",
    },
    {
      pt: "Svalbard e Jan Mayen",
      en: "Svalbard and Jan Mayen",
      flag: "🇸🇯",
      code: "SJ",
      dialCode: "+47",
      mask: "999 99 999",
    },
    {
      pt: "Suazilândia",
      en: "Swaziland",
      flag: "🇸🇿",
      code: "SZ",
      dialCode: "+268",
      mask: "9999 9999",
    },
    {
      pt: "Suécia",
      en: "Sweden",
      flag: "🇸🇪",
      code: "SE",
      dialCode: "+46",
      mask: "999-999 99 99",
    },
    {
      pt: "Suíça",
      en: "Switzerland",
      flag: "🇨🇭",
      code: "CH",
      dialCode: "+41",
      mask: "999 999 99 99",
    },
    {
      pt: "República Árabe da Síria",
      en: "Syrian Arab Republic",
      flag: "🇸🇾",
      code: "SY",
      dialCode: "+963",
      mask: "9999 999 999",
    },
    {
      pt: "Taiwan",
      en: "Taiwan",
      flag: "🇹🇼",
      code: "TW",
      dialCode: "+886",
      mask: "9999 999 999",
    },
    {
      pt: "Tajiquistão",
      en: "Tajikistan",
      flag: "🇹🇯",
      code: "TJ",
      dialCode: "+992",
      mask: "999 99 9999",
    },
    {
      pt: "Tanzânia, República Unida da Tanzânia",
      en: "Tanzania, United Republic of Tanzania",
      flag: "🇹🇿",
      code: "TZ",
      dialCode: "+255",
      mask: "9999 999 999",
    },
    {
      pt: "Tailândia",
      en: "Thailand",
      flag: "🇹🇭",
      code: "TH",
      dialCode: "+66",
      mask: "999 999 9999",
    },
    {
      pt: "Timor-Leste",
      en: "Timor-Leste",
      flag: "🇹🇱",
      code: "TL",
      dialCode: "+670",
      mask: "9999 9999",
    },
    {
      pt: "Togo",
      en: "Togo",
      flag: "🇹🇬",
      code: "TG",
      dialCode: "+228",
      mask: "99 99 99 99",
    },
    {
      pt: "Toquela",
      en: "Tokelau",
      flag: "🇹🇰",
      code: "TK",
      dialCode: "+690",
      mask: "9999",
    },
    {
      pt: "Tonga",
      en: "Tonga",
      flag: "🇹🇴",
      code: "TO",
      dialCode: "+676",
      mask: "999 9999",
    },
    {
      pt: "Trinidad e Tobago",
      en: "Trinidad and Tobago",
      flag: "🇹🇹",
      code: "TT",
      dialCode: "+1868",
      mask: "(999) 999-9999",
    },
    {
      pt: "Tunísia",
      en: "Tunisia",
      flag: "🇹🇳",
      code: "TN",
      dialCode: "+216",
      mask: "99 999 999",
    },
    {
      pt: "Peru",
      en: "Turkey",
      flag: "🇹🇷",
      code: "TR",
      dialCode: "+90",
      mask: "(999) 999 9999",
    },
    {
      pt: "Turquemenistão",
      en: "Turkmenistan",
      flag: "🇹🇲",
      code: "TM",
      dialCode: "+993",
      mask: "9 99 999999",
    },
    {
      pt: "Ilhas Turcas e Caicos",
      en: "Turks and Caicos Islands",
      flag: "🇹🇨",
      code: "TC",
      dialCode: "+1649",
      mask: "(999) 999-9999",
    },
    {
      pt: "Tuval",
      en: "Tuvalu",
      flag: "🇹🇻",
      code: "TV",
      dialCode: "+688",
      mask: "999999",
    },
    {
      pt: "Uganda",
      en: "Uganda",
      flag: "🇺🇬",
      code: "UG",
      dialCode: "+256",
      mask: "9999 999999",
    },
    {
      pt: "Ucrânia",
      en: "Ukraine",
      flag: "🇺🇦",
      code: "UA",
      dialCode: "+380",
      mask: "999 999 9999",
    },
    {
      pt: "Emirados Árabes Unidos",
      en: "United Arab Emirates",
      flag: "🇦🇪",
      code: "AE",
      dialCode: "+971",
      mask: "999 999 9999",
    },
    {
      pt: "Reino Unido",
      en: "United Kingdom",
      flag: "🇬🇧",
      code: "GB",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      pt: "Estados Unidos",
      en: "United States",
      flag: "🇺🇸",
      code: "US",
      dialCode: "+1",
      mask: "(999) 999-9999",
    },
    {
      pt: "Uruguai",
      en: "Uruguay",
      flag: "🇺🇾",
      code: "UY",
      dialCode: "+598",
      mask: "99 999 999",
    },
    {
      pt: "Uzbequistão",
      en: "Uzbekistan",
      flag: "🇺🇿",
      code: "UZ",
      dialCode: "+998",
      mask: "9 99 999 99 99",
    },
    {
      pt: "Vanuatu",
      en: "Vanuatu",
      flag: "🇻🇺",
      code: "VU",
      dialCode: "+678",
      mask: "999 9999",
    },
    {
      pt: "Venezuela, República Bolivariana da Venezuela",
      en: "Venezuela, Bolivarian Republic of Venezuela",
      flag: "🇻🇪",
      code: "VE",
      dialCode: "+58",
      mask: "9999-9999999",
    },
    {
      pt: "Vietnã",
      en: "Vietnam",
      flag: "🇻🇳",
      code: "VN",
      dialCode: "+84",
      mask: "999 999 99 99",
    },
    {
      pt: "Wallis e Futuna",
      en: "Wallis and Futuna",
      flag: "🇼🇫",
      code: "WF",
      dialCode: "+681",
      mask: "99 99 99",
    },
    {
      pt: "Iémen",
      en: "Yemen",
      flag: "🇾🇪",
      code: "YE",
      dialCode: "+967",
      mask: "999 999 999",
    },
    {
      pt: "Zâmbia",
      en: "Zambia",
      flag: "🇿🇲",
      code: "ZM",
      dialCode: "+260",
      mask: "999 9999999",
    },
    {
      pt: "Zimbábue",
      en: "Zimbabwe",
      flag: "🇿🇼",
      code: "ZW",
      dialCode: "+263",
      mask: "999 999 9999",
    },
  ],
});
