import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const Container: any = styled(Grid)`
  && {
    padding: 24px 20px;
    border-radius: 8px;
    border: 1px solid #e4e7eb;
    background: #fff;
  }
`;

export const Name: any = styled(Typography)`
  && {
    font-family: "InterSemiBold", sans-serif;
    color: #111827;
    font-size: 14px;
    font-style: normal;
    line-height: 140%;
    letter-spacing: -0.14px;
  }
`;

export const PreName: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #374151;
    font-size: 14px;
    font-style: normal;
    line-height: 140%;
    letter-spacing: -0.14px;
  }
`;

export const Created: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #6b7280;
    font-size: 12px;
    font-style: normal;
    line-height: normal;

    &.date {
      color: #4b5563;
      font-size: 14px;
    }
  }
`;
