import MUICloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Transition from "resources/components/transition";
import { Dialog, Grid } from "./styles";
import { ContentInfoModalOptions } from "./types";

export function ContentInfoModal({
  open,
  close,
  info,
}: ContentInfoModalOptions): JSX.Element {
  return (
    <Dialog open={open} onClose={close} TransitionComponent={Transition}>
      <Grid container className="dialogHeader">
        <Grid
          container
          flexWrap="nowrap"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <MUICloseIcon
            onClick={close}
            sx={{ color: "rgba(115, 115, 115, 0.6)" }}
          />
        </Grid>
      </Grid>

      <Grid container className="dialogBody">
        <Typography component="p">{info}</Typography>
      </Grid>
    </Dialog>
  );
}
