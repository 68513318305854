import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Grid)`
  && {
    background: #f3f4f6;
    border-radius: 8px;
    min-width: 166px;
    max-width: 166px;
    cursor: pointer;
  }
`;

export const Content = styled(Grid)`
  && {
    flex-direction: column;
    padding: 10px 16px 24px;
  }
`;

export const Title = styled(Typography)`
  && {
    color: var(--foundation-gray-Gray-800, var(--gray-80, #1f2937));
    font-feature-settings: "clig" off, "liga" off;
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.14px;
    white-space: normal;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;
