import { useNavigate } from "react-router-dom";
import { Button as PrimaryButton } from "resources/components/buttons/main";
import { Container } from "./styles";
import { ButtonOptions } from "./types";

export default function Button({ label, url }: ButtonOptions): JSX.Element {
  const navigate = useNavigate();

  const onClick = () => {
    const regex =
      /^(https?:\/\/)?((app(-dev)?\.odgo\.com\.br)|localhost:3000)(\/[^\s]*)?/;
    const match = url.match(regex);
    const internalPath = match && match[5];

    if (internalPath) {
      navigate(internalPath);
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <Container>
      <PrimaryButton
        type="button"
        title={label}
        onClick={onClick}
        style={{
          maxWidth: "375px",
        }}
      />
    </Container>
  );
}
