import { City } from "./city.entity";
import { UserPlan } from "./plan.entity";
import { UserSetting } from "./user-setting.entity";
import { Visa } from "./visa.entity";

export interface User {
  id: number;
  name: string;
  email: string;
  birthday: string;
  avatar: string;
  city?: City;
  destination_city?: City;
  destination_city_id: string;
  departure_estimate: string;
  budget: string;
  major: string;
  occupation: string;
  migration_companion: string;
  profile_completed: boolean;
  visa?: Visa;
  sessions_count: number;
  task_viewed_count: number;
  finance_tool_options: any;
  remessa_online_customer_id: string;
  phone: string;
  calling_code: string;
  community_join_reason: string;
  member_status: string;
  stage: string;
  is_migrating_soon: boolean;
  has_migrated: boolean;
  stage_transaction_mascot_message: any;
  stage_transaction_message: any;
  total_incomes: any;
  total_spends: any;
  migration_level: string;
  migration_percentage: number;
  settings: UserSetting;
  invited_users_count: number;
  migration_score_reasons?: MigrationScoreReasons;
  groups: GroupOptions[];
  days_after_signup?: number;
  subscription_plan?: UserPlan;
}

export interface MigrationScoreReasons {
  migration_adults?: string;
  migration_deal?: string;
  migration_deadline?: string;
  visa_process_status?: string;
  total_spends?: string;
}

export interface FinanceToolOptions {
  adults: number;
  city: number;
  flight_ticket: number;
  healt_insurance: number;
  home: string;
  home_bills: string;
  lounge: string;
  market: string;
  minors: number;
  reserve: number;
  reserve_brl: number;
  monthly_income: number;
  job_offer_value: number;
  transport: string;
  visa: string;
  income_source: string;
  experience_level: string;
  preferred_work_area: string;
  reserve_eur: number;
  monthly_income_eur: number;
  monthly_income_expectation: number;
}

export interface GroupOptions {
  name: string | undefined;
  member_since: string | undefined;
  members_count: number | undefined;
  whatsapp_id: string | undefined;
  moderators: any[];
}

export class UserConvert {
  public static toUser(json: string): User {
    return JSON.parse(json);
  }

  public static userToJson(value: User): string {
    return JSON.stringify(value);
  }
}
