import { AppRoutes } from "app/enums/app-route.enum";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { HelmetHandler } from "resources/components/handlers/helmet";
import { WelcomePartial } from "resources/components/welcome";
import AuthenticationLayout from "resources/layouts/authentication";

const PAGE_TITLE = "Entrar na Ailu";

export default function WelcomeSignInPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  function onSignInClick(): void {
    navigate(AppRoutes.SignInForm);
  }

  function onSignUpClick(): void {
    navigate(AppRoutes.SignUp);
  }

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout>
        <WelcomePartial
          title={PAGE_TITLE}
          subtitle={"Que bom ter você de volta."}
          onSignInClick={onSignInClick}
          onSignUpClick={onSignUpClick}
        />
      </AuthenticationLayout>
    </>
  );
}
