export const MixPanelParamConstants = Object.freeze({
  PAGE: "Page" as string,
  COMMENT: "Comment" as string,
  VOTE_SELECTED: "Vote selected" as string,
  SEARCHED_CONTENT: "Searched Content" as string,
  TOOL_USED: "Tool Used" as string,
  FIELD: "$Field" as string,
  USER_LEVEL: "Level" as string,
  JOINED_WHATSAPP: "Joined WhatsApp?" as string,
  MOTIVATION: "Motivation" as string,
  REACTION_TYPES: "Reaction Types" as string,
  CATEGORIES: "Categories" as string,
  POST_TITLE: "Post Title" as string,
  ANONYMOUS: "Anonymous" as string,
  CATEGORY: "Category" as string,
  VARIANT: "Variant" as string,
  DAYS_AFTER_SIGNUP: "Days After SignUp" as string,
  SURVEY_NAME: "Survey Name" as string,
  SCORE: "Score" as string,
  TAG: "Tag" as string,
  WALK_THROUGH_TYPE: "Walk Through Type" as string,
  COMMUNITY_OPT_IN: "CommunityOptIn",
  STEP: "Step" as string,
  OPTION: "Option" as string,
  PROVIDER: "Provider" as string,
  SECTION: "Section" as string,
  REWARD_NAME: "Reward Name" as string,
  TASK_NAME: "Task Name" as string,
  STATUS: "Status" as string,
  TYPE: "Type" as string,
  ONBOARDING_STARTED: "Onboarding started" as string,
});

export const MixPanelFieldConstants = Object.freeze({
  NAME: "Name" as string,
  PHONE: "Phone" as string,
  PHONE_CALLING_CODE: "PhoneCallingCode" as string,
  EMAIL: "Email" as string,
  COMMENT: "Comment" as string,
  USER_ID: "UserID" as string,
  INVITATION_LINK: "InvitationLink" as string,
  IMMIGRATION_PLAN: "ImmigrationPlan" as string,
  MEMBER_STATUS: "MemberStatus" as string,
  COMMUNITY_STEP: "CommunityStep" as string,
  REFERRAL_LINK: "ReferralLink" as string,
  IMMIGRATION_LEVEL: "Immigration Level" as string,
  USERS_INVITED: "Users Invited" as string,
  MOTIVATION: "Motivation" as string,
  JOINED_WHATS_APP_GENERAL_GROUP: "Joined WhatsApp General Group" as string,
  TOOL_USED: "Tool Used" as string,
  PARTNER_USED: "Partner Used" as string,
  USER_WHO_JOINED: "User who joined" as string,
  BIRTH_DATE: "Birth Date" as string,
  OCCUPATION: "Occupation" as string,
  INVITED_BY: "Invited By" as string,
  SIGN_UP_REFERRER: "SignUpReferrer" as string,
  AGE_RANGE: "AgeRange",
  EDUCATION_LEVEL: "EducationLevel",
  IMMIGRATION_MOMENT: "ImmigrationMoment",
  IMMIGRATION_WAY: "ImmigrationWay",
  IMMIGRATION_OBJECTIVE: "ImmigrationObjective",
  IMMIGRATION_WORK_TYPE: "ImmigrationWorkType",
});
