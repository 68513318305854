import { Grid, IconButton } from "@mui/material";
import { AppIcons } from "configuration/asset.configuration";
import { Container, Subtitle, Title } from "./styles";
import { SuccessMessageProps } from "./types";

export function SuccessMessage({
  title,
  subtitle,
  whatsAppUrl,
  telegramUrl,
  twitterUrl,
}: SuccessMessageProps): JSX.Element {
  function openUrl(url: string): void {
    window.open(url, "_blank", "noreferrer");
  }

  return (
    <Container>
      <Grid mb={"8px"}>
        <Title>{title}</Title>
      </Grid>

      <Grid mb={"16px"}>
        <Subtitle>{subtitle}</Subtitle>
      </Grid>

      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        gap={"12px"}
      >
        {whatsAppUrl && (
          <IconButton onClick={() => openUrl(whatsAppUrl)}>
            <AppIcons.Social.WhatsApp />
          </IconButton>
        )}

        {telegramUrl && (
          <IconButton onClick={() => openUrl(telegramUrl)}>
            <AppIcons.Social.Telegram />
          </IconButton>
        )}

        {twitterUrl && (
          <IconButton onClick={() => openUrl(twitterUrl)}>
            <AppIcons.Social.X />
          </IconButton>
        )}
      </Grid>
    </Container>
  );
}
