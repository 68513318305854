import useObjectHelper from "app/helpers/object.helper";

function useStorageProvider() {
  const storageProvider = localStorage;

  const { parseJSONOrReturnOriginal } = useObjectHelper();

  /**
   * @returns {any}
   */
  function getItem(key: string): any {
    let foundItem = storageProvider.getItem(key);

    if (foundItem) {
      foundItem = parseJSONOrReturnOriginal(foundItem);
    }

    return foundItem;
  }

  /**
   * @returns {void}
   */
  function setItem(key: string, value: string): void {
    storageProvider.setItem(key, value);
  }

  /**
   * @returns {void}
   */
  function removeItem(key: string): void {
    storageProvider.removeItem(key);
  }

  /**
   * @returns {void}
   */
  function clear(): void {
    storageProvider.clear();
  }

  /**
   * @returns {void}
   */
  function multiRemove(keys: Array<string>): void {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    multiRemove,
  };
}

export default useStorageProvider;
