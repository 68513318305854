import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { ProgressBarOptions } from "./types";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  width: 60,
  borderRadius: 5,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#030712" : "##E6E6E6",
  },
}));

export function ProgressBar({ value }: ProgressBarOptions): JSX.Element {
  return (
    <>
      <BorderLinearProgress variant="determinate" value={value} />
    </>
  );
}
