import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { User } from "app/entities/user.entity";
import useAuthenticationService from "app/services/authentication.service";
import useProfileService from "app/services/profile.service";
import { AxiosResponse, HttpStatusCode } from "axios";

export default function useAuthenticatedUser() {
  const { changeUser } = useAuthentication();
  const { updateUser: updateProfile } = useProfileService();
  const { getAuthenticatedUser } = useAuthenticationService();

  async function updateUser(payload: any): Promise<User | undefined> {
    const requestResponse: AxiosResponse<any> = await updateProfile(payload);

    if (requestResponse.status === HttpStatusCode.Ok) {
      const userEntity: User = requestResponse.data.data;

      if (userEntity) {
        changeUser(userEntity);

        return userEntity;
      }
    }

    return undefined;
  }

  async function refreshUser(): Promise<void> {
    const userResponse = await getAuthenticatedUser();

    changeUser(userResponse.data.data);
  }

  return {
    updateUser,
    refreshUser,
  };
}
