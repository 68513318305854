export function pluralize(attribute: string) {
  return attribute.endsWith("s") ? attribute : `${attribute}s`;
}

export function singularize(attribute: string) {
  return attribute.endsWith("s") ? attribute.slice(0, -1) : attribute;
}

export function titleCase(attribute: string) {
  return attribute.charAt(0).toUpperCase() + attribute.slice(1);
}
