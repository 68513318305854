import styled from "styled-components";

export const RichTextContainer = styled.div`
  font-size: 0.875rem; /* 14/16 rem */
  line-height: 1.5; /* theme.lineHeights[6] */
  margin-top: 24px;

  font-family: "InterRegular", sans-serif;
  font-size: 16px;
  line-height: 156%;
  color: var(--primria-escuros-preto-cinza-e-branco-600, #595959);
  letter-spacing: -0.32px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "InterMedium", sans-serif;
    line-height: 156%;
    margin: 8px 0px;
    color: black;
    margin-block-start: 8px; /* theme.spaces[2] */
    margin-block-end: 8px;
  }

  p {
    margin-bottom: 8px; /* theme.spaces[2] */
  }

  h1 {
    font-size: 2.25rem; /* 36/16 rem */
    font-weight: 600; /* theme.fontWeights.bold */
  }

  h2 {
    font-size: 1.875rem; /* 30/16 rem */
    font-weight: 500; /* theme.fontWeights.semiBold */
  }

  h3 {
    font-size: 1.5rem; /* 24/16 rem */
    font-weight: 500; /* theme.fontWeights.semiBold */
  }

  h4 {
    font-size: 1.25rem; /* 20/16 rem */
    font-weight: 500; /* theme.fontWeights.semiBold */
  }

  strong {
    font-weight: 800; /* No exact value in theme, using hard-coded value */
  }

  em {
    font-style: italic;
  }

  blockquote {
    margin-top: 32px; /* theme.spaces[7] */
    margin-bottom: 28px; /* theme.spaces[7] */
    font-size: 0.875rem; /* 14/16 rem */
    font-weight: 400; /* theme.fontWeights.regular */
    border-left: 4px solid #e0e0e0; /* Placeholder color */
    padding: 8px 20px; /* theme.spaces[2] and theme.spaces[5] */
  }

  img {
    max-width: 100%;
  }

  table {
    thead {
      background: #e0e0e0; /* Placeholder color */

      th {
        padding: 16px; /* theme.spaces[4] */
      }
    }
    tr {
      border: 1px solid #d0d0d0; /* Placeholder color */
    }
    th,
    td {
      padding: 16px; /* theme.spaces[4] */
      border: 1px solid #d0d0d0; /* Placeholder color */
      border-bottom: 0;
      border-top: 0;
    }
  }

  pre,
  code {
    font-size: 0.875rem; /* 14/16 rem */
    border-radius: 4px; /* theme.borderRadius */
    background-color: #32324d; /* Hard-coded */
    max-width: 100%;
    overflow: auto;
    padding: 8px; /* theme.spaces[2] */
  }

  /* Inline code */
  p,
  pre,
  td {
    > code {
      color: #839496; /* Hard-coded */
    }
  }

  ol {
    list-style-type: decimal;
    margin-block-start: 16px; /* theme.spaces[4] */
    margin-block-end: 16px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 16px; /* theme.spaces[4] */

    ol,
    ul {
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
  }

  ul {
    list-style-type: disc;
    margin-block-start: 16px; /* theme.spaces[4] */
    margin-block-end: 16px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 16px; /* theme.spaces[4] */

    ul,
    ol {
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
  }
`;
