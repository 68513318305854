import { ReactComponent as Brazil } from "resources/assets/icons/country-flags/br.svg";
import { ReactComponent as Italy } from "resources/assets/icons/country-flags/it.svg";
import { ReactComponent as Portugal } from "resources/assets/icons/country-flags/pt.svg";
import { ReactComponent as Telegram } from "resources/assets/icons/social/telegram.svg";
import { ReactComponent as WhatsApp } from "resources/assets/icons/social/whatsapp.svg";
import { ReactComponent as X } from "resources/assets/icons/social/x.svg";

import { ReactComponent as DesktopSuccessIcon } from "resources/assets/icons/sales-contact/desktop-success.svg";
import { ReactComponent as MobileSuccessIcon } from "resources/assets/icons/sales-contact/mobile-success.svg";

const SocialIcons = {
  WhatsApp,
  X,
  Telegram,
};

const CountryFlags = {
  Brazil,
  Portugal,
  Italy,
};

const SalesContact = {
  DesktopSuccessIcon,
  MobileSuccessIcon,
};

export const AppIcons = {
  CountryFlags,
  SalesContact,
  Social: SocialIcons,
};
