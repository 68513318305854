import React, {
  createContext,
  useContext,
  useState,
  cloneElement,
} from "react";

// @ts-ignore
const DialogContext: any = createContext();

const DialogProvider = ({ children }) => {
  const [_globalDialog, setGlobalDialog] = useState<any>(null);
  const [_openGlobalDialog, setOpenGlobalDialog] = useState<any>(false);

  const globalDialog = _globalDialog
    ? cloneElement(_globalDialog, { open: _openGlobalDialog })
    : null;

  return (
    <DialogContext.Provider value={{ setGlobalDialog, setOpenGlobalDialog }}>
      {children}
      {globalDialog}
    </DialogContext.Provider>
  );
};

function useDialog(): any {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error("useDialog só pode ser usado com o DialogProvider");
  }

  return context;
}

export { DialogProvider, useDialog };
