import { Grid, Typography } from "@mui/material";
import { ReactComponent as ErrorImage } from "resources/assets/icons/error.svg";
import { InputErrorOptions } from "./types";

export function InputError({ title }: InputErrorOptions): JSX.Element {
  return (
    <>
      <Grid
        container
        flexWrap="nowrap"
        alignItems="center"
        gap={1.5}
        sx={{
          background: "var(--error-50)",
          padding: "4px 16px",
          borderRadius: 1,
          mt: 1,
        }}
      >
        <ErrorImage />

        <Grid container flexDirection="column">
          <Typography
            variant="body1"
            sx={{
              fontFamily: "InterRegular, sans-serif",
              fontSize: "14px",
              lineHeight: "23.8px",
              color: "var(--error-700)",
            }}
          >
            <p>{title}</p>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
