export const InputConstants = Object.freeze({
  ID: "id",
  NAME: "name",
  EMAIL: "email",
  PASSWORD: "password",
  CURRENT_PASSWORD: "current_password",
  PASSWORD_CONFIRMATION: "password_confirmation",
  PHONE: "phone",
  PHONE_NUMBER: "phone_number",
  CALLING_CODE: "calling_code",
  CATEGORY: "category",
  DOCUMENT: "document",
  ZIP_CODE: "zip_code",
  OCCUPATION: "occupation",
  BIRTHDAY: "birthday",
  STATE: "state",
  STATE_ID: "state_id",
  CITY: "city",
  CITIES: "cities",
  CITY_ID: "city_id",
  DEPARTURE_ESTIMATE: "departure_estimate",
  BUDGET: "budget",
  EXPECTANCY: "expectancy",
  MAJOR: "major",
  PURPOSE: "purpose",
  COMMUNITY_JOIN_REASON: "community_join_reason",
  ONBOARDING_VIEWED: "onboarding_viewed",
  ADULTS: "adults",
  RESERVE: "reserve",
  MINORS: "minors",
  FLIGHT_TICKET: "flight_ticket",
  HEALTH_INSURANCE: "healt_insurance",
  HOME: "home",
  HOME_BILLS: "home_bills",
  LOUNGE: "lounge",
  MARKET: "market",
  TRANSPORT: "transport",
  VISA: "visa",
  VISA_ID: "visa_id",
  INCOME_SOURCE: "income_source",
  MONTHLY_INCOME: "monthly_income",
  MONTHLY_INCOME_EUR: "monthly_income_eur",
  PREFERRED_WORK_AREA: "preferred_work_area",
  EXPERIENCE_LEVEL: "experience_level",
  JOB_OFFER_VALUE: "job_offer_value",
  RESERVE_BRL: "reserve_brl",
  RESERVE_EUR: "reserve_eur",
  MONTHLY_INCOME_EXPECTATION: "monthly_income_expectation",
  ADDRESS: "address",
  CPF: "cpf",
  RG: "rg",
  ISSUE_DATE: "issue_date",
  INSTITUTION_ISSUING: "institution_issuing",
  NUMBER: "number",
  COMPLEMENT: "complement",
  NEIGHBORHOOD: "neighborhood",
  NATIONALITY: "nationality",
  PATRIMONY: "patrimony",
  PASSPORT: "passport",
  PASSPORT_STATUS: "passport_status",
  PASSPORT_DOCUMENT: "passport_document",
  DOCUMENT_TYPE: "document_type",
  EXTRA_DOCUMENT: "extra_document",
  ENABLE_EXTRA_FILE: "enable_extra_file",
  COUNTRY: "country",
  CURRENCY: "currency",
  SWIFT: "swift",
  IBAN: "iban",
  INTERMEDIARY_BANK: "intermediaryBank",
  BANK_NAME: "bankName",
  BANK_CODE: "bankCode",
  UTM_SOURCE: "utm_source",
  UTM_CAMPAIGN: "utm_campaign",
  GCLID: "gclid",
  FBCLID: "fbclid",
  ONBOARDING_RETURN: "onb_return",
  COMMUNITY_RETURN: "comm_return",
  REFERRER_ID: "referrer_id",
  AFFILIATE_REFER: "affiliate_refer",
  TOKEN: "token",
  SCHEDULING_SKIPPED_AT: "scheduling_skipped_at",
  EVENT_SCHEDULED_AT: "event_scheduled_at",
  RECIPIENT_NAME: "recipientName",
  RECIPIENT_COUNTRY: "recipientCountry",
  RECIPIENT_ADDRESS_LINE1: "addressLine1",
  RECIPIENT_ADDRESS_LINE2: "addressLine2",
  ADDITIONAL_PEOPLE: "additional_people",
  IS_MINOR: "is_minor",
  ACCEPT_TERMS: "accept_terms",
  PAYMENT_METHOD: "payment_method",
  SEARCH: "search",
  FEEDBACK: "feedback",
  INTEREST_GROUP: "interest_group",
  GETTING_STARTED_EXPLANATION_VIEWED: "getting_started_explanation_viewed",
  NO_DESTINATION: "no_destination",
  KIDS: "kids",
  PETS: "pets",
  VISA_STATUS: "visa_status",
  MY_CHOICE: "my_choice",
  HOME_TYPE: "home_type",
  USE_UNDEFINED_CITY: "use_undefined_city",
  INCOMES: "incomes",
  MIGRATION_ADULTS: "migration_adults",
  MIGRATION_CHILDREN: "migration_children",
  MIGRATION_PETS: "migration_pets",
  MIGRATION_DEAL: "migration_deal",
  MIGRATION_DEADLINE: "migration_deadline",
  MIGRATION_STATUS: "migration_status",
  VISA_PROCESS_STATUS: "visa_process_status",
  INCOMES_TYPE: "incomes_type",
  GETTING_STARTED_FINISHED_AT: "getting_started_finished_at",
  GETTING_STARTED_STAGE: "getting_started_stage",
  ONBOARDING_STAGE: "onboarding_stage",
  USE_HABITATION_CUSTOM_VALUE: "use_habitation_custom_value",
  HABITATION: "habitation",
  HABITATION_CUSTOM_VALUE_BRL: "habitation_custom_value_brl",
  HABITATION_CUSTOM_VALUE_EUR: "habitation_custom_value_eur",
  ADULT: "adult",
  TYPE: "type",
  BRL: "brl",
  EUR: "eur",
  EXPECTATION: "expectation",
  HABITATION_VALUE: "habitation_value",
  TRANSPORT_VALUE: "transport_value",
  MARKET_VALUE: "market_value",
  LOUNGE_VALUE: "lounge_value",
  HOME_BILLS_VALUE: "home_bills_value",
  VISA_TYPES: "visa_types",
  INVALID_ITEMS: "invalid_items",
  IMMIGRATION_COMPANIONS: "immigration_companions",
  PROFESSIONAL_INTEREST: "professional_interest",
  NOT_INTERESTED_COMMUNITY_ANSWERED_AT: "not_interested_community_answered_at",
  NOT_INTERESTED_COMMUNITY_MOTIVATION: "not_interested_community_motivation",
  NOT_INTERESTED_COMMUNITY_CONFIRMED_AT:
    "not_interested_community_confirmed_at",
  ACCEPTED_INFORMATIVE_GROUP: "accepted_informative_group",
  INTERESTED_JOINING_COMMUNITY: "interested_joining_community",
  DESTINATION_CITY_ID: "destination_city_id",
  ACCEPTED_COMMUNITY_ANSWERED_AT: "accepted_community_answered_at",
  NOT_INTERESTED_COMMUNITY_FINISHED_AT: "not_interested_community_finished_at",
  AGE_RANGE: "age_range",
  EDUCATION_LEVEL: "education_level",
  REFERRER: "referrer",
  VISA_STAGE: "visa_stage",
  VISA_GOALS: "visa_goals",
  VISA_WORK_PLANS: "visa_work_plans",
  VISA_WORK_TYPES: "visa_work_types",
  VISA_STUDY_PLANS: "visa_study_plans",
  VISA_DETAILS: "visa_details",
  COMMENT: "comment",
  DEFAULT: "default",
  ORDER: "order",
  TAGS: "tags",
  FEED_EXPLANATION_VIEWED: "feed_explanation_viewed",
  SALARY: "salary",
  WORK_TIME: "work_time",
  DESCRIPTION: "description",
  CONTACT: "contact",
  CATEGORIES: "categories",
  SUBJECT: "subject",
  TITLE: "title",
  CONTENT: "content",
  ANONYMOUS: "anonymous",
  NOTIFICATION_CHANNEL: "notification_channel",
  MESSAGE: "message",
  INTRO_VIEWED_AT: "intro_viewed_at",
  WHATSAPP_AUTHORIZATION: "whatsapp_authorization",
  ENABLE_TOUR: "enable_tour",
  ENABLE_WELCOME_MODAL: "enable_welcome_modal",
  ENABLE_BUTTON: "enable_button",
  CREDIT_CARD: "credit_card",
  CREDIT_CARD_DATE: "credit_card_date",
  CREDIT_CARD_CVC: "credit_card_cvc",
});
