import styled from "styled-components";

import { Dialog as DialogMui, Grid as GridMui } from "@mui/material";

export const Dialog: any = styled(DialogMui)`
  && {
    .MuiDialog-paper {
      border-radius: 15px;
      padding: 34px 42px 52px;
      width: 100%;
      max-width: 834px;
      height: max-content;

      h6 {
        font-family: "InterMedium", sans-serif;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: -1%;
        color: var(--black);
      }

      h5 {
        font-family: "InterMedium", sans-serif;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -1%;
        color: var(--black);
      }
    }

    @media screen and (max-width: 767px) {
      .MuiDialog-paper {
        border-radius: 30px 30px 0 0;
        padding: 32px;
        margin: 0;
        width: 100%;
        height: 70vh;
        bottom: 0;
        position: absolute;
      }
    }
  }
`;

export const Grid: any = styled(GridMui)`
  && {
    &.dialogHeader {
      flex-direction: column;
      gap: 24px;
    }

    &.dialogBody {
      padding: 24px 0;

      p {
        font-family: "InterRegular", sans-serif;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -2%;
        color: var(--foundation-gray-gray-600, #4b5563);
        text-align: center;

        @media screen and (min-width: 951px) {
          font-size: 16px;
        }

        strong {
          font-family: "InterRegular", sans-serif;
          font-weight: inherit;
        }
      }
    }
  }
`;
