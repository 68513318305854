import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import ArticleProvider from "app/contexts/contents/article";
import { ArticleMainAttributes } from "app/contexts/contents/article/enums/main-attribute.enum";
import { useArticle } from "app/contexts/contents/article/hooks/article.hook";
import ContentCommentProvider from "app/contexts/contents/comment";
import { ContentCommentMainAttributes } from "app/contexts/contents/comment/enums/main-attribute.enum";
import ContentFeedbackProvider from "app/contexts/contents/feedback";
import { ContentFeedbackMainAttributes } from "app/contexts/contents/feedback/enums/main-attribute.enum";
import { ClassifiedsArticle } from "app/entities/article.entity";
import useDateHelper from "app/helpers/date.helper";
import useStrapiHelper from "app/helpers/strapi.helper";
import useRouterQuery from "app/hooks/router-query.hook";
import useNavigationProvider from "app/providers/navigation.provider";
import { useParams } from "react-router-dom";
import { ContainerScrollbar } from "resources/components/container-scrollbar";
import { ContentComments } from "resources/components/contents/comments";
import { ContentFeedback } from "resources/components/contents/feedback";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthorsBio from "resources/packages/contents/components/authors-bio";
import ClassifiedsList from "resources/packages/contents/components/classifieds-list";
import RichText from "resources/packages/contents/components/rich-text";
import { Video } from "resources/packages/contents/components/video";
import { ArticleQueryAttributes } from "resources/packages/contents/enums/article-query-attribute.enum";
import {
  ContentContainer,
  CoverImg,
  DescriptionTypography,
  TitleTypography,
  UpdatedAtTypography,
} from "resources/packages/contents/styled/styles";

export default function ViewClassifiedsArticle(): JSX.Element {
  const { id: postId }: any = useParams();

  return (
    <ArticleProvider
      postId={postId}
      mainAttribute={ArticleMainAttributes.ClassifiedArticle}
    >
      <ElementView />
    </ArticleProvider>
  );
}

function ElementView(): JSX.Element {
  const { state: articleState } = useArticle<ClassifiedsArticle>();

  const { onBackButtonPress } = useNavigationProvider();
  const { formatPtBrDate } = useDateHelper();
  const { getImageUrl } = useStrapiHelper();

  const routerQuery: URLSearchParams = useRouterQuery();
  const webviewMode: boolean = Boolean(
    routerQuery.get("webviewMode") === "true"
  );

  return (
    <>
      <HelmetHandler
        options={{
          title: articleState.contentResponse?.attributes.title ?? "Conteúdos",
        }}
        htmlTags={articleState.htmlMetaTags}
      />

      {articleState.contentResponse && (
        <ContainerScrollbar styles={{ alignItems: "center" }}>
          <Grid container justifyContent={"flex-end"}>
            {!webviewMode && (
              <CloseIcon
                onClick={onBackButtonPress}
                style={{ cursor: "pointer" }}
              />
            )}
          </Grid>

          <ContentContainer>
            <TitleTypography>
              {articleState.contentResponse.attributes.title}
            </TitleTypography>

            <DescriptionTypography>
              {articleState.contentResponse.attributes.description}
            </DescriptionTypography>

            {articleState.contentResponse.attributes.updatedAt && (
              <UpdatedAtTypography>
                {`Última atualização - ${formatPtBrDate(
                  articleState.contentResponse.attributes.updatedAt
                )}`}
              </UpdatedAtTypography>
            )}

            {articleState.contentResponse.attributes?.cover?.data && (
              <CoverImg
                src={getImageUrl(
                  articleState.contentResponse.attributes.cover.data?.attributes
                    .url
                )}
                alt={
                  articleState.contentResponse.attributes.cover.data?.attributes
                    .alternativeText
                }
              />
            )}

            {articleState.contentResponse.attributes.authorsBio.data && (
              <AuthorsBio
                authorsBio={articleState.contentResponse.attributes.authorsBio}
              />
            )}

            <RichText body={articleState.contentResponse.attributes.intro} />

            <ClassifiedsList
              items={articleState.contentResponse.attributes.list.items}
            />

            {articleState.contentResponse?.attributes?.video && (
              <Video
                embedUrl={
                  articleState.contentResponse.attributes.video.embedUrl
                }
                title={articleState.contentResponse.attributes.video.title}
                description={
                  articleState.contentResponse.attributes.video.description
                }
              />
            )}

            <RichText body={articleState.contentResponse.attributes.ending} />

            {articleState.contentId && (
              <>
                <ContentFeedbackProvider
                  contentId={articleState.contentId}
                  mainAttribute={
                    ContentFeedbackMainAttributes.ClassifiedArticleFeedbacks
                  }
                  queryAttribute={ArticleQueryAttributes.ClassifiedArticle}
                >
                  <ContentFeedback />
                </ContentFeedbackProvider>

                <ContentCommentProvider
                  contentId={articleState.contentId}
                  mainAttribute={
                    ContentCommentMainAttributes.ClassifiedArticleComments
                  }
                  queryAttribute={ArticleQueryAttributes.ClassifiedArticle}
                >
                  <ContentComments />
                </ContentCommentProvider>
              </>
            )}
          </ContentContainer>
        </ContainerScrollbar>
      )}
    </>
  );
}
