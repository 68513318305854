import { WindowConstants } from "app/constants/window.constants";
import useWindowSize from "app/hooks/use-window-size.hook";

const defaultPaddingProps = {
  [WindowConstants.DEFAULT.key]: {
    padding: "40px",
  },
  [WindowConstants.SMALL_MOBILE.key]: {
    padding: "10px 10px 54px 10px",
  },
  [WindowConstants.MOBILE.key]: {
    padding: "20px 20px 54px 20px",
  },
  [WindowConstants.TABLET.key]: {
    padding: "40px",
  },
  [WindowConstants.DESKTOP.key]: {
    padding: "90px",
  },
};

function usePaddingHelper() {
  const { windowSize } = useWindowSize();

  const { padding } = defaultPaddingProps[windowSize.key];
  const horizontalPadding = padding.split(" ")[0];
  const horizontalNegativePadding = `-${horizontalPadding}`;

  return {
    padding,
    horizontalPadding,
    horizontalNegativePadding,
  };
}

export default usePaddingHelper;
