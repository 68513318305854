import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const AuthorContainer: any = styled(Grid)`
  && {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    border-bottom: 0.5px solid #cfcfcf;
    margin: 18px 0;
    padding: 0 0 16px;
  }
`;

export const AuthorName: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 14px;
    line-height: 156%;
    color: var(--primria-escuros-preto-cinza-e-branco-500, #737373);
    cursor: pointer;
  }
`;

export const PopoverTitleTypography: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    line-height: 156%;
    color: black;
    margin-bottom: 8px;
  }
`;

export const PopoverDescriptionTypography: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 14px;
    line-height: 156%;
    color: var(--primria-escuros-preto-cinza-e-branco-500, #737373);
  }
`;

export const PopoverContentContainer: any = styled.div`
  && {
    max-width: 400px;
    padding: 16px;
  }
`;
