import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  ArticleFeedbackResponse,
  FeedbackResponseTypes,
} from "app/entities/content-feedback.entity";
import { DocumentNode } from "graphql";

export function useTypedFeedbackQuery<T extends keyof FeedbackResponseTypes>(
  mainAttribute: T,
  query: DocumentNode,
  options: QueryHookOptions
) {
  return useQuery<ArticleFeedbackResponse<T>>(query, options);
}
