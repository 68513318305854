import { InputConstants } from "app/constants/input.constants";
import YupSettings from "configuration/yup.configuration";
import { YupSchemaDefaultPasswordValidation } from "pages/public/authentication/sign-up/schema.yup";

export const ResetPasswordValidationSchema = YupSettings.object().shape({
  [InputConstants.TOKEN]: YupSettings.string().required(),
  [InputConstants.PASSWORD]: YupSchemaDefaultPasswordValidation,
  [InputConstants.PASSWORD_CONFIRMATION]:
    YupSchemaDefaultPasswordValidation.oneOf(
      [YupSettings.ref(InputConstants.PASSWORD)],
      "Senhas não conferem"
    ),
});
