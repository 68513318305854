export const WindowConstants = Object.freeze({
  DEFAULT: {
    key: "DEFAULT",
    value: "default",
  },
  SMALL_MOBILE: {
    key: "SMALL_MOBILE",
    value: "(max-width: 389px)",
  },
  MOBILE: {
    key: "MOBILE",
    value: "(max-width: 950px)",
  },
  TABLET: {
    key: "TABLET",
    value: "(max-width: 1379px)",
  },
  DESKTOP: {
    key: "DESKTOP",
    value: "(min-width: 1380px)",
  },
});
