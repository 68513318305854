import { Typography } from "@mui/material";
import styled from "styled-components";

export const TitleWelcome: any = styled(Typography)`
  && {
    color: #111827;
    font-family: "InterSemibold", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
`;

export const Subtitle: any = styled(Typography)`
  && {
    color: #374151;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.16px;
  }
`;

export const SubtitleWelcome: any = styled(Typography)`
  && {
    color: #374151;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.16px;
  }
`;

export const Notice: any = styled(Typography)`
  && {
    color: #374151;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "InterRegular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.12px;

    a {
      color: #8b11a5;
      text-decoration: underline;
    }
  }
`;

export const Information: any = styled(Typography)`
  color: #374151;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "InterRegular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 156%;
  letter-spacing: -0.12px;
`;
