import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const AuthenticationContainer = styled(Box)`
  && {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 950px) {
      width: 100%;
    }

    @media screen and (min-width: 1079px) {
      min-width: 500px;
    }
  }
`;

export const AuthenticationContainerStage = styled(Box)`
  && {
    @media screen and (max-width: 950px) {
      width: 100%;
    }

    @media screen and (min-width: 1079px) {
      min-width: 500px;
    }
  }
`;

export const FormularyControl: any = styled(FormControl)`
  && {
    border-radius: 8px;
    border: 1px solid var(--gray-30, #d1d5db);
    background: #fff;
    width: 100%;
    margin: 0 auto;
    padding: 15px 16px;

    input {
      font-family: "InterRegular", sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: 156%;
      letter-spacing: -0.16px;
      padding: 0;
      color: #111827;
    }
  }
`;

export const FormularyControlStage: any = styled(FormControl)`
  && {
    background: var(--white);
    border: 1px solid var(--foundation-gray-gray-300, #d1d5db);
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    padding: 11.5px 16px;

    input {
      font-family: "InterMedium", sans-serif;
      font-size: 16px;
      line-height: 156%;
      letter-spacing: -0.16px;
      padding: 0;
      color: var(--foundation-gray-gray-500, #6b7280);
    }
  }
`;

export const TextAreaField: any = styled(TextField)`
  && {
    background: var(--white);
    border: 1px solid var(--gray-30, #d1d5db);
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    padding: 12px 16px;

    input {
      font-family: "InterRegular", sans-serif;
      font-size: 14px;
      line-height: 19.6px;
      padding: 0;
    }
  }
`;

export const Container: any = styled(Box)`
  && {
    padding: 30px 30px 30px 30px;

    @media screen and (max-width: 950px) {
      padding: 80px 20px 20px 20px;

      .MuiTabs-root {
        padding: 20px 16px 25px;
      }
    }
  }
`;

export const Line = styled(Grid)`
  border-bottom: 1px solid #e4e7eb;
`;

export const HeadingTitle = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 20px;
    line-height: 29.4px;
    color: var(--black);
    transition: all 0.3s ease-out;
  }
`;

export const HeadingSubtitle = styled(Typography)`
  && {
    font-family: "InterLight", sans-serif;
    font-size: 16px;
    line-height: 16.8px;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: var(--black);
  }
`;

export const defaultContainerStyles = {
  "@media screen and (max-width: 949px)": {
    maxHeight: "none",
  },
};

export const ScrollbarContainerStyles = {
  ...defaultContainerStyles,
  maxHeight: `100vh`,
};

export const ScrollbarContainerWithNavbarStyles = {
  ...defaultContainerStyles,
  maxHeight: `calc(100vh - 78px)`,
};
