import { Typography } from "@mui/material";
import styled, { css } from "styled-components";

export const Separator: any = styled.span`
  color: #e7e7e7;
  margin: 0px 5px 0px 5px;
`;

export const CustomTypography: any = styled(Typography)`
  && {
    ${({ bold }: any) =>
      bold &&
      css`
        font-weight: bold;
      `}

    ${({ h1 }: any) =>
      h1 &&
      css`
        font-family: "InterMedium", sans-serif;
        font-size: 36px;
        line-height: 53.2px;
        letter-spacing: -2%;
        color: var(--black);
        margin-bottom: 28px;
      `}

        ${({ h3 }: any) =>
      h3 &&
      css`
        font-family: "InterRegular", sans-serif;
        font-size: 22px;
        line-height: 30.8px;
        letter-spacing: -2%;
        color: var(--black);
        margin-bottom: 9px;
      `}

        ${({ h5 }: any) =>
      h5 &&
      css`
        font-family: "InterLight", sans-serif;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: -2%;
        color: var(--black);
      `}

        @media screen and (max-width: 767px) {
      ${({ h1 }: any) =>
        h1 &&
        css`
          font-size: 21px;
          line-height: 29.4px;
          margin-bottom: 20px;
        `}

      ${({ h3 }: any) =>
        h3 &&
        css`
          font-size: 18px;
          line-height: 25.2px;
          margin-bottom: 4px;
        `}
    }
  }
`;
