import { MainNavigator } from "navigators";
import { GlobalStyle } from "resources/assets/styles/global";
import { Providers } from "resources/components/providers";

function App() {
  return (
    <>
      <GlobalStyle />

      <div className="notranslate">
        <Providers>
          <MainNavigator />
        </Providers>
      </div>
    </>
  );
}

export default App;
