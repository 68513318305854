import {
  BEARER_TOKEN_PREFIX,
  RestAPIConstants,
} from "app/constants/rest-api.constants";
import api from "app/providers/api.provider";
import { AxiosResponse, HttpStatusCode } from "axios";
import {
  dispatchErrorToastMessage,
  dispatchWarningToastMessage,
} from "configuration/toast.configuration";
import { ForgotPasswordFormData } from "pages/public/password/forgot/types";
import { ResetPasswordFormData } from "pages/public/password/reset/types";

function usePasswordService() {
  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async function forgot(
    data: ForgotPasswordFormData,
    token: string
  ): Promise<AxiosResponse<any>> {
    const config: any = {
      headers: {
        Authorization: `${BEARER_TOKEN_PREFIX}${token}`,
      },
    };

    return api
      .post(RestAPIConstants.FORGOT_PASSWORD, data, config)
      .catch((error: any) => {
        if (error?.response?.status === HttpStatusCode.BadRequest) {
          dispatchWarningToastMessage(
            "Não foi possível enviar e-mail de recuperação!"
          );
        } else {
          dispatchErrorToastMessage(
            "Não foi possível enviar e-mail de recuperação!"
          );
        }

        throw error;
      });
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async function reset(
    data: ResetPasswordFormData,
    token: string
  ): Promise<AxiosResponse<any>> {
    const config: any = {
      headers: {
        Authorization: `${BEARER_TOKEN_PREFIX}${token}`,
      },
    };

    return api
      .post(RestAPIConstants.RESET_PASSWORD, data, config)
      .catch((error: any) => {
        if (error?.response?.status === HttpStatusCode.BadRequest) {
          dispatchWarningToastMessage("Não possível atualizar sua senha!");
        } else {
          dispatchErrorToastMessage("Não possível atualizar sua senha!");
        }

        throw error;
      });
  }

  return {
    forgot,
    reset,
  };
}

export default usePasswordService;
