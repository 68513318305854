import { Grid, Stack } from "@mui/material";
import { InputConstants } from "app/constants/input.constants";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { useContentComment } from "app/contexts/contents/comment/hooks/content-comment.hook";
import { useFormik } from "formik";
import { Button } from "resources/components/buttons/main";
import { TextAreaInput } from "resources/components/form/inputs/text-area";
import { Person } from "../../../person";
import { CommentFormYupFormSchema } from "./schema.yup";
import { Container } from "./styles";
import { CommentFormFormData, CommentFormProps } from "./types";

export function CommentForm({}: CommentFormProps): JSX.Element {
  const { state: authenticationState } = useAuthentication();
  const { handleCommentResponse } = useContentComment();

  const formik = useFormik({
    initialValues: {
      [InputConstants.COMMENT]: "",
    },
    validationSchema: CommentFormYupFormSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(data: CommentFormFormData): Promise<void> {
    try {
      handleCommentResponse({
        avatar: authenticationState.user?.avatar,
        name: authenticationState.user?.name,
        comment: data.comment,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container mt="16px">
      <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
        {authenticationState.user?.name && (
          <Grid mb={"16px"}>
            <Person
              avatar={authenticationState.user?.avatar}
              name={authenticationState.user?.name}
            />
          </Grid>
        )}

        <Stack>
          <TextAreaInput
            options={{
              id: InputConstants.COMMENT,
              name: InputConstants.COMMENT,
              value: formik.values[InputConstants.COMMENT],
              onChange: formik.handleChange(InputConstants.COMMENT),
              disabled: formik.isSubmitting,
              placeholder: "Escreva um comentário...",
              inputProps: {
                maxLength: 255,
              },
              style: {
                border: "none",
                boxShadow: "none",
                padding: 0,
              },
            }}
            error={formik.errors[InputConstants.COMMENT]}
          />
        </Stack>

        <Grid container justifyContent={"flex-end"}>
          <Button
            title={"Publicar comentário"}
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            sx={{
              mt: "24px",
              maxHeight: "38px",
            }}
          />
        </Grid>
      </form>
    </Container>
  );
}
