import { ContentCommentMainAttributes } from "app/contexts/contents/comment/enums/main-attribute.enum";
import gql from "graphql-tag";
import { ArticleQueryAttributes } from "resources/packages/contents/enums/article-query-attribute.enum";
import {
  singularize,
  titleCase,
} from "resources/packages/contents/helpers/article.helper";

export function generateCommentQuery(
  mainAttribute: ContentCommentMainAttributes,
  queryAttribute: ArticleQueryAttributes
) {
  return gql`
    query GetFeedbackByUser($userId: IntFilterInput!, $contentId: ID!) {
      ${mainAttribute}(
        filters: { odgo_user_id: $userId, ${queryAttribute}: { id: { eq: $contentId } } }
        pagination: { limit: 1 }
      ) {
        data {
          id
          attributes {
            comment
            odgo_user_id
            avatar
            name
            ${queryAttribute} {
              data {
                id
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  `;
}

export function generateCreateCommentMutation(
  mainAttribute: ContentCommentMainAttributes
) {
  const mutationName = titleCase(singularize(mainAttribute));

  return gql`
    mutation CreateComment($data: ${mutationName}Input!) {
      create${mutationName}(data: $data) {
        data {
          id
          attributes {
            comment
            odgo_user_id
            avatar
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  `;
}

export function generatePaginatedCommentsQuery(
  mainAttribute: ContentCommentMainAttributes,
  queryAttribute: ArticleQueryAttributes
) {
  return gql`
    query GetComments($contentId: ID!, $limit: Int, $start: Int) {
      ${mainAttribute}(
        filters: { ${queryAttribute}: { id: { eq: $contentId } } }
        sort: ["createdAt:desc"]
        pagination: { limit: $limit, start: $start }
      ) {
        data {
          id
          attributes {
            comment
            odgo_user_id
            avatar
            name
            createdAt
            updatedAt
          }
        }
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;
}
