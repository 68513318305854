import { Grid } from "@mui/material";
import usePhoneHelper from "app/helpers/phone.helper";
import useWindowSize from "app/hooks/use-window-size.hook";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import Select from "react-select";
import { ReactComponent as CheckIcon } from "resources/assets/icons/check.svg";
import { InputError } from "../../input-error";
import { InputLabel } from "../../input-label";
import { PhoneInputConstants } from "./constants";
import { SelectIconOption } from "./icon-option";
import { SelectSingleValue } from "./single-value";
import { FormularyControl, selectStyles } from "./styles";
import { PhoneCountryProps, PhoneInputProps } from "./types";

export function PhoneInput({
  inputProps,
  selectProps,
  label,
  error,
  isLoading,
  callingCodeValue,
  endIcon,
}: PhoneInputProps): JSX.Element {
  const formularyControlRef = useRef<any>(null);
  const [formularyWidth, setFormularyWidth] = useState<number>(0);
  const [selectedPhoneCountry, setSelectedCountry] = useState<any>(
    PhoneInputConstants.DEFAULT_COUNTRY
  );

  const formattedCountry = selectedPhoneCountry
    ? {
        value: selectedPhoneCountry.dialCode,
        label: selectedPhoneCountry.pt,
        flag: selectedPhoneCountry.flag,
        icon: selectedPhoneCountry.icon,
        mask: selectedPhoneCountry.mask,
      }
    : null;

  const { compareByPt } = usePhoneHelper();
  const { isMobile } = useWindowSize();

  /**
   * @returns {void}
   */
  useEffect((): void => {
    const phoneCountry: PhoneCountryProps | undefined =
      PhoneInputConstants.COUNTRIES.find(
        (country: PhoneCountryProps) =>
          selectProps && country.dialCode === callingCodeValue
      );

    if (phoneCountry) {
      setSelectedCountry(phoneCountry);
    }
  }, [callingCodeValue]);

  /**
   * @returns {void}
   */
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      Array.from(entries).forEach((entry) => {
        setFormularyWidth(entry.contentRect.width);
      });
    });

    if (formularyControlRef.current) {
      resizeObserver.observe(formularyControlRef.current);
    }

    return () => {
      if (formularyControlRef.current) {
        resizeObserver.unobserve(formularyControlRef.current);
      }
    };
  }, []);

  return (
    <>
      <Grid container overflow="unset" style={{ flexDirection: "column" }}>
        {label && (
          <InputLabel
            title={label}
            inputName={inputProps.name}
            isRequired={inputProps.required}
          />
        )}

        <FormularyControl
          ref={formularyControlRef}
          error={!!error}
          variant="standard"
        >
          <Grid container alignItems={"center"} flexWrap={"nowrap"}>
            <Select
              {...selectProps}
              options={PhoneInputConstants.COUNTRIES.filter(
                (country: PhoneCountryProps) => country.pt
              )
                .sort(compareByPt)
                .map((country: PhoneCountryProps) => ({
                  value: country.dialCode,
                  label: country.pt,
                  flag: country.flag,
                  icon: country.icon,
                  mask: country.mask,
                }))}
              value={formattedCountry}
              menuPlacement="bottom"
              components={{
                Option: SelectIconOption,
                SingleValue: SelectSingleValue,
              }}
              styles={{
                ...selectStyles,
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
                menuList: (provided) => ({
                  ...provided,
                  marginTop: "8px",
                  backgroundColor: "#fff",
                  border: "1px solid  #D1D5DB",
                  borderRadius: "8px",
                  zIndex: 3,
                  minWidth: `${formularyWidth}px`,
                }),
              }}
              placeholder={isLoading ? "Carregando opções" : "Selecionar"}
              noOptionsMessage={() =>
                isLoading ? "Carregando opções" : "Sem opções"
              }
              unstyled
              isSearchable={false}
            />

            <InputMask
              {...inputProps}
              mask={selectedPhoneCountry?.mask || ""}
              placeholder={selectedPhoneCountry?.mask || ""}
              style={{
                border: "none",
                borderRadius: 8,
                height: "48px",
                color: "#6B7280",
                fontFamily: "'InterRegular', sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "156%",
                letterSpacing: "-0.16px",
                width: isMobile ? "100%" : "",
                maxWidth: isMobile ? "180px" : "",
              }}
            />

            {endIcon && (
              <CheckIcon style={{ marginLeft: "auto", marginRight: "10px" }} />
            )}
          </Grid>
        </FormularyControl>

        {!!error && <InputError title={error} />}
      </Grid>
    </>
  );
}
