import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const NumberIndicator: any = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #adadad;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    p {
      font-family: "InterBold", sans-serif;
      font-size: 14px;
      line-height: 100%;
      color: var(--white);
      margin: 0;
    }
  }
`;

export const ItemTitle: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 18px;
    line-height: 156%;
    letter-spacing: -0.32px;
    color: var(--primria-escuros-preto-cinza-e-branco-800, var(--Black, #000));
  }
`;
