import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { useState } from "react";
import { AvatarImage } from "../person";
import { Menu, Title } from "./styles";
import { DropdownMenuProps, OptionItemProps } from "./types";

export const DropdownMenu = ({ options }: DropdownMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: "pointer", marginLeft: "8px" }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{
          ".MuiMenu-paper": {
            borderRadius: "12px",
            border: "1px solid #F9FAFB",
            boxShadow: "none",
          },
        }}
      >
        {options.map(
          (option: OptionItemProps): JSX.Element => (
            <MenuItem
              {...option}
              onClick={(event) => {
                if (option.onClick) {
                  option.onClick(event);
                }

                handleClose();
              }}
            >
              <Grid container alignItems="center" gap={"8px"}>
                {option.icon}

                <Title style={option.titleStyle}>{option.title}</Title>
              </Grid>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export const AvatarDropdownMenu = ({
  options,
}: DropdownMenuProps): JSX.Element => {
  const { state: authenticationState } = useAuthentication();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: "pointer", marginLeft: "8px" }}
      >
        <AvatarImage
          avatar={authenticationState.user?.avatar}
          name={authenticationState.user?.name}
        />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{
          ".MuiMenu-paper": {
            borderRadius: "12px",
            border: "1px solid #F9FAFB",
            boxShadow: "none",
          },
        }}
      >
        {options.map(
          (option: OptionItemProps): JSX.Element => (
            <MenuItem
              {...option}
              onClick={(event) => {
                if (option.onClick) {
                  option.onClick(event);
                }

                handleClose();
              }}
            >
              <Grid container alignItems="center" gap={"8px"}>
                {option.icon}

                <Title style={option.titleStyle}>{option.title}</Title>
              </Grid>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};
