import { Grid } from "@mui/material";
import { useContentComment } from "app/contexts/contents/comment/hooks/content-comment.hook";
import { ArticleCommentAttributes } from "app/entities/content-comment.entity";
import useDateHelper from "app/helpers/date.helper";
import { CommentForm } from "./form";
import { Person } from "../../person";
import { CommentLabel, StyledButton, Title } from "./styles";

export function ContentComments(): JSX.Element {
  const { state: contentCommentState, handleLoadMore } = useContentComment();
  const { formatPtBrDate } = useDateHelper();

  return (
    <Grid mt={"32px"}>
      <Title mb={"20px"}>{"Comentários"}</Title>

      <CommentForm />

      <Grid container mt={"20px"} mb={"20px"}>
        {contentCommentState.comments?.map(
          (comment: ArticleCommentAttributes) => (
            <>
              <Person
                name={comment.attributes.name}
                createdAt={formatPtBrDate(comment.attributes.createdAt)}
                avatar={comment.attributes.avatar}
              />

              <Grid mt={"10px"} mb={"16px"}>
                <CommentLabel>{comment.attributes.comment}</CommentLabel>
              </Grid>
            </>
          )
        )}
      </Grid>

      {contentCommentState.comments && (
        <Grid container justifyContent="center" mt={"16px"}>
          <Grid xl={4} md={4} sm={12} xs={12}>
            <StyledButton
              type={"button"}
              variant="contained"
              onClick={handleLoadMore}
            >
              {"Carregar mais"}
            </StyledButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
