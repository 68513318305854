import { Box } from "@mui/material";
import styled from "styled-components";

export const CustomBox: any = styled(Box)`
  && {
    display: flex;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
  max-width: 100vw;
  padding: 0 0 0 100px;
  position: relative;

  .ellipseTop {
    position: absolute;
    top: 45px;
    left: 0;
  }

  .ellipseBottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media screen and (max-width: 950px) {
    padding: 65px 0 0 0;
  }
`;
