import { toast } from "react-toastify";

export const ToastContainerOptions = Object.freeze({
  hideProgressBar: true,
  closeButton: false,
  toastStyle: {
    minHeight: "48px",
    borderRadius: "8px",
    background: "#F0FDF4",
  },
  bodyStyle: {
    color: "#262626",
    fontFamily: "'InterMedium', sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%",
    letterSpacing: "-0.28px",
  },
});

export function dispatchSuccessToastMessage(message?: string) {
  toast.success(message ?? "Requisição realizada com sucesso!");
}

export function dispatchWarningToastMessage(message?: string) {
  toast.warning(message ?? "Houve um erro ao processar sua requisição!");
}

export function dispatchErrorToastMessage(message?: string) {
  toast.error(message ?? "Houve um erro ao processar sua requisição!");
}
