import { AppRoutes } from "app/enums/app-route.enum";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { HelmetHandler } from "resources/components/handlers/helmet";
import { WelcomePartial } from "resources/components/welcome";
import AuthenticationLayout from "resources/layouts/authentication";

const PAGE_TITLE = "Vamos começar criando sua conta Ailu";

export default function WelcomeSignUpPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  function onSignInClick(): void {
    navigate(AppRoutes.SignUpForm);
  }

  function onSignUpClick(): void {
    navigate(AppRoutes.SignIn);
  }

  return (
    <>
      <HelmetHandler
        options={{
          title: "Criar Conta Ailu",
        }}
      />

      <AuthenticationLayout>
        <WelcomePartial
          title={PAGE_TITLE}
          subtitle={"Escolha a forma mais prática pra você:"}
          onSignInClick={onSignInClick}
          isSignUp
          progressBarValue={50}
          onBackButtonClick={onSignUpClick}
        />
      </AuthenticationLayout>
    </>
  );
}
