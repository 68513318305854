import { Stack } from "@mui/material";
import { InputConstants } from "app/constants/input.constants";
import { AppRoutes } from "app/enums/app-route.enum";
import useAuthenticationService from "app/services/authentication.service";
import usePasswordService from "app/services/password.service";
import { AxiosResponse, HttpStatusCode } from "axios";
import { dispatchSuccessToastMessage } from "configuration/toast.configuration";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthenticationFooter } from "resources/components/authentication/footer";
import { Button } from "resources/components/buttons/main";
import { TextInput } from "resources/components/form/inputs/text";
import { AnimationHandler } from "resources/components/handlers/animation";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthenticationLayout, {
  AuthenticationElement,
} from "resources/layouts/authentication";
import { ResetPasswordInitialValues } from "./constants";
import { ResetPasswordValidationSchema } from "./schema.yup";
import { ResetPasswordFormData } from "./types";

const PAGE_TITLE = "Alterar senha";

export default function ResetPasswordPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const [clientToken, setClientToken] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { clientToken: getClientOauthToken } = useAuthenticationService();
  const { reset: resetPassword } = usePasswordService();

  const location: Location = useLocation();

  const token: string = new URLSearchParams(location.search).get("token") || "";
  const initialValues = {
    ...ResetPasswordInitialValues,
    [InputConstants.TOKEN]: token,
  };

  const formik = useFormik<ResetPasswordFormData>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: ResetPasswordValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  /**
   * @returns {Promise<void>}
   */
  async function handleSubmit(data: ResetPasswordFormData): Promise<void> {
    try {
      if (clientToken) {
        const requestResponse: AxiosResponse<any> = await resetPassword(
          data,
          clientToken
        );

        if (requestResponse?.status === HttpStatusCode.NoContent) {
          dispatchSuccessToastMessage("Sua senha foi alterada com sucesso!");

          navigate(AppRoutes.SignInForm);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onSignInClick(): void {
    navigate(AppRoutes.SignInForm);
  }

  /**
   * @returns {void}
   */
  useEffect((): void => {
    const bootstrapAsync = async (): Promise<void> => {
      try {
        setIsLoading(true);

        const requestResponse: AxiosResponse<any> = await getClientOauthToken();

        if (requestResponse?.status === HttpStatusCode.Ok) {
          const accessToken: string | null = requestResponse.data?.access_token;

          if (accessToken) {
            setClientToken(accessToken);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout>
        <AuthenticationElement
          title={PAGE_TITLE}
          subtitle={
            "A senha deve ser diferente da usada anteriormente e ter no mínimo 8 dígitos."
          }
          onBackButtonClick={onSignInClick}
        >
          {isLoading ? (
            <AnimationHandler />
          ) : (
            <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
              <Stack spacing={"24px"}>
                <TextInput
                  label={"Senha"}
                  id={InputConstants.PASSWORD}
                  name={InputConstants.PASSWORD}
                  value={formik.values[InputConstants.PASSWORD]}
                  onChange={formik.handleChange(InputConstants.PASSWORD)}
                  disabled={!token || formik.isSubmitting}
                  type={"password"}
                  placeholder={"Digite sua  senha"}
                  error={formik.errors[InputConstants.PASSWORD]}
                />

                <TextInput
                  label={"Repetir Senha"}
                  id={InputConstants.PASSWORD_CONFIRMATION}
                  name={InputConstants.PASSWORD_CONFIRMATION}
                  value={formik.values[InputConstants.PASSWORD_CONFIRMATION]}
                  onChange={formik.handleChange(
                    InputConstants.PASSWORD_CONFIRMATION
                  )}
                  disabled={!token || formik.isSubmitting}
                  type={"password"}
                  placeholder={"Digite sua  senha"}
                  error={formik.errors[InputConstants.PASSWORD_CONFIRMATION]}
                />

                <Button
                  type="submit"
                  fullWidth
                  title={"Alterar senha"}
                  isLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                />

                <AuthenticationFooter
                  gridOptions={{
                    justifyContent: "center",
                  }}
                  items={[
                    {
                      title: "Lembrou sua senha?",
                      subtitle: "Entre aqui",
                      onClick: onSignInClick,
                    },
                  ]}
                />
              </Stack>
            </form>
          )}
        </AuthenticationElement>
      </AuthenticationLayout>
    </>
  );
}
