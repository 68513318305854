import { Grid, Stack } from "@mui/material";
import { User } from "@sentry/react";
import { InputConstants } from "app/constants/input.constants";
import { RestAPIConstants } from "app/constants/rest-api.constants";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import usePhoneHelper, { formatCallingCode } from "app/helpers/phone.helper";
import useStringHelper from "app/helpers/string.helper";
import useWindowSize from "app/hooks/use-window-size.hook";
import useAuthenticatedUser from "app/hooks/user.hook";
import externalApi from "app/providers/external-api.provider";
import { HttpStatusCode } from "axios";
import { dispatchErrorToastMessage } from "configuration/toast.configuration";
import { FormikHelpers, FormikProps, useFormik } from "formik";
import { ReactComponent as InformationIcon } from "resources/assets/icons/information-gray.svg";
import { Button } from "resources/components/buttons/main";
import { PhoneInput } from "resources/components/form/inputs/phone";
import { PhoneInputConstants } from "resources/components/form/inputs/phone/constants";
import { usePhoneComplete } from "resources/components/form/inputs/phone/helper";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthenticationLayout, {
  AuthenticationElement,
} from "resources/layouts/authentication";
import { handleCompleteSignUpPeopleDispatchToMixPanel } from "./helpers/tracking.helper";
import { CompleteSignUpYupFormSchema } from "./schema.yup";
import { SubtitleInfo } from "./styles";
import { CompleteSignUpFormData } from "./types";

const PAGE_TITLE = "Completar Cadastro";

export default function CompleteSignUpPage(): JSX.Element {
  const { state: authenticationState } = useAuthentication();

  const { getFirstWord } = useStringHelper();

  const { isMobile } = useWindowSize();
  const { updateUser } = useAuthenticatedUser();
  const { extractSimbolsAndSpaces } = usePhoneHelper();

  const initialValues: CompleteSignUpFormData = {
    [InputConstants.PHONE]: authenticationState.user?.phone ?? "",
    [InputConstants.CALLING_CODE]: formatCallingCode(
      authenticationState.user?.calling_code ??
        PhoneInputConstants.BRAZIL_CALLING_CODE
    ),
  };

  const formik: FormikProps<CompleteSignUpFormData> =
    useFormik<CompleteSignUpFormData>({
      initialValues,
      validationSchema: CompleteSignUpYupFormSchema,
      onSubmit: handleSubmit,
      validateOnChange: false,
      validateOnBlur: false,
    });

  const { isComplete } = usePhoneComplete(
    formik?.values[InputConstants.PHONE] ?? "",
    formik?.values[InputConstants.CALLING_CODE]
  );

  /**
   * @returns {Promise<void>}
   */
  async function handleSubmit(
    data: CompleteSignUpFormData,
    { setFieldError }: FormikHelpers<CompleteSignUpFormData>
  ): Promise<void> {
    try {
      const payload = {
        phone: data.phone ? extractSimbolsAndSpaces(data.phone) : "",
        calling_code: data.calling_code
          ? extractSimbolsAndSpaces(data.calling_code)
          : "",
      };

      const validationResponse = await externalApi
        .get(
          `${RestAPIConstants.WHATSAPP_VALIDATION}/${extractSimbolsAndSpaces(
            data.calling_code
          )}${extractSimbolsAndSpaces(data.phone)}`
        )
        .catch((error) => {
          console.error(error);
        });

      if (validationResponse?.status === HttpStatusCode.Ok) {
        if (validationResponse?.data?.result === false) {
          setFieldError(
            InputConstants.PHONE,
            "Este número não está no WhatsApp"
          );

          dispatchErrorToastMessage(
            "Este número não está no WhatsApp. A nossa experiência é baseada no WhatsApp, por isso precisamos que você indique um número de WhatsApp válido."
          );

          return;
        }
      }

      const updatedUser: User | undefined = await updateUser(payload);

      if (updatedUser) {
        handleCompleteSignUpPeopleDispatchToMixPanel(updateUser);
      }
    } catch (error) {
      dispatchErrorToastMessage("Houve um erro ao tentar enviar seus dados!");

      console.error(error);
    }
  }

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout>
        <AuthenticationElement
          title={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                {`Me diz, ${getFirstWord(
                  authenticationState?.user?.name ?? ""
                )}:`}
              </span>

              <span>{"Qual seu WhatsApp?"}</span>
            </span>
          }
          subtitle={"Não enviamos spam, só coisa boa. 😉"}
          progressBarValue={100}
        >
          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <Stack spacing={"24px"}>
              <PhoneInput
                label={"WhatsApp"}
                callingCodeValue={formik.values[InputConstants.CALLING_CODE]}
                selectProps={{
                  name: InputConstants.CALLING_CODE,
                  onChange: (selectedItem: any) => {
                    const { value } = selectedItem;

                    formik.setFieldValue(InputConstants.CALLING_CODE, value);
                    formik.setFieldValue(InputConstants.PHONE, "");
                  },
                  isMulti: false,
                }}
                disabled={formik.isSubmitting}
                inputProps={{
                  name: InputConstants.PHONE,
                  value: formik.values[InputConstants.PHONE],
                  onChange: (event) => {
                    formik.setFieldValue(
                      InputConstants.PHONE,
                      event.target.value
                    );
                  },
                  disabled: formik.isSubmitting,
                  type: "text",
                }}
                endIcon={isComplete}
                error={
                  formik.errors[InputConstants.CALLING_CODE] ??
                  formik.errors[InputConstants.PHONE]
                }
              />

              <Grid
                container
                alignItems={"center"}
                flexWrap={"nowrap"}
                gap={"12px"}
                sx={{
                  margin: "12px 0 24px",
                }}
              >
                <Grid sx={{ width: "20px", height: "20px" }}>
                  <InformationIcon style={{ width: "20px", height: "20px" }} />
                </Grid>

                <SubtitleInfo>
                  {
                    "Nossa comunidade é digital e você interage com outros membros pelo WhatsApp. Deixe um número válido."
                  }
                </SubtitleInfo>
              </Grid>

              <Button
                type="submit"
                fullWidth
                title={"Criar minha conta"}
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            </Stack>
          </form>
        </AuthenticationElement>
      </AuthenticationLayout>
    </>
  );
}
