import { AppRoutes } from "app/enums/app-route.enum";
import { useMixPanelTrackPageView } from "app/hooks/mixpanel-page-view.hook";
import { LayoutWrapper } from "navigators";
import { AppRouteConstants } from "navigators/constants/app-route.constants";
import { RouteTypes } from "navigators/enums/route-type.enum";
import { AppRoute } from "navigators/types";
import { createElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export function PublicNavigator(): JSX.Element {
  // Track MixPanel Page View
  useMixPanelTrackPageView();

  return (
    <>
      <Routes>
        <>
          {AppRouteConstants.filter(
            (route: AppRoute) =>
              route.type === RouteTypes.Public ||
              route.type === RouteTypes.Common
          ).map((route: AppRoute) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <LayoutWrapper
                  layout={route?.layout}
                  element={createElement(route.element)}
                />
              }
            />
          ))}

          <Route
            path={AppRoutes.NotFound}
            element={<Navigate to={AppRoutes.SignIn} />}
          />
        </>
      </Routes>
    </>
  );
}
