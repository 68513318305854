import { Menu as MuiMenu, Typography } from "@mui/material";
import styled from "styled-components";

export const Menu: any = styled(MuiMenu)`
  && {
    .MuiMenu-paper {
      border-radius: 12px;
      border: 1px solid #f9fafb;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Title: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #6b7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
  }
`;
