import { useContextSelector } from "use-context-selector";
import { LinkingContext } from ".";
import { LinkingContextOptions } from "./types";

export function useLinking() {
  const state = useContextSelector(
    LinkingContext,
    (linking: LinkingContextOptions) => linking.state
  );

  const removeParams = useContextSelector(
    LinkingContext,
    (linking: LinkingContextOptions) => linking.removeParams
  );

  return {
    state,
    removeParams,
  };
}
