import { useContextSelector } from "use-context-selector";
import { ContentCommentContext } from "..";
import { ContentCommentContextOptions } from "../types";

export function useContentComment() {
  const state = useContextSelector(
    ContentCommentContext,
    (contentComment: ContentCommentContextOptions) => contentComment.state
  );

  const handleCommentResponse = useContextSelector(
    ContentCommentContext,
    (contentComment: ContentCommentContextOptions) =>
      contentComment.handleCommentResponse
  );

  const handleLoadMore = useContextSelector(
    ContentCommentContext,
    (contentComment: ContentCommentContextOptions) =>
      contentComment.handleLoadMore
  );

  return {
    state,
    handleCommentResponse,
    handleLoadMore,
  };
}
