import { Grid, Typography } from "@mui/material";
import { components } from "react-select";

const { Option }: any = components;

export function SelectIconOption(props: any): JSX.Element {
  const { data } = props;

  const Icon = data.icon;

  return (
    <Option {...props}>
      <Grid container alignItems={"center"} gap={"6px"}>
        {Icon ? (
          <Icon
            style={{
              width: "24px",
              borderRadius: "4px",
            }}
          />
        ) : (
          data.flag
        )}

        <Typography
          style={{
            color: "#000",
            fontFamily: "'InterRegular', sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "156%",
            letterSpacing: "-0.16px",
          }}
        >
          <strong>{data.value}</strong>
          {` - ${data.label}`}
        </Typography>
      </Grid>
    </Option>
  );
}
