import { FormControl } from "@mui/material";
import styled from "styled-components";

export const StyledSelectInput: any = styled.select`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-color: transparent;
  margin: 0;
  max-width: 115px;
  -moz-appearance: textfield;
  -webkit-appearance: none;

  font-family: "InterMedium", sans-serif;
  font-size: 16px;
  line-height: 156%;
  letter-spacing: -0.16px;
`;

export const Separator: any = styled.span`
  color: #e7e7e7;
  margin: 0px 5px 0px 5px;
`;

export const FormularyControl = styled(FormControl)`
  && {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background: #fff;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
`;

export const selectStyles: any = {
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? "#470e89" : "#fff",
    color: isSelected ? "#fff" : "#333333",
    fontSize: "14px",
    borderBottom: "1px solid rgba(191, 191, 191, 0.40)",
    padding: "10px 22px",

    height: "54px",
    display: "flex",
    alignItems: "center",

    ":hover": {
      ...provided[":hover"],
      backgroundColor: "#D1D5DB",
      color: "#000",
    },
  }),

  input: (provided, { isFocused }) => ({
    ...provided,

    fontFamily: '"InterRegular", sans-serif',
    fontSize: 14,

    ":hover": {
      ...provided[":hover"],
    },
    ":focus": {
      ...provided[":focus"],
    },
    ":focus:hover": {
      ...provided[":focus:hover"],
    },
  }),

  container: (provided) => ({
    ...provided,
  }),

  valueContainer: (provided) => ({
    ...provided,
  }),

  placeholder: (provided) => ({
    ...provided,
    color: "#333333",
    fontSize: "14px",
  }),

  control: (provided, { isFocused, menuIsOpen, isPreviewDisabled }) => ({
    ...provided,

    height: "54px",
    borderRadius: isPreviewDisabled ? "8px" : "8px 0px 0px 8px",
    background: `var(--white)`,

    width: `100%`,
    padding: `0px 16px`,
    minHeight: 44,
    margin: `0 auto`,
  }),

  singleValue: (provided) => ({
    ...provided,

    color: "#333333",
    fontSize: "14px",
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    color: "gray",
    backgroundColor: "transparent",
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),

  menu: (provided) => ({
    ...provided,

    zIndex: 2000,
    paddingBottom: "20px",
  }),
};
