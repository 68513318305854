import { NavigationLink } from "./types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImmigrateImage1 from "resources/assets/images/immigrate-1.png";
import ImmigrateImage2 from "resources/assets/images/immigrate-2.png";
import ImmigrateImage3 from "resources/assets/images/immigrate-3.png";
import ImmigrateImage4 from "resources/assets/images/immigrate-4.png";

export const NAVIGATION_LINKS = (isPopoverOpen: boolean): NavigationLink[] => [
  {
    title: "Sobre",
    href: "https://ailu.com.br/sobre",
    className: "ml-4 text-[#4B5563]",
  },
  {
    title: "Planos",
    href: "https://ailu.com.br/planos",
    className: "text-[#4B5563]",
  },
  {
    title: "Imigrar para",
    className: "text-[#4B5563]",
    icon: (
      <KeyboardArrowDownIcon
        style={{
          color: "#D6D6D6",
          transition: "transform 0.2s ease-in-out",
          transform: isPopoverOpen ? "rotate(180deg)" : "rotate(0deg)",
        }}
      />
    ),
    childrenLinks: [
      {
        image: ImmigrateImage1,
        title: "Trabalhar em Portugal",
        href: "https://ailu.com.br/imigrar-para-trabalhar",
        alt: "Imagem imigração 1",
      },
      {
        image: ImmigrateImage2,
        title: "Estudar em Portugal",
        href: "https://ailu.com.br/imigrar-para-estudar",
        alt: "Imagem imigração 2",
      },
      {
        image: ImmigrateImage3,
        title: "Empreender em Portugal",
        href: "https://ailu.com.br/imigrar-para-empreender",
        alt: "Imagem imigração 3",
      },
      {
        image: ImmigrateImage4,
        title: "Aposentadoria em Portugal",
        href: "https://ailu.com.br/imigrar-para-aposentadoria",
        alt: "Imagem imigração 4",
      },
    ],
  },
  {
    title: "Contato",
    href: "https://ailu.com.br/contato",
    className: "text-[#4B5563]",
  },
];
