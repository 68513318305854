import { ExitToApp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { AppRoutes } from "app/enums/app-route.enum";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DrawerIcon } from "resources/assets/icons/drawer.svg";
import { AvatarDropdownMenu } from "../dropdown-menu";
import DrawerLink from "./components/drawer-link";
import HeaderLogo from "./components/header-logo";
import NavigationLink from "./components/navigation-link";
import { NAVIGATION_LINKS } from "./constants";

export default function NavigationHeader() {
  const navigate = useNavigate();
  const { state: authenticationState, signOut } = useAuthentication();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handlePopoverOpen = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    handlePopoverClose();
  };

  return (
    <div className="flex row justify-between items-center min-[970px]:px-[128px] max-[970px]:px-8 h-[64px] w-full border-solid border-b border-[#E4E7EB]">
      <div className="flex row gap-8">
        <HeaderLogo />

        {!authenticationState.isAuthenticated && (
          <div className="max-[970px]:hidden flex row gap-8">
            {NAVIGATION_LINKS(isPopoverOpen).map((link, index) => (
              <NavigationLink
                key={index}
                link={link}
                isPopoverOpen={isPopoverOpen}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
              />
            ))}
          </div>
        )}
      </div>

      {authenticationState.isAuthenticated ? (
        <>
          <AvatarDropdownMenu
            options={[
              {
                title: "Sair",
                icon: <ExitToApp />,
                onClick: () => {
                  signOut();
                },
              },
            ]}
          />
        </>
      ) : (
        <>
          <div className="max-[970px]:hidden flex row gap-2">
            <button
              type="button"
              className="py-2 px-3 text-[#4B5563] rounded-lg"
              onClick={() => navigate(AppRoutes.SignIn)}
            >
              Entrar
            </button>

            <button
              type="button"
              className="py-2 px-3 bg-[#470E89] text-[#FFFFFF] rounded-lg"
              onClick={() => navigate(AppRoutes.SignUp)}
            >
              Começar agora
            </button>
          </div>

          <div className="min-[970px]:hidden">
            <IconButton onClick={() => setIsDrawerOpen((open) => !open)}>
              <DrawerIcon />
            </IconButton>
          </div>

          <SwipeableDrawer
            hideBackdrop
            anchor="top"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}
            onOpen={() => setIsDrawerOpen(true)}
          >
            <div className="flex flex-col h-screen">
              <div className="flex row justify-between items-center px-8 h-[64px] w-full border-solid border-b border-[#E4E7EB]">
                <HeaderLogo />

                <IconButton onClick={() => setIsDrawerOpen(false)}>
                  <CloseIcon
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              </div>

              {NAVIGATION_LINKS(isPopoverOpen).map((link, index) => (
                <DrawerLink
                  key={index}
                  link={link}
                  handleCloseDrawer={handleCloseDrawer}
                  isPopoverOpen={isPopoverOpen}
                  handlePopoverOpen={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}
                />
              ))}
            </div>
          </SwipeableDrawer>
        </>
      )}
    </div>
  );
}
