import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const TitleTypography: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 18px;
    color: black;
  }
`;

export const Step: any = styled(Grid)`
  && {
    display: flex;
    gap: 24px;
  }
`;

export const StepColumnLeft: any = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StepColumnRight: any = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &.lastStep {
      margin-bottom: 0;
    }
  }
`;

export const StepNumber: any = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #f64f59;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    p {
      font-family: "InterBold", sans-serif;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: -0.24px;
      color: var(--Cor-final, #ff4752);
      margin: 0;
    }
  }
`;

export const StepLine: any = styled(Grid)`
  && {
    background: var(--gray-100);
    width: 2px;
    height: 100%;
  }
`;

export const StepTitle: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 18px;
    line-height: 156%;
    letter-spacing: -0.32px;
    color: var(--Black, #000);

    margin-bottom: 12px;
  }
`;

export const StepBody: any = styled(Grid)`
  && {
    margin-top: -24px;
    margin-bottom: 24px;
  }
`;
