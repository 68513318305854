import { CircularProgress } from "@mui/material";
import { StyledButton } from "./styles";
import { MainButtonOptions } from "./types";

export function Button({
  title,
  isLoading,
  ...props
}: MainButtonOptions): JSX.Element {
  return (
    <>
      <StyledButton {...props}>
        {isLoading ? (
          <CircularProgress size={24} sx={{ color: "#fff" }} />
        ) : (
          <span>{title}</span>
        )}
      </StyledButton>
    </>
  );
}
