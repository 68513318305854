import { ArticleMainAttributes } from "app/contexts/contents/article/enums/main-attribute.enum";
import gql from "graphql-tag";
import {
  singularize,
  titleCase,
} from "resources/packages/contents/helpers/article.helper";

function freeArticleFields(): string {
  return `
    content {
      __typename
      ... on ComponentArticleContentRichText {
        body
      }
      ... on ComponentArticleContentList {
        items {
          title
          body
        }
      }
      ... on ComponentArticleContentButton {
        label
        url
      }
      ... on ComponentArticleContentVideo {
        title
        description
        embedUrl
      }
    }
    authorsBio {
      data {
        attributes {
          name
          avatar {
            data {
              attributes {
                url
              }
            }
          }
          description
        }
      }
    }
    categories {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
  `;
}

function odNewsArticleFields(): string {
  return `
    intro
    list {
      items {
        link
        title
        description
        cover {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
      }
    }
    ending
    video {
      title
      description
      embedUrl
    }
    authorsBio {
      data {
        attributes {
          name
          avatar {
            data {
              attributes {
                url
              }
            }
          }
          description
        }
      }
    }    
  `;
}

function classifiedsArticleFields(): string {
  return `
    intro
    list {
      items {
        link
        title
        description
      }
    }
    ending
    video {
      title
      description
      embedUrl
    }
    authorsBio {
      data {
        attributes {
          name
          avatar {
            data {
              attributes {
                url
              }
            }
          }
          description
        }
      }
    }
  `;
}

function taskArticleFields(): string {
  return `
    highlights {
      highlights {
        icon {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
        title
        description
      }
    }
    steps {
      description
      steps {
        title
        body
      }
    }
    richTextAfterSteps
    documents {
      description
      documents {
        title
        action
        actionValue
      }
    }
    richTextAfterDocuments
    video {
      title
      description
      embedUrl
    }
    categories {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
  `;
}

function commonArticleFields(mainAttribute: ArticleMainAttributes): string {
  return `
    id
    attributes {
      title
      description
      slug
      cover {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        keywords
        metaRobots
        structuredData
        metaViewport
        canonicalURL
      }
      updatedAt
      createdAt
      ${specificFields[mainAttribute]()}
    }
  `;
}

const specificFields = {
  [ArticleMainAttributes.FreeArticle]: freeArticleFields,
  [ArticleMainAttributes.OdNewsArticle]: odNewsArticleFields,
  [ArticleMainAttributes.ClassifiedArticle]: classifiedsArticleFields,
  [ArticleMainAttributes.TaskArticle]: taskArticleFields,
};

export function generateArticleQuery(mainAttribute: ArticleMainAttributes) {
  const articleType = titleCase(singularize(mainAttribute));

  return gql`
    query Get${articleType}ById($id: ID!) {
      ${mainAttribute}(id: $id) {
        data {
          ${commonArticleFields(mainAttribute)}
        }
      }
    }
  `;
}
