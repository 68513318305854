import { StorageKeys } from "app/enums/storage-key.enum";
import useRouterQuery from "app/hooks/router-query.hook";
import useStorageProvider from "app/providers/storage.provider";
import { FC, useEffect, useReducer } from "react";
import { createContext } from "use-context-selector";
import { LinkingContextConstants } from "./constants";
import { linkingReducer } from "./reducer";
import {
  LinkingContextOptions,
  LinkingProviderOptions,
  LinkingReducerOptions,
} from "./types";

export const LinkingContext = createContext<LinkingContextOptions>(
  {} as LinkingContextOptions
);

const params: Record<any, any> = {
  utmSource: StorageKeys.UtmSource,
  utmCampaign: StorageKeys.UtmCampaign,
  gclid: StorageKeys.Gclid,
  fbclid: StorageKeys.Fbclid,
  referrerId: StorageKeys.ReferrerId,
  affiliateRefer: StorageKeys.AffiliateRefer,
  coupon: StorageKeys.Coupon,
  referrer: StorageKeys.Referrer,
};

const LinkingProvider: FC<LinkingProviderOptions> = ({
  children,
}): JSX.Element => {
  const routerQuery: URLSearchParams = useRouterQuery();

  const utmSource = routerQuery.get("utm_source");
  const utmCampaign = routerQuery.get("utm_campaign");
  const gclid = routerQuery.get("gclid");
  const fbclid = routerQuery.get("fbclid");
  const referrerId = routerQuery.get("referrer_id");
  const affiliateRefer = routerQuery.get("affiliate_refer");
  const coupon = routerQuery.get("coupon");
  const { referrer } = document;

  const linkingInitialState: LinkingReducerOptions = {
    utmSource,
    utmCampaign,
    gclid,
    fbclid,
    referrerId,
    affiliateRefer,
    coupon,
    referrer,
  };

  const [state, dispatch] = useReducer(linkingReducer, linkingInitialState);

  const { getItem, setItem, multiRemove } = useStorageProvider();

  /**
   * @returns {void}
   */
  function removeParams(): void {
    multiRemove([...Object.values(params)]);

    dispatch({
      type: LinkingContextConstants.CLEAN_PARAMS,
    });
  }

  /**
   * @returns {void}
   */
  useEffect((): void => {
    const loadedParams = {
      utmSource: null,
      utmCampaign: null,
      gclid: null,
      fbclid: null,
      referrerId: null,
      affiliateRefer: null,
      coupon: null,
      referrer: null,
    };

    Object.entries(params).forEach(([paramKey, storageKey]) => {
      const loadedValue = linkingInitialState[paramKey] ?? getItem(storageKey);

      if (loadedValue) {
        setItem(storageKey, loadedValue);
        loadedParams[paramKey] = loadedValue;
      }
    });

    dispatch({
      type: LinkingContextConstants.RESTORE_PARAMS,
      ...loadedParams,
    });
  }, []);

  return (
    <LinkingContext.Provider
      value={{
        state,
        removeParams,
      }}
    >
      {children}
    </LinkingContext.Provider>
  );
};

export default LinkingProvider;
