import useStrapiHelper from "app/helpers/strapi.helper";
import { ReactComponent as BookIcon } from "resources/assets/icons/book.svg";
import { IconContainer } from "../category-recommended-posts/styles";
import { Container, Content, Title } from "./styles";
import { ContentHitOptions } from "./types";
import { Grid } from "@mui/material";

export function ContentRelatedPostsHit({
  hit,
  onClick,
}: ContentHitOptions): JSX.Element {
  const { getImageUrl } = useStrapiHelper();

  return (
    <>
      <Container onClick={onClick}>
        {hit.cover ? (
          <img
            src={getImageUrl(hit.cover)}
            alt="Capa do conteúdo"
            style={{
              width: "166px",
              height: "91px",
              objectFit: "cover",
              objectPosition: "100% 100%",
              borderRadius: "8px 8px 0 0",
            }}
          />
        ) : (
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center",
              padding: "16px",
            }}
          >
            <IconContainer>
              <BookIcon />
            </IconContainer>
          </Grid>
        )}

        <Content container>
          <Title>{hit.title}</Title>
        </Content>
      </Container>
    </>
  );
}
