import { clickWithStopPropagation } from "app/helpers/click.helper";
import { DrawerLinkOptions } from "./types";

export default function DrawerLink({
  link,
  handleCloseDrawer,
  isPopoverOpen,
  handlePopoverOpen,
  handlePopoverClose,
}: DrawerLinkOptions) {
  const { href, title, childrenLinks, icon } = link;

  const handleOpenLink = (url?: string) => {
    if (url) {
      window.location.href = url;
    }

    handleCloseDrawer();
  };

  const toggleExpandable = () => {
    if (isPopoverOpen) {
      handlePopoverClose();
    } else {
      handlePopoverOpen();
    }
  };

  return (
    <>
      {childrenLinks ? (
        <>
          <button
            type="button"
            onClick={(event) =>
              clickWithStopPropagation(event, toggleExpandable)
            }
            className="flex justify-between py-4 px-8 text-base"
          >
            {title}
            {icon}
          </button>

          <div
            style={{
              maxHeight: isPopoverOpen ? "200px" : "0",
              transition: "max-height 0.2s ease-out",
            }}
            className="overflow-hidden"
          >
            {childrenLinks.map(({ href, title }, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handleOpenLink(href)}
                className="flex justify-start ml-4 py-4 px-8 text-base"
              >
                {title}
              </button>
            ))}
          </div>
        </>
      ) : (
        <button
          type="button"
          onClick={() => handleOpenLink(href)}
          className="flex justify-start py-4 px-8 text-base"
        >
          {title}
        </button>
      )}
    </>
  );
}
