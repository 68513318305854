import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  ArticleResponseCollection,
  ArticleResponseTypes,
} from "app/entities/article.entity";
import { DocumentNode } from "graphql";

export function useTypedQuery<T extends keyof ArticleResponseTypes>(
  mainAttribute: T,
  query: DocumentNode,
  options: QueryHookOptions
) {
  return useQuery<ArticleResponseCollection<T>>(query, options);
}
