import { Grid } from "@mui/material";
import { CustomRequired, CustomTypography } from "./styles";
import { InputLabelOptions } from "./types";

export function InputLabel({
  title,
  subtitle,
  onSubtitleClick,
  inputName,
  isRequired,
}: InputLabelOptions): JSX.Element {
  return (
    <>
      <Grid
        container
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        mb={"8px"}
      >
        <CustomTypography component="span">
          <label htmlFor={inputName}>
            {title}

            {isRequired && <CustomRequired>*</CustomRequired>}
          </label>
        </CustomTypography>

        {subtitle && (
          <CustomTypography
            onClick={onSubtitleClick}
            style={{
              textDecoration: "underline",
              color: "#8B11A5",
              cursor: "pointer",
            }}
          >
            {subtitle}
          </CustomTypography>
        )}
      </Grid>
    </>
  );
}
