import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const ContentContainer: any = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
  }
`;

export const TitleTypography: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 24px;
    line-height: 156%;
    color: black;

    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

export const DescriptionTypography: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    line-height: 156%;
    color: #595959;

    margin-bottom: 16px;
  }
`;

export const UpdatedAtTypography: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    color: #6b7280;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    margin-bottom: 16px;
  }
`;

export const CoverImg: any = styled.img`
  && {
    width: 100%;
    object-fit: contain;
  }
`;

export const AuthorContainer: any = styled(Grid)`
  && {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    border-bottom: 0.5px solid #cfcfcf;
    margin: 18px 0;
    padding: 0 0 16px;
  }
`;

export const AuthorName: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 14px;
    line-height: 156%;
    color: #737373;
  }
`;

export const SectionTitle: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 156%; /* 28.08px */
    letter-spacing: -0.36px;
  }
`;
