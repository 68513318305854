import QRCode from "react-qr-code";
import { Grid, Stack, Typography } from "@mui/material";

import useWindowSize from "app/hooks/use-window-size.hook";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";

import { SignUpQrCodeConstants } from "../download/constants";
import { getMountedUrl } from "../download/helpers/download.helper";

import { Button } from "resources/components/buttons/main";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthenticationLayout from "resources/layouts/authentication";
import SalesContactBackground from "resources/assets/images/background-sales-contact-screen.png";
import { ReactComponent as ArrowUpRight } from "resources/assets/icons/arrow-up-right.svg";

import { Title } from "./styles";
import { InfoMessage } from "./components/info-message";

const PAGE_TITLE = "Contato de Vendas - Ailu";

export default function SalesContactPage(): JSX.Element {
  const { isMobile } = useWindowSize();
  const { state: authenticationState } = useAuthentication();

  const windowHeight = window.innerHeight;

  const mountedUrl = authenticationState?.user
    ? getMountedUrl(SignUpQrCodeConstants.BASE_URL, authenticationState?.user)
    : SignUpQrCodeConstants.BASE_URL;

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout backgroundImage={SalesContactBackground}>
        {isMobile ? (
          <Grid
            container
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={"60px"}
            p={"24px"}
          >
            <InfoMessage />

            <Button
              title={"Abrir no App"}
              endIcon={<ArrowUpRight />}
              onClick={() => window.open(mountedUrl)}
              style={{
                position: "fixed",
                bottom: 24,
                width: "90%",
              }}
            />
          </Grid>
        ) : (
          <Grid container flexDirection={"column"} alignItems={"center"}>
            <Grid
              container
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={"10px"}
              minWidth={"380px"}
              maxWidth={"380px"}
            >
              <InfoMessage />

              <Grid
                container
                flexDirection={"column"}
                mb={windowHeight < 1024 ? "10px" : "24px"}
              >
                <Title mt={"8px"}>{"Aproveite e baixe nosso app e"}</Title>
                <Title>
                  <span>{"navegue rumo à Portugal"}</span>
                </Title>
              </Grid>

              <Grid
                container
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  gap: windowHeight < 1024 ? "16px" : "24px",
                }}
              >
                <Stack
                  width={"100%"}
                  style={{
                    flexDirection: "column",
                    gap: windowHeight < 1024 ? "16px" : "24px",
                    borderRadius: 16,
                    backgroundColor: "#F9FAFB",
                    padding: windowHeight < 1024 ? "16px" : "24px 16px",
                  }}
                >
                  <Grid
                    container
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <QRCode
                      value={mountedUrl}
                      style={{
                        width: "158px",
                        height: "158px",
                      }}
                    />
                  </Grid>

                  <Grid container flexDirection={"column"} gap={"12px"}>
                    <Typography
                      sx={{
                        color: "#374151",
                        fontFamily: "'InterSemiBold', sans-serif",
                        fontSize: windowHeight < 1024 ? "14px" : "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "140%",
                        letterSpacing: "-0.12px",
                      }}
                    >
                      {"Instruções"}
                    </Typography>

                    <Grid container flexDirection={"column"}>
                      {SignUpQrCodeConstants.INSTRUCTIONS.map(
                        (instruction, index) => (
                          <Typography
                            sx={{
                              opacity: 0.9,
                              color: "#374151",
                              fontFamily: "'InterRegular', sans-serif",
                              fontSize: windowHeight < 1024 ? "12px" : "14px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "160%",
                              letterSpacing: "-0.14px",
                            }}
                          >
                            <span>{`${index + 1}. ${instruction}`}</span>
                          </Typography>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              mt={windowHeight < 1024 ? "10px" : "24px"}
              sx={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                container
                flexDirection={"column"}
                alignItems={"center"}
                gap={windowHeight < 1024 ? "6px" : "12px"}
              >
                <Typography
                  sx={{
                    color: "#374151",
                    fontFamily: "'InterRegular', sans-serif",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    textAlign: "center",
                    lineHeight: "156%",
                    letterSpacing: "-0.12px",
                  }}
                >
                  {"Disponível para"}
                </Typography>

                <Grid container gap={"16px"} maxWidth={"max-content"}>
                  {SignUpQrCodeConstants.USEFUL_LINKS.map((item) => (
                    <img
                      style={{ cursor: "pointer" }}
                      src={item.icon}
                      alt={"store"}
                      onClick={() => window.open(item.link)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </AuthenticationLayout>
    </>
  );
}
