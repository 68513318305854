import { Typography } from "@mui/material";
import styled from "styled-components";

const windowHeight = window.innerHeight;

export const HeaderText: any = styled(Typography)`
  && {
    color: #6b7280;
    font-size: ${windowHeight < 1024 ? "12px" : "14px"};
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
  }
`;

export const BodyText: any = styled(Typography)`
  && {
    color: #000;
    font-family: "InterRegular", sans-serif;
    font-size: ${windowHeight < 1024 ? "20px" : "24px"};
    font-style: normal;
    font-weight: 500;
    line-height: 130%;

    span {
      color: #3b0764;
      font-weight: 600;
    }
  }
`;

export const FooterText: any = styled(Typography)`
  && {
    color: #6b7280;
    font-family: "InterRegular", sans-serif;
    font-size: ${windowHeight < 1024 ? "14px" : "16px"};
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.16px;
  }
`;
