import { Grid } from "@mui/material";
import { ReactComponent as ChevronLeft } from "resources/assets/icons/chevron-left.svg";
import { ProgressBar } from "./components/progress-bar";
import { StyledButton } from "./styles";
import { AuthenticationHeadingOptions } from "./types";

export function AuthenticationHeading({
  value,
  onBackButtonClick,
}: AuthenticationHeadingOptions): JSX.Element {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        mb={"16px"}
      >
        {value && <ProgressBar value={value} />}

        {onBackButtonClick && (
          <StyledButton startIcon={<ChevronLeft />} onClick={onBackButtonClick}>
            {"voltar"}
          </StyledButton>
        )}
      </Grid>
    </>
  );
}
