import styled, { css } from "styled-components";

import { Grid } from "@mui/material";

export const StyledGrid: any = styled(Grid)`
  && {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;

    &.unselectedTask {
      img {
        width: 100%;
      }

      p {
        font-family: "InterRegular", sans-serif;
        font-size: 12px;
        line-height: 16.8px;
        letter-spacing: -2%;
        color: #bfbfbf;
        max-width: 256px;
        text-align: center;
      }
    }

    @media screen and (min-width: 951px) {
      overflow-x: hidden;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 6px;
        margin-left: auto;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--primary-900);
        border-radius: 24px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(242, 244, 245, 0.6);
        border-radius: 24px;
      }
    }
  }
`;
