import { ApolloProvider } from "@apollo/client";
import AuthenticationProvider from "app/contexts/authentication";
import { DialogProvider } from "app/contexts/dialog";
import LinkingProvider from "app/contexts/linking";
import NotificationsProvider from "app/contexts/notifications";
import { ApolloGraphQLClient } from "configuration/apollo.configuration";
import { EnvironmentConfiguration } from "configuration/environment.configuration";
import { ToastContainerOptions } from "configuration/toast.configuration";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Providers({ children }): JSX.Element {
  const storageUser: any = localStorage.getItem("@AILU:user");
  const user = JSON.parse(storageUser);

  // Google Analytics settings
  const options = {
    gaOptions: {
      userId: user?.id,
    },
  };
  ReactGA.initialize("G-NFM02LH819", options);

  // Mix panel settings
  if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
    mixpanel.init(EnvironmentConfiguration.MIXPANEL_TOKEN, {
      persistence: "cookie",
    });
  }

  return (
    <>
      <DialogProvider>
        <ApolloProvider client={ApolloGraphQLClient}>
          <LinkingProvider>
            <AuthenticationProvider>
              <NotificationsProvider>{children}</NotificationsProvider>
            </AuthenticationProvider>
          </LinkingProvider>
        </ApolloProvider>
      </DialogProvider>

      <ToastContainer {...ToastContainerOptions} />
    </>
  );
}
