import { Box } from "@mui/material";
import styled from "styled-components";

export const CenteredContainer: any = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
  }
`;
