import { Avatar, Box, Grid } from "@mui/material";
import { clickWithStopPropagation } from "app/helpers/click.helper";
import useStringHelper from "app/helpers/string.helper";
import { ReactComponent as VerifiedImage } from "resources/assets/icons/verified-blue.svg";
import { DropdownMenu } from "resources/components/dropdown-menu";
import { Created, Name, PreName } from "./styles";
import { AvatarImageProps, PersonProps } from "./types";

export function AvatarImage({
  avatar,
  name,
  ...props
}: AvatarImageProps): JSX.Element {
  const { getInitials } = useStringHelper();

  return (
    <Avatar
      src={avatar || "/broken-image.jpg"}
      style={{ width: 32, height: 32 }}
      {...props}
    />
  );
}

export function Person({
  avatar,
  name,
  title,
  verified,
  createdAt,
  preName,
  dropdownOptions,
  isLegalComment,
  post,
  openModal,
}: PersonProps): JSX.Element {
  const { getFirstTwoWords } = useStringHelper();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      flexWrap={isLegalComment ? "nowrap" : "wrap"}
    >
      <Grid container maxWidth={"max-content"}>
        <Grid
          container
          alignItems="center"
          gap={"8px"}
          onClick={(event: any) =>
            clickWithStopPropagation(event, () =>
              openModal && post.id ? openModal(post.id) : {}
            )
          }
          sx={{ cursor: "pointer" }}
        >
          <AvatarImage avatar={avatar} name={name} />

          <Grid>
            <Grid container gap={"4px"} alignItems="center">
              {name && (
                <Name style={{ display: "flex", alignItems: "center", gap: 3 }}>
                  {preName && (
                    <PreName style={{ fontWeight: "300" }}>{preName}</PreName>
                  )}
                  {getFirstTwoWords(name)}
                </Name>
              )}

              {verified && <VerifiedImage />}
            </Grid>

            {title && <Created>{title}</Created>}
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <Grid container alignItems={"center"}>
          {createdAt && <Created className="date">{createdAt}</Created>}

          {dropdownOptions?.length && (
            <DropdownMenu options={dropdownOptions} />
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
