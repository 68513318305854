import { ContentFeedbackActions } from "../enums/actions.enum";
import {
  ContentFeedbackReducerActionOptions,
  ContentFeedbackReducerOptions,
} from "../types";

export const contentFeedbackReducer = (
  prevState: ContentFeedbackReducerOptions,
  action: ContentFeedbackReducerActionOptions
): ContentFeedbackReducerOptions => {
  switch (action.type) {
    case ContentFeedbackActions.RestoreParams:
      return {
        ...prevState,
        ...action,
      };

    default:
      return prevState;
  }
};
