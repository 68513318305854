import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomGrid: any = styled(Grid)`
  && {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 64px 20px;
    max-width: 400px;
  }
`;

export const Title: any = styled(Typography)`
  && {
    color: #111827;
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

    span {
      color: #8b11a5;
    }
  }
`;

export const FooterTitle: any = styled(Typography)`
  && {
    color: #6b7280;
    text-align: center;
    font-family: "InterRegular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
    letter-spacing: -0.12px;
  }
`;
