import { Box, Grid } from "@mui/material";
import { Content } from "app/entities/content.entity";
import useStrapiHelper from "app/helpers/strapi.helper";
import { AlgoliaSearchClient } from "configuration/algolia.configuration";
import { Configure, InstantSearch, connectHits } from "react-instantsearch-dom";
import { TabViewScrollable } from "resources/components/tab-view-scrollable";
import { SectionTitle } from "resources/packages/contents/styled/styles";
import { ContentRelatedPostsHit } from "../hits/content-related-posts";
import { ContentSliderOptions } from "./types";

const CustomHits: any = connectHits(
  ({ hits, onContentClick, contentId }: any) => {
    const { mergeContentId } = useStrapiHelper();

    return hits.map(
      (hit: Content) =>
        hit.id !== contentId && (
          <ContentRelatedPostsHit
            key={hit.id}
            hit={hit}
            onClick={() => onContentClick(mergeContentId(hit.id, hit.slug))}
          />
        )
    );
  }
);

export function ContentRelatedPostsSlider({
  title,
  contentId,
  categoryFilter,
  indexName,
  onClick,
}: ContentSliderOptions): JSX.Element {
  return (
    <>
      <InstantSearch searchClient={AlgoliaSearchClient} indexName={indexName}>
        <Configure filters={categoryFilter} hitsPerPage={4} />

        <Box
          display={"flex"}
          alignItems={"center"}
          mb={"16px"}
          justifyContent={"space-between"}
        >
          <Grid container alignItems={"center"}>
            <SectionTitle>{title}</SectionTitle>
          </Grid>
        </Box>

        <TabViewScrollable
          extraStyles={{
            ".MuiTabs-scroller": {
              "& > div": {
                gap: "10px",
              },
            },
          }}
        >
          <CustomHits onContentClick={onClick} contentId={contentId} />
        </TabViewScrollable>
      </InstantSearch>
    </>
  );
}
