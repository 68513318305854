import { User } from "app/entities/user.entity";
import clevertap from "clevertap-web-sdk";
import { EnvironmentConfiguration } from "configuration/environment.configuration";
import { useEffect } from "react";
import { createContext } from "use-context-selector";
import { useAuthentication } from "../authentication/use-authentication.hook";
import { NotificationsOptions } from "./types";

export const NotificationsContext = createContext<NotificationsOptions>(
  {} as NotificationsOptions
);

export function NotificationsProvider({ children }): JSX.Element | null {
  const { state: authenticationState } = useAuthentication();

  function initCleverTap(user: User): void {
    clevertap.init(EnvironmentConfiguration.CLEVERTAP_KEY || "");

    clevertap.notifications.push({
      titleText: "Deseja receber as notificações da Ailu?",
      bodyText: "Fique por dentro das novidades do App que te leva a Portugal!",
      okButtonText: "Sim, quero!",
      rejectButtonText: "Não, obrigado",
      okButtonColor: "#f28046",
      okCallback: () => {
        clevertap.onUserLogin.push({
          Site: {
            Email: user.email,
            Name: user.name,
            "MSG-push": true,
          },
        });
      },
    });
  }

  useEffect(() => {
    const bootstrapAsync = async (): Promise<void> => {
      if (authenticationState.user) {
        initCleverTap(authenticationState.user);
      }
    };

    bootstrapAsync();
  }, [authenticationState.user]);

  return (
    <NotificationsContext.Provider value={{ children }}>
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsProvider;
