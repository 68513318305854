import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  ArticleCommentResponse,
  CommentResponseTypes,
} from "app/entities/content-comment.entity";
import { DocumentNode } from "graphql";

export function useTypedCommentQuery<T extends keyof CommentResponseTypes>(
  mainAttribute: T,
  query: DocumentNode,
  options: QueryHookOptions
) {
  return useQuery<ArticleCommentResponse<T>>(query, options);
}
