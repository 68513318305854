import { Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const Container: any = styled(Grid)`
  && {
    padding: 16px;
    background: #f3f4f6;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledTypography: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    color: #000;
  }
`;

export const FeedbackButton: any = styled(Button)`
  && {
    font-family: "InterRegular, sans-serif";
    border-radius: 100px;
    border: 1px solid
      ${({ selected }: { selected: boolean }) =>
        selected ? "#6b7280" : "#e4e7eb"};
    background: ${({ selected }: { selected: boolean }) =>
      selected ? "#f7f7f7" : "#fff"};
    text-transform: none;
    box-shadow: none;
    color: #374151;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
  }
`;
