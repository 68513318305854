import { Typography } from "@mui/material";
import styled from "styled-components";

export const CustomTypography: any = styled(Typography)`
  && {
    color: #1f2937;
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 156%;
    letter-spacing: -0.14px;

    &.labelHeight {
      min-height: 39.2px;
    }
  }
`;

export const CustomRequired: any = styled.span`
  color: #470e89;
  font-family: "InterMedium", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Subtitle = styled.span`
  color: #6b7280;
  font-family: "InterRegular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%;
  letter-spacing: -0.14px;
  margin-right: 8px;
`;
