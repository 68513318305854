import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { EnvironmentConfiguration } from "./environment.configuration";

const httpLink = new HttpLink({
  uri: `${EnvironmentConfiguration.STRAPI_URL}/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${EnvironmentConfiguration.STRAPI_TOKEN}` || null,
    },
  });

  return forward(operation);
});

export const ApolloGraphQLClient = new ApolloClient({
  link: ApolloLink.from([authMiddleware, httpLink]),
  cache: new InMemoryCache(),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
