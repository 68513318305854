import { useLayoutEffect } from "react";
import { Location, useLocation } from "react-router-dom";
import { ScrollWrapperHandlerOptions } from "./types";

export function ScrollWrapperHandler({
  children,
}: ScrollWrapperHandlerOptions): JSX.Element {
  const location: Location = useLocation();

  /**
   * @returns {void}
   */
  useLayoutEffect(() => {
    // Save the original scroll-behavior
    const originalScrollBehavior =
      document.documentElement.style.scrollBehavior;

    // Disable scroll animation
    document.documentElement.style.scrollBehavior = "auto";

    // Scroll to the top of the page
    document.documentElement.scrollTo(0, 0);

    // Restore the original scroll-behavior after the scrolling occurs
    requestAnimationFrame(() => {
      document.documentElement.style.scrollBehavior = originalScrollBehavior;
    });
  }, [location.pathname]);

  return <>{children}</>;
}
