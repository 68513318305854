import { Stack } from "@mui/material";
import { InputConstants } from "app/constants/input.constants";
import { useContentFeedback } from "app/contexts/contents/feedback/hooks/content-feedback.hook";
import { useFormik } from "formik";
import { Button } from "resources/components/buttons/main";
import { TextAreaInput } from "resources/components/form/inputs/text-area";
import { FeedbackYupFormSchema } from "./schema.yup";
import { Container } from "./styles";
import { FeedbackFormData, FeedbackProps } from "./types";

export function Feedback({}: FeedbackProps): JSX.Element {
  const { handleFeedbackResponse } = useContentFeedback();

  const formik = useFormik({
    initialValues: {
      [InputConstants.FEEDBACK]: "",
    },
    validationSchema: FeedbackYupFormSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(data: FeedbackFormData): Promise<void> {
    try {
      handleFeedbackResponse({
        comment: data.feedback,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container mt="16px">
      <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
        <Stack>
          <TextAreaInput
            label={"O que poderia tornar esse conteúdo melhor?"}
            options={{
              id: InputConstants.FEEDBACK,
              name: InputConstants.FEEDBACK,
              value: formik.values[InputConstants.FEEDBACK],
              onChange: formik.handleChange(InputConstants.FEEDBACK),
              disabled: formik.isSubmitting,
              placeholder: "Escreva aqui",
            }}
            error={formik.errors[InputConstants.FEEDBACK]}
          />
        </Stack>

        <Button
          fullWidth
          title={"Enviar feedback"}
          type="submit"
          onSubmit={() => formik.handleSubmit()}
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          sx={{ mt: "24px" }}
        />
      </form>
    </Container>
  );
}
