import { Popover as PopoverMui } from "@mui/material";
import styled, { css } from "styled-components";

export const StyledPopover = styled(PopoverMui)`
  && .MuiPopover-paper {
    border-radius: 15px;
    position: relative;
    width: fit-content;
  }
`;
