import {
  BEARER_TOKEN_PREFIX,
  RestAPIConstants,
} from "app/constants/rest-api.constants";
import api from "app/providers/api.provider";
import { AxiosResponse, HttpStatusCode } from "axios";
import { EnvironmentConfiguration } from "configuration/environment.configuration";
import {
  dispatchErrorToastMessage,
  dispatchWarningToastMessage,
} from "configuration/toast.configuration";
import { useCallback } from "react";

function useAuthenticationService() {
  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  const clientToken = useCallback(
    async (options?: any): Promise<AxiosResponse<any>> => {
      const data: Object = {
        grant_type: options?.client_credentials ?? "client_credentials",
        client_id:
          options?.client_id ?? EnvironmentConfiguration.USER_AUTH_CLIENT_ID,
        client_secret:
          options?.client_secret ??
          EnvironmentConfiguration.USER_AUTH_CLIENT_SECRET,
        scope: "",
        ...options,
      };

      return api
        .post(RestAPIConstants.OAUTH_TOKEN, data)
        .catch((error: any) => {
          if (error?.response?.status === HttpStatusCode.BadRequest) {
            dispatchWarningToastMessage("Usuário ou senha inválidos");
          } else {
            dispatchErrorToastMessage("Usuário ou senha inválidos");
          }

          throw error;
        });
    },
    []
  );

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async function getAuthenticatedUser(
    accessToken?: string
  ): Promise<AxiosResponse<any>> {
    if (accessToken) {
      api.defaults.headers.Authorization = `${BEARER_TOKEN_PREFIX}${accessToken}`;
    }

    return api.get(RestAPIConstants.PROFILE).catch((error: any) => {
      throw error;
    });
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async function signUp(data: any): Promise<AxiosResponse<any>> {
    return api.post(RestAPIConstants.SIGN_UP, data).catch((error: any) => {
      if (error?.response?.status === HttpStatusCode.Forbidden) {
        dispatchWarningToastMessage("Conta já cadastrada");
      } else {
        dispatchErrorToastMessage("Erro na autenticação");
      }

      throw error;
    });
  }

  return {
    clientToken,
    getAuthenticatedUser,
    signUp,
  };
}

export default useAuthenticationService;
