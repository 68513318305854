import { BEARER_TOKEN_PREFIX } from "app/constants/rest-api.constants";
import { User, UserConvert } from "app/entities/user.entity";
import { AppRoutes } from "app/enums/app-route.enum";
import { AuthenticationTypes } from "app/enums/authentication-type.enum";
import { MixpanelEvents } from "app/enums/mix-panel.enum";
import useStringHelper from "app/helpers/string.helper";
import useRouterQuery from "app/hooks/router-query.hook";
import api from "app/providers/api.provider";
import useAuthenticationProvider from "app/providers/authentication.provider";
import {
  dispatchPeopleToMixPanel,
  dispatchToMixpanel,
} from "app/providers/event.provider";
import useAuthenticationService from "app/services/authentication.service";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { EnvironmentConfiguration } from "configuration/environment.configuration";
import mixpanel from "mixpanel-browser";
import { SignInFormData } from "pages/public/authentication/sign-in/types";
import { SignUpFormData } from "pages/public/authentication/sign-up/types";
import { FC, useCallback, useEffect, useReducer } from "react";
import { ReactComponent as LogOutIcon } from "resources/assets/icons/modal/log-out.svg";
import { Dialog } from "resources/components/dialog";
import { createContext } from "use-context-selector";
import { useDialog } from "../dialog";
import { AuthenticationContextConstants } from "./constants";
import useAuthenticationHelper from "./helper";
import { authenticationReducer } from "./reducer";
import { handleProfileMixPanelEvents } from "./tracking.helper";
import {
  AuthenticatedUserOptions,
  AuthenticationContextOptions,
  AuthenticationProviderOptions,
  AuthenticationReducerOptions,
  HandleAuthenticatedUserOptions,
  SignUpOptions,
} from "./types";

export const AuthenticationContext =
  createContext<AuthenticationContextOptions>(
    {} as AuthenticationContextOptions
  );

export const authenticationInitialState: AuthenticationReducerOptions = {
  isLoading: true,
  isAuthenticated: false,
  isSignOut: false,
  user: undefined,
  nextBehavior: undefined,
  authenticationType: undefined,
};

const AuthenticationProvider: FC<AuthenticationProviderOptions> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(
    authenticationReducer,
    authenticationInitialState
  );

  const routerQuery: URLSearchParams = useRouterQuery();
  const webviewMode: boolean = Boolean(
    routerQuery.get("webviewMode") === "true"
  );
  const socialAuthToken: string | null =
    routerQuery.get("access_token") ?? routerQuery.get("authtoken");

  const { getFirstWord, getLastWord } = useStringHelper();
  const {
    getUser: getUserFromLocalStorage,
    getToken: getTokenFromLocalStorage,
    setUser: setUserIntoLocalStorage,
    removeSession,
    createSession,
  } = useAuthenticationProvider();

  const { getNextBehavior } = useAuthenticationHelper();
  const {
    getAuthenticatedUser,
    clientToken: getClientOauthToken,
    signUp: register,
  } = useAuthenticationService();

  const { setGlobalDialog, setOpenGlobalDialog } = useDialog();

  /**
   * @returns {Promise<void>}
   */
  const signIn = useCallback(
    async (
      data: SignInFormData,
      nextBehavior?: string,
      authenticationType?: AuthenticationTypes
    ): Promise<void> => {
      try {
        const payload: Object = {
          username: data.email,
          password: data.password,
          grant_type: "password",
          client_id: EnvironmentConfiguration.USER_AUTH_ID,
          client_secret: EnvironmentConfiguration.USER_AUTH_SECRET,
        };

        const requestResponse = await getClientOauthToken(payload);

        if (requestResponse.status === HttpStatusCode.Ok) {
          if (authenticationType !== AuthenticationTypes.SignUp) {
            dispatchToMixpanel(MixpanelEvents.UserSignIn);
            const mixPanelParams = {
              Method: "Direct",
              PagePath: AppRoutes.SignInForm,
            };

            dispatchToMixpanel(MixpanelEvents.UserSignIn, mixPanelParams);
          }

          const accessToken: string | null = requestResponse.data?.access_token;

          if (accessToken) {
            api.defaults.headers.Authorization = `${BEARER_TOKEN_PREFIX}${accessToken}`;

            const options: HandleAuthenticatedUserOptions = {
              accessToken,
              authenticationType:
                authenticationType ?? AuthenticationTypes.SignIn,
            };
            const authenticatedUserOptions:
              | AuthenticatedUserOptions
              | undefined = await handleAuthenticatedUser(options);

            if (authenticatedUserOptions) {
              dispatch({
                type: AuthenticationContextConstants.SIGN_IN,
                user: authenticatedUserOptions.user,
                nextBehavior:
                  nextBehavior ?? authenticatedUserOptions.nextBehavior,
                authenticationType,
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  /**
   * @param data
   * @returns {Promise<void>}
   */
  const signInByAccessToken = useCallback(
    async (accessToken: string, enableStorage?: boolean): Promise<void> => {
      const authenticatedProps: AuthenticatedUserOptions | undefined =
        await handleAuthenticatedUser({
          accessToken,
        });

      if (!!authenticatedProps) {
        if (enableStorage) {
          await createSession(authenticatedProps.user, accessToken);
        }

        dispatch({
          type: AuthenticationContextConstants.SIGN_IN,
          user: authenticatedProps.user,
          nextBehavior: authenticatedProps.nextBehavior,
          authenticationType: AuthenticationTypes.SignIn,
        });
      }
    },
    []
  );

  /**
   * @returns {Promise<void>}
   */
  const signUp = useCallback(
    async (data: SignUpFormData, options?: SignUpOptions): Promise<void> => {
      const requestResponse: AxiosResponse<any> = await getClientOauthToken();

      if (requestResponse.status === HttpStatusCode.Ok) {
        if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
          const mixPanelParams = {
            Method: "Direct",
            PagePath: AppRoutes.SignUpForm,
          };

          dispatchToMixpanel(MixpanelEvents.UserSignUp, mixPanelParams);
          dispatchPeopleToMixPanel({
            [`$first_name`]: getFirstWord(data.name),
            [`$last_name`]: getLastWord(data.name),
            [`$email`]: data.email,
          });
        }

        const accessToken: string | null = requestResponse.data?.access_token;

        if (accessToken) {
          api.defaults.headers.Authorization = `${BEARER_TOKEN_PREFIX}${accessToken}`;

          const signUpResponse: AxiosResponse<any> = await register(data);

          if (signUpResponse.status === HttpStatusCode.NoContent) {
            const signInPayload: SignInFormData = {
              email: data.email,
              password: data.password,
            };

            await signIn(
              signInPayload,
              options?.nextBehavior,
              AuthenticationTypes.SignUp
            );
          }
        }
      }
    },
    []
  );

  /**
   * @returns {void}
   */
  const signOut = useCallback((): void => {
    try {
      removeSession();

      if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
        mixpanel.reset();
      }

      dispatch({
        type: AuthenticationContextConstants.SIGN_OUT,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  /**
   * @returns {void}
   */
  const openSignOutDialog = useCallback((): void => {
    const logOutDialog = (
      <Dialog
        onClose={() => {
          setOpenGlobalDialog(false);
        }}
        topIcon={<LogOutIcon />}
        title="Você tem certeza que quer sair da plataforma?"
        buttons={[
          {
            title: "Não, quero continuar na Ailu!",
            onClick: () => {
              setOpenGlobalDialog(false);
            },
          },
          {
            title: "Sim, quero sair!",
            variant: "white",
            onClick: () => {
              setOpenGlobalDialog(false);
              signOut();
            },
          },
        ]}
      />
    );

    setGlobalDialog(logOutDialog);
    setOpenGlobalDialog(true);
  }, []);

  /**
   * @returns {void}
   */
  const changeUser = (userEntity: User): void => {
    setUserIntoLocalStorage(userEntity);

    const nextBehavior: string | undefined = getNextBehavior(userEntity);

    dispatch({
      type: AuthenticationContextConstants.CHANGE_USER,
      user: userEntity,
      nextBehavior,
    });
  };

  /**
   * @returns {void}
   */
  const changeNextBehavior = (nextBehavior: string): void => {
    dispatch({
      type: AuthenticationContextConstants.CHANGE_NEXT_BEHAVIOR,
      nextBehavior,
    });
  };

  /**
   * @returns {void}
   */
  const turnSignOutFlagOff = (): void => {
    dispatch({
      type: AuthenticationContextConstants.TURN_SIGN_OUT_FLAG_OFF,
    });
  };

  /**
   * @returns {Promise<User|undefined>}
   */
  async function handleAuthenticatedUser(
    options?: HandleAuthenticatedUserOptions
  ): Promise<AuthenticatedUserOptions | undefined> {
    let authenticatedUserOptions: AuthenticatedUserOptions | undefined;

    const accessToken = options?.accessToken ?? getTokenFromLocalStorage();

    if (accessToken) {
      const requestResponse: void | AxiosResponse<any, any> =
        await getAuthenticatedUser(accessToken).catch(
          async (error: AxiosError<any>) => {
            if (error?.response?.status === HttpStatusCode.Unauthorized) {
              if (options?.signOutWhenUnauthorizedEnabled) {
                signOut();
              }
            }
          }
        );

      if (requestResponse?.status === HttpStatusCode.Ok) {
        const userEntity: User = UserConvert.toUser(
          JSON.stringify(requestResponse.data.data)
        );

        if (userEntity) {
          if (!webviewMode) {
            if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
              if (options?.authenticationType === AuthenticationTypes.SignUp) {
                mixpanel.alias(String(userEntity.id));
              }

              mixpanel.identify(String(userEntity.id));

              handleProfileMixPanelEvents(
                {
                  [`$first_name`]: getFirstWord(userEntity.name),
                  [`$last_name`]: getLastWord(userEntity.name),
                  [`$email`]: userEntity.email,
                },
                userEntity
              );
            }

            createSession(userEntity, accessToken);
          }

          const nextBehavior: string | undefined = getNextBehavior(
            userEntity,
            webviewMode
          );

          authenticatedUserOptions = {
            user: userEntity,
            nextBehavior,
          };
        }
      }
    }

    return authenticatedUserOptions;
  }

  /**
   * @returns {void}
   */
  useEffect((): void => {
    const bootstrapAsync = async (): Promise<void> => {
      let user: User | undefined;

      const dispatchPayload: any = {
        type: AuthenticationContextConstants.RESTORE_TOKEN,
        isAuthenticated: false,
        user,
        nextBehavior: AppRoutes.SignIn,
      };

      try {
        user = getUserFromLocalStorage();

        if (user) {
          const options: HandleAuthenticatedUserOptions = {
            signOutWhenUnauthorizedEnabled: true,
            authenticationType: AuthenticationTypes.Profile,
          };
          const authenticatedUserOptions: AuthenticatedUserOptions | undefined =
            await handleAuthenticatedUser(options);

          if (authenticatedUserOptions) {
            // Send Appcues event
            window.Appcues.identify(
              authenticatedUserOptions.user?.id,
              authenticatedUserOptions.user
            );

            dispatchPayload.isAuthenticated = true;
            dispatchPayload.user = authenticatedUserOptions.user;
            dispatchPayload.nextBehavior =
              authenticatedUserOptions.nextBehavior;
          }
        } else if (webviewMode || !!socialAuthToken) {
          const authAccessToken: string | null =
            routerQuery.get("webviewToken") ?? socialAuthToken;

          if (authAccessToken) {
            const ssoAuthProvider: string | null =
              routerQuery.get("sso_provider");
            const ssoAuthType: string | null = routerQuery.get("sso_type");
            const ssoOrigin: string | null = routerQuery.get("sso_origin");
            const ssoPlan: string | null = routerQuery.get("sso_plan");

            const options: HandleAuthenticatedUserOptions = {
              signOutWhenUnauthorizedEnabled: false,
              authenticationType:
                (ssoAuthType as AuthenticationTypes) ??
                AuthenticationTypes.Profile,
              accessToken: authAccessToken,
              isSocialAuth: !!ssoAuthType && !!ssoAuthProvider,
              hasSelectedPlan: !!ssoPlan,
            };

            const authenticatedUserOptions:
              | AuthenticatedUserOptions
              | undefined = await handleAuthenticatedUser(options);

            if (authenticatedUserOptions) {
              if (!!socialAuthToken && !webviewMode) {
                createSession(authenticatedUserOptions.user, socialAuthToken);

                if (ssoAuthProvider && ssoAuthType) {
                  if (EnvironmentConfiguration.MIXPANEL_TOKEN) {
                    const mixPanelParams = {
                      Method: ssoAuthProvider,
                      PagePath: ssoOrigin,
                    };

                    if (ssoAuthType === AuthenticationTypes.SignUp) {
                      dispatchToMixpanel(
                        MixpanelEvents.UserSignUp,
                        mixPanelParams
                      );
                      dispatchPeopleToMixPanel({
                        [`$first_name`]: getFirstWord(
                          authenticatedUserOptions.user.name
                        ),
                        [`$last_name`]: getLastWord(
                          authenticatedUserOptions.user.name
                        ),
                        [`$email`]: authenticatedUserOptions.user.email,
                      });
                    } else if (ssoAuthType === AuthenticationTypes.SignIn) {
                      dispatchToMixpanel(
                        MixpanelEvents.UserSignIn,
                        mixPanelParams
                      );
                    }
                  }
                }
              }

              dispatchPayload.isAuthenticated = true;
              dispatchPayload.user = authenticatedUserOptions.user;
              dispatchPayload.nextBehavior =
                authenticatedUserOptions.nextBehavior;
            }
          }
        }
      } catch (error) {
        // Restoring token failed
        console.error(error);
      }

      dispatch(dispatchPayload);
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    const getOrCreateCurrentSession = () =>
      state.isAuthenticated
        ? api.post("api/users/get-or-create-current-session")
        : null;

    getOrCreateCurrentSession();
    const interval = setInterval(() => {
      getOrCreateCurrentSession();
    }, 15 * 60 * 1000); // 15 minutes

    // The cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [state.isAuthenticated]);

  return (
    <AuthenticationContext.Provider
      value={{
        state,

        signIn,
        signInByAccessToken,
        signUp,
        signOut,
        turnSignOutFlagOff,
        openSignOutDialog,

        changeNextBehavior,
        changeUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
