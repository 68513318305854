import { Grid } from "@mui/material";
import styled from "styled-components";

export const Container: any = styled(Grid)`
  && {
    padding: 24px 20px;
    border-radius: 8px;
    border: 1px solid #e4e7eb;
    background: #fff;
  }
`;
