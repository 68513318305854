import { Grid, Stack } from "@mui/material";
import { InputConstants } from "app/constants/input.constants";
import { AppRoutes } from "app/enums/app-route.enum";
import useAuthenticationService from "app/services/authentication.service";
import usePasswordService from "app/services/password.service";
import { AxiosResponse, HttpStatusCode } from "axios";
import { dispatchSuccessToastMessage } from "configuration/toast.configuration";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AuthenticationFooter } from "resources/components/authentication/footer";
import { Button } from "resources/components/buttons/main";
import { TextInput } from "resources/components/form/inputs/text";
import { AnimationHandler } from "resources/components/handlers/animation";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthenticationLayout, {
  AuthenticationElement,
} from "resources/layouts/authentication";
import { ForgotPasswordInitialValues } from "./constants";
import { ForgotPasswordValidationSchema } from "./schema.yup";
import { ForgotPasswordFormData } from "./types";

const PAGE_TITLE = "Esqueci a senha";

export default function ForgotPasswordPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const [clientToken, setClientToken] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { clientToken: getClientOauthToken } = useAuthenticationService();
  const { forgot: forgotPassword } = usePasswordService();

  const formik = useFormik<ForgotPasswordFormData>({
    initialValues: ForgotPasswordInitialValues,
    onSubmit: handleSubmit,
    validationSchema: ForgotPasswordValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  /**
   * @returns {Promise<void>}
   */
  async function handleSubmit(data: ForgotPasswordFormData): Promise<void> {
    try {
      if (clientToken) {
        const requestResponse: AxiosResponse<any> = await forgotPassword(
          data,
          clientToken
        );

        if (requestResponse?.status === HttpStatusCode.Ok) {
          dispatchSuccessToastMessage(
            "Confira seu e-mail para criar uma nova senha!"
          );

          navigate(AppRoutes.SignInForm);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onSignInFormClick(): void {
    navigate(AppRoutes.SignInForm);
  }

  function onSignInClick(): void {
    navigate(AppRoutes.SignIn);
  }

  /**
   * @returns {void}
   */
  useEffect((): void => {
    const bootstrapAsync = async (): Promise<void> => {
      try {
        setIsLoading(true);

        const requestResponse: AxiosResponse<any> = await getClientOauthToken();

        if (requestResponse?.status === HttpStatusCode.Ok) {
          const accessToken: string | null = requestResponse.data?.access_token;

          if (accessToken) {
            setClientToken(accessToken);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout>
        <AuthenticationElement
          title={PAGE_TITLE}
          subtitle="Enviaremos um link de recuperação para seu e-mail cadastrado."
          onBackButtonClick={onSignInClick}
        >
          {isLoading ? (
            <Grid container justifyContent={"center"}>
              <AnimationHandler />
            </Grid>
          ) : (
            <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
              <Stack spacing={"24px"}>
                <TextInput
                  label={"E-mail"}
                  id={InputConstants.EMAIL}
                  name={InputConstants.EMAIL}
                  value={formik.values[InputConstants.EMAIL]}
                  onChange={formik.handleChange(InputConstants.EMAIL)}
                  disabled={formik.isSubmitting}
                  type={"email"}
                  placeholder={"nome@email.com.br"}
                  error={formik.errors[InputConstants.EMAIL]}
                />

                <Button
                  fullWidth
                  title={"Enviar link de recuperação"}
                  isLoading={formik.isSubmitting}
                  type="submit"
                  disabled={formik.isSubmitting}
                />

                <AuthenticationFooter
                  gridOptions={{
                    justifyContent: "center",
                  }}
                  items={[
                    {
                      title: "Lembrou a senha?",
                      subtitle: "Entrar",
                      onClick: onSignInFormClick,
                    },
                  ]}
                />
              </Stack>
            </form>
          )}
        </AuthenticationElement>
      </AuthenticationLayout>
    </>
  );
}
