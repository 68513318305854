import useWindowSize from "app/hooks/use-window-size.hook";
import {
  ComponentCard,
  ComponentContainer,
  HighlightDescription,
  HighlightTitle,
} from "./styles";
import { TaskHighlightOptions } from "./types";

export default function TaskHighlightList({ highlights }): JSX.Element {
  const { isMobile } = useWindowSize();

  return (
    <ComponentContainer
      style={{ "flex-direction": isMobile ? "column" : "row" }}
    >
      {highlights &&
        highlights.map((highlight: TaskHighlightOptions) => (
          <ComponentCard
            sx={{
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #dcdcdc !important",
              background: "#fff",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            <img
              src={`${process.env.REACT_APP_STRAPI_URL}${highlight.icon?.data?.attributes?.url}`}
              alt="HighlightIcon"
            />

            <HighlightTitle>{highlight.title}</HighlightTitle>

            {highlight.description && (
              <HighlightDescription>
                {highlight.description}
              </HighlightDescription>
            )}
          </ComponentCard>
        ))}
    </ComponentContainer>
  );
}
