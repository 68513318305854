import { CssBaseline } from "@mui/material";
import { CustomBox, Main } from "./styles";

export default function DashboardLayout({ children }) {
  return (
    <CustomBox>
      <CssBaseline />

      <Main>{children}</Main>
    </CustomBox>
  );
}
