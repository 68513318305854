import { InputConstants } from "app/constants/input.constants";
import YupSettings from "configuration/yup.configuration";
import {
  findCountryByCallingCode,
  isMaskedPhoneValueValid,
} from "resources/components/form/inputs/phone/helper";
import { PhoneCountryProps } from "resources/components/form/inputs/phone/types";

export const YupSchemaDefaultPasswordValidation: YupSettings.StringSchema<
  string,
  YupSettings.AnyObject,
  undefined,
  ""
> = YupSettings.string()
  .required()
  .min(8, `Número de caracteres precisa ser maior que 8`)
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-={}:'"|,.<>?]+/,
    "Sua senha precisa ter uma letra e um número"
  );

export const SignUpValidationSchema = YupSettings.object().shape({
  [InputConstants.NAME]: YupSettings.string().required(),
  [InputConstants.EMAIL]: YupSettings.string().required().email(),
  [InputConstants.PASSWORD]: YupSchemaDefaultPasswordValidation,
  [InputConstants.CALLING_CODE]: YupSettings.string().required(),
  [InputConstants.PHONE]: YupSettings.string()
    .required()
    .test(
      "test-is-phone-complete",
      "O número de telefone está incompleto",
      (value, context) => {
        const callingCode: string = context.parent[InputConstants.CALLING_CODE];
        const loadedCountry: PhoneCountryProps | undefined =
          findCountryByCallingCode(callingCode);

        const isPhoneValid: boolean = isMaskedPhoneValueValid(
          value,
          loadedCountry?.mask
        );

        return isPhoneValid;
      }
    ),
});
