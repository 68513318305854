import Apple from "resources/assets/icons/apple-store.svg";
import Google from "resources/assets/icons/google-store.svg";

export const SignUpQrCodeConstants = Object.freeze({
  BASE_URL: "https://app-links.ailu.com.br/base",

  INSTRUCTIONS: [
    "Abra a câmera no seu celular",
    "Escaneie o código QR acima",
    "Baixe o aplicativo",
  ],
  USEFUL_LINKS: [
    {
      icon: Google,
      link: "https://play.google.com/store/apps/details?id=odgo.app",
    },
    {
      icon: Apple,
      link: "https://apps.apple.com/app/ailu/id6479608815",
    },
  ],
});
