export function clickWithStopPropagation(
  event: any,
  customClick?: () => any
): void {
  event.stopPropagation();

  if (customClick) {
    customClick();
  }
}

export function clickWithPreventDefault(
  event: any,
  customClick?: () => any
): void {
  event.preventDefault();

  if (customClick) {
    customClick();
  }
}
