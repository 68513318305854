import { AppRoutes } from "app/enums/app-route.enum";
import { RouteTypes } from "navigators/enums/route-type.enum";
import {
  AppRoute,
  CommonRoute,
  PrivateRoute,
  PublicRoute,
} from "navigators/types";
import ViewClassifiedsArticle from "pages/common/article/classifieds";
import ViewFreeArticle from "pages/common/article/free";
import ViewOdNewsArticle from "pages/common/article/odnews";
import ViewTaskArticle from "pages/common/article/task";
import DownloadPage from "pages/common/download";
import SalesContactPage from "pages/common/sales-contact";
import SignInPage from "pages/public/authentication/sign-in";
import SignUpPage from "pages/public/authentication/sign-up";
import ForgotPasswordPage from "pages/public/password/forgot";
import ResetPasswordPage from "pages/public/password/reset";
import WelcomeSignInPage from "pages/public/welcome/sign-in";
import WelcomeSignUpPage from "pages/public/welcome/sign-up";
import DashboardLayout from "resources/layouts/dashboard";

const PublicRoutes: PublicRoute[] = [
  {
    path: AppRoutes.SignIn,
    element: WelcomeSignInPage,
    type: RouteTypes.Public,
  },
  {
    path: AppRoutes.SignUp,
    element: WelcomeSignUpPage,
    type: RouteTypes.Public,
  },
  {
    path: AppRoutes.SignInForm,
    element: SignInPage,
    type: RouteTypes.Public,
  },
  {
    path: AppRoutes.SignUpForm,
    element: SignUpPage,
    type: RouteTypes.Public,
  },
  {
    path: AppRoutes.ForgotPassword,
    element: ForgotPasswordPage,
    type: RouteTypes.Public,
  },
  {
    path: AppRoutes.ResetPassword,
    element: ResetPasswordPage,
    type: RouteTypes.Public,
  },
];

const PrivateRoutes: PrivateRoute[] = [
  {
    path: AppRoutes.Download,
    element: DownloadPage,
    type: RouteTypes.Private,
  },
];

const CommonRoutes: CommonRoute[] = [
  {
    path: AppRoutes.FreeArticle,
    element: ViewFreeArticle,
    layout: DashboardLayout,
    type: RouteTypes.Common,
  },
  {
    path: AppRoutes.OdNewsArticle,
    element: ViewOdNewsArticle,
    layout: DashboardLayout,
    type: RouteTypes.Common,
  },
  {
    path: AppRoutes.ClassifiedsArticle,
    element: ViewClassifiedsArticle,
    layout: DashboardLayout,
    type: RouteTypes.Common,
  },
  {
    path: AppRoutes.TaskArticle,
    element: ViewTaskArticle,
    layout: DashboardLayout,
    type: RouteTypes.Common,
  },
  {
    path: AppRoutes.SalesContact,
    element: SalesContactPage,
    type: RouteTypes.Common,
  },
];

export const AppRouteConstants: AppRoute[] = [
  ...PublicRoutes,
  ...PrivateRoutes,
  ...CommonRoutes,
];
