import React from "react";
import { StyledPopover } from "./styles";

export const CustomPopover = ({ children, content }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const child = React.Children.only(children);

  return (
    <div>
      {React.cloneElement(child, {
        onClick: handleClick,
      })}
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock
      >
        {content}
      </StyledPopover>
    </div>
  );
};
