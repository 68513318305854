import { MixPanelParamConstants } from "app/constants/mix-panel.constants";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { MixpanelEvents } from "app/enums/mix-panel.enum";
import { dispatchToMixpanel } from "app/providers/event.provider";
import { MutableRefObject, useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

const exclude: string[] = [];

export function useMixPanelTrackPageView() {
  const location: Location = useLocation();
  const { state: authenticationState } = useAuthentication();
  const lastPathnameRef: MutableRefObject<string> = useRef("");

  useEffect((): void => {
    const { pathname } = location;

    if (lastPathnameRef.current !== pathname) {
      lastPathnameRef.current = pathname;

      const shouldTrackPageView: boolean = !exclude.some((excludedRoute) =>
        pathname.includes(excludedRoute)
      );

      if (shouldTrackPageView) {
        dispatchToMixpanel(MixpanelEvents.PageView, {
          [MixPanelParamConstants.PAGE]: pathname,
          [MixPanelParamConstants.DAYS_AFTER_SIGNUP]:
            authenticationState.user?.days_after_signup,
        });
      }
    }
  }, [location, authenticationState.isAuthenticated]);
}
