import { useContextSelector } from "use-context-selector";
import { ArticleContext } from "..";
import { ArticleContextOptions, ContentResponse } from "../types";

export function useArticle<T extends ContentResponse>() {
  const state = useContextSelector(
    ArticleContext,
    (context: ArticleContextOptions) => ({
      ...context.state,
      contentResponse: context.state.contentResponse as T,
    })
  );

  return {
    state,
  };
}
