import { Grid } from "@mui/material";
import styled from "styled-components";

export const Container: any = styled(Grid)`
  && {
    padding: 24px 20px;
    border-radius: 16px;
    background: #f7f7f7;
  }
`;
