import { RestAPIConstants } from "app/constants/rest-api.constants";
import api from "app/providers/api.provider";
import { AxiosResponse } from "axios";

function useProfileService() {
  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async function updateUser(data: Object): Promise<AxiosResponse<any>> {
    return api.put(RestAPIConstants.PROFILE, data).catch((error: any) => {
      throw error;
    });
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async function updatePassword(data: Object): Promise<AxiosResponse<any>> {
    return api
      .put(RestAPIConstants.CHANGE_PASSWORD, data)
      .catch((error: any) => {
        throw error;
      });
  }

  return {
    updateUser,
    updatePassword,
  };
}

export default useProfileService;
