import { EnvironmentConfiguration } from "configuration/environment.configuration";

function useStrapiHelper() {
  function getImageUrl(imagePath: string): string {
    return `${EnvironmentConfiguration.STRAPI_URL}${imagePath}`;
  }

  function mergeContentId(hitId: number | string, slug: string): string {
    return `${hitId}-${slug}`;
  }

  function splitContentId(contentId: string): string {
    const contents = contentId.split("-");

    return contents[0];
  }

  return {
    getImageUrl,
    mergeContentId,
    splitContentId,
  };
}

export default useStrapiHelper;
