import {
  ClassifiedsArticle,
  FreeArticle,
  OdNewsArticle,
  TaskArticle,
} from "app/entities/article.entity";
import { HTMLTag } from "resources/components/handlers/helmet/types";

function useSEOHelper() {
  function getContentSEO(
    post?: FreeArticle | OdNewsArticle | TaskArticle | ClassifiedsArticle
  ): HTMLTag[] | undefined {
    if (!post?.attributes?.seo) return undefined;

    const { seo } = post?.attributes;

    const metaTags: HTMLTag[] = [];

    if (seo.metaDescription) {
      metaTags.push({
        tag: "meta",
        attributes: {
          name: "description",
          content: seo.metaDescription,
        },
      });

      metaTags.push({
        tag: "meta",
        attributes: {
          property: "og:description",
          content: seo.metaDescription,
        },
      });
    }

    if (seo.keywords) {
      metaTags.push({
        tag: "meta",
        attributes: {
          name: "keywords",
          content: seo.keywords,
        },
      });
    }

    if (seo.metaRobots) {
      metaTags.push({
        tag: "meta",
        attributes: {
          name: "robots",
          content: seo.metaRobots,
        },
      });
    }

    if (seo.metaViewport) {
      metaTags.push({
        tag: "meta",
        attributes: {
          name: "viewport",
          content: seo.metaViewport,
        },
      });
    }

    if (seo.canonicalURL) {
      metaTags.push({
        tag: "link",
        attributes: {
          rel: "canonical",
          href: seo.canonicalURL,
        },
      });
    }

    if (seo.metaTitle) {
      metaTags.push({
        tag: "meta",
        attributes: {
          property: "og:title",
          content: seo.metaTitle,
        },
      });
    }

    return metaTags.length ? metaTags : undefined;
  }

  return {
    getContentSEO,
  };
}

export default useSEOHelper;
