import { ContentCommentActions } from "../enums/actions.enum";
import {
  ContentCommentReducerActionOptions,
  ContentCommentReducerOptions,
} from "../types";

export const contentCommentReducer = (
  prevState: ContentCommentReducerOptions,
  action: ContentCommentReducerActionOptions
): ContentCommentReducerOptions => {
  switch (action.type) {
    case ContentCommentActions.RestoreParams:
      return {
        ...prevState,
        ...action,
      };

    default:
      return prevState;
  }
};
