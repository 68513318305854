import useWindowSize from "app/hooks/use-window-size.hook";

import { AppIcons } from "configuration/asset.configuration";
import { BodyText, FooterText, HeaderText } from "./styles";
import { Grid } from "@mui/material";

export function InfoMessage(): JSX.Element {
  const { isMobile } = useWindowSize();

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={isMobile ? "center" : "flex-start"}
      >
        {isMobile ? (
          <AppIcons.SalesContact.MobileSuccessIcon />
        ) : (
          <AppIcons.SalesContact.DesktopSuccessIcon />
        )}
      </Grid>
      <Grid container gap={"10px"}>
        <HeaderText>Fique atento ao Whatsapp!</HeaderText>
        <BodyText>
          Vamos <span>entrar em contato</span> em breve!
        </BodyText>
        <FooterText>
          O time de especialistas em imigração da Ailu irá entrar falar contigo
          através do número de Whatsapp cadastrado.
        </FooterText>
      </Grid>
    </>
  );
}
