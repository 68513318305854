import { Stack } from "@mui/material";
import { InputConstants } from "app/constants/input.constants";
import { MixPanelFieldConstants } from "app/constants/mix-panel.constants";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { useLinking } from "app/contexts/linking/use-linking.hook";
import { AppRoutes } from "app/enums/app-route.enum";
import { MixpanelEvents } from "app/enums/mix-panel.enum";
import usePhoneHelper, { formatCallingCode } from "app/helpers/phone.helper";
import { dispatchToMixpanel } from "app/providers/event.provider";
import { EnvironmentConfiguration } from "configuration/environment.configuration";
import { useFormik } from "formik";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AuthenticationFooter } from "resources/components/authentication/footer";
import { Button } from "resources/components/buttons/main";
import { PhoneInput } from "resources/components/form/inputs/phone";
import { PhoneInputConstants } from "resources/components/form/inputs/phone/constants";
import { usePhoneComplete } from "resources/components/form/inputs/phone/helper";
import { TextInput } from "resources/components/form/inputs/text";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthenticationLayout, {
  AuthenticationElement,
} from "resources/layouts/authentication";
import { SignUpValidationSchema } from "./schema.yup";
import { SignUpFormData } from "./types";

const PAGE_TITLE = "Digite seus dados para começar sua jornada";

export default function SignUpPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const { signUp } = useAuthentication();
  const { state: authenticationState } = useAuthentication();
  const { extractSimbolsAndSpaces } = usePhoneHelper();
  const { state: linkingState, removeParams: removeLinkingParams } =
    useLinking();

  const initialValues: SignUpFormData = {
    [InputConstants.NAME]:
      authenticationState.user?.name ??
      EnvironmentConfiguration.TEST_USER_NAME ??
      "",
    [InputConstants.EMAIL]:
      authenticationState.user?.email ??
      EnvironmentConfiguration.TEST_USER_EMAIL ??
      "",
    [InputConstants.PASSWORD]:
      EnvironmentConfiguration.TEST_USER_PASSWORD ?? "",
    [InputConstants.PHONE]: authenticationState.user?.phone ?? "",
    [InputConstants.CALLING_CODE]: formatCallingCode(
      authenticationState.user?.calling_code ??
        PhoneInputConstants.BRAZIL_CALLING_CODE
    ),
    [InputConstants.UTM_SOURCE]: linkingState.utmSource ?? null,
    [InputConstants.UTM_CAMPAIGN]: linkingState.utmCampaign ?? null,
    [InputConstants.GCLID]: linkingState.gclid ?? null,
    [InputConstants.FBCLID]: linkingState.fbclid ?? null,
    [InputConstants.REFERRER_ID]: linkingState.referrerId ?? null,
    [InputConstants.REFERRER]: linkingState.referrer ?? null,
  };

  const formik = useFormik<SignUpFormData>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: SignUpValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { isComplete } = usePhoneComplete(
    formik?.values[InputConstants.PHONE] ?? "",
    formik?.values[InputConstants.CALLING_CODE]
  );

  /**
   * @returns {Promise<void>}
   */
  async function handleSubmit(data: SignUpFormData): Promise<void> {
    try {
      const payload: any = {
        ...data,
        phone: data.phone ? extractSimbolsAndSpaces(data.phone) : "",
        calling_code: data.calling_code
          ? extractSimbolsAndSpaces(data.calling_code)
          : "",
      };

      await signUp(payload);

      removeLinkingParams();
    } catch (error) {
      console.error(error);
    } finally {
      if (data.referrer_id) {
        dispatchToMixpanel(MixpanelEvents.UserInvitedStarted, {
          [MixPanelFieldConstants.USER_WHO_JOINED]: data.email,
        });
      }
    }
  }

  function onSignInClick(): void {
    navigate(AppRoutes.SignIn);
  }

  function onSignUpClick(): void {
    navigate(AppRoutes.SignUp);
  }

  function onForgotPasswordClick(): void {
    navigate(AppRoutes.ForgotPassword);
  }

  return (
    <>
      <HelmetHandler
        options={{
          title: "Criar Conta Ailu",
        }}
      />

      <AuthenticationLayout>
        <AuthenticationElement
          title={PAGE_TITLE}
          onBackButtonClick={onSignUpClick}
          progressBarValue={100}
        >
          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <Stack spacing={"24px"}>
              <TextInput
                label={"Nome completo"}
                id={InputConstants.NAME}
                name={InputConstants.NAME}
                value={formik.values[InputConstants.NAME]}
                onChange={formik.handleChange(InputConstants.NAME)}
                disabled={formik.isSubmitting}
                type={"text"}
                placeholder={"Pero Vaz de Caminha"}
                error={formik.errors[InputConstants.NAME]}
              />

              <TextInput
                label={"E-mail"}
                id={InputConstants.EMAIL}
                name={InputConstants.EMAIL}
                value={formik.values[InputConstants.EMAIL]}
                onChange={formik.handleChange(InputConstants.EMAIL)}
                disabled={formik.isSubmitting}
                type={"email"}
                placeholder={"perovaz@dominio.com.br"}
                error={formik.errors[InputConstants.EMAIL]}
              />

              <TextInput
                label={"Senha"}
                secondaryLabel={"Esqueci a senha"}
                onSecondaryLabelClick={onForgotPasswordClick}
                id={InputConstants.PASSWORD}
                name={InputConstants.PASSWORD}
                value={formik.values[InputConstants.PASSWORD]}
                onChange={formik.handleChange(InputConstants.PASSWORD)}
                disabled={formik.isSubmitting}
                type={"password"}
                placeholder={"Digite sua  senha"}
                error={formik.errors[InputConstants.PASSWORD]}
              />

              <PhoneInput
                label={"WhatsApp"}
                callingCodeValue={formik.values[InputConstants.CALLING_CODE]}
                selectProps={{
                  name: InputConstants.CALLING_CODE,
                  onChange: (selectedItem: any) => {
                    const { value } = selectedItem;

                    formik.setFieldValue(InputConstants.CALLING_CODE, value);
                    formik.setFieldValue(InputConstants.PHONE, "");
                  },
                  isMulti: false,
                }}
                disabled={formik.isSubmitting}
                inputProps={{
                  name: InputConstants.PHONE,
                  value: formik.values[InputConstants.PHONE],
                  onChange: (event) => {
                    formik.setFieldValue(
                      InputConstants.PHONE,
                      event.target.value
                    );
                  },
                  required: true,
                  disabled: formik.isSubmitting,
                  type: "text",
                }}
                endIcon={isComplete}
                error={
                  formik.errors[InputConstants.CALLING_CODE] ??
                  formik.errors[InputConstants.PHONE]
                }
              />

              <Button
                type="submit"
                fullWidth
                title={"Criar minha conta"}
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />

              <AuthenticationFooter
                gridOptions={{
                  justifyContent: "center",
                }}
                items={[
                  {
                    title: "Já possui uma conta?",
                    subtitle: "Entrar",
                    onClick: onSignInClick,
                  },
                ]}
              />
            </Stack>
          </form>
        </AuthenticationElement>
      </AuthenticationLayout>
    </>
  );
}
