import { useMutation, useQuery } from "@apollo/client";
import {
  generateCommentQuery,
  generateCreateCommentMutation,
  generatePaginatedCommentsQuery,
} from "app/queries/content-comment.graphql";
import { dispatchSuccessToastMessage } from "configuration/toast.configuration";
import { FC, useEffect, useReducer, useState } from "react";
import { createContext } from "use-context-selector";
import { useAuthentication } from "../../authentication/use-authentication.hook";
import { ContentCommentActions } from "./enums/actions.enum";
import { useTypedCommentQuery } from "./hooks/typed-comment-query.hook";
import { contentCommentReducer } from "./reducers/content-comment.reducer";
import {
  ContentCommentContextOptions,
  ContentCommentProviderOptions,
  ContentCommentReducerOptions,
} from "./types";

export const ContentCommentContext =
  createContext<ContentCommentContextOptions>(
    {} as ContentCommentContextOptions
  );

const contentCommentInitialState: ContentCommentReducerOptions = {
  contentCommentResponse: undefined,
  comments: undefined,
};

const COMMENTS_PER_LOAD: number = 10;

const ContentCommentProvider: FC<ContentCommentProviderOptions> = ({
  contentId,
  mainAttribute,
  queryAttribute,
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(
    contentCommentReducer,
    contentCommentInitialState
  );

  const [commentsLimit, setCommentsLimit] = useState(COMMENTS_PER_LOAD);

  const { state: authenticationState } = useAuthentication();

  const getCommentByUserQuery = generateCommentQuery(
    mainAttribute,
    queryAttribute
  );
  const commentsQuery = generatePaginatedCommentsQuery(
    mainAttribute,
    queryAttribute
  );

  const createCommentMutation = generateCreateCommentMutation(mainAttribute);

  const fetchPayload = {
    skip: !authenticationState.user,
    variables: {
      userId: {
        eq: Number(authenticationState.user?.id),
      },
      contentId: Number(contentId),
    },
  };
  const { data: contentCommentResponse, refetch: refetchComment } =
    useTypedCommentQuery(mainAttribute, getCommentByUserQuery, fetchPayload);
  const [createComment] = useMutation(createCommentMutation);

  const commentsPayload = {
    variables: {
      contentId,
      limit: commentsLimit,
      start: 0,
    },
    skip: !contentId,
  };
  const { data: commentsData, refetch: refetchComments } = useQuery(
    commentsQuery,
    commentsPayload
  );

  function handleLoadMore(): void {
    setCommentsLimit((prevLimit: number) => prevLimit + COMMENTS_PER_LOAD);
  }

  function handleSuccessfulComment() {
    dispatchSuccessToastMessage("Comment enviado com sucesso!");

    refetchComment();
    refetchComments();
  }

  async function handleCommentResponse(data: any): Promise<void> {
    try {
      if (!authenticationState.user) {
        return;
      }

      const commentResult = await createComment({
        variables: {
          data: {
            odgo_user_id: Number(authenticationState.user.id),
            [queryAttribute]: contentId,
            ...data,
          },
        },
      });

      if (commentResult) {
        handleSuccessfulComment();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (contentCommentResponse) {
      dispatch({
        type: ContentCommentActions.RestoreParams,
        contentCommentResponse:
          contentCommentResponse?.[mainAttribute]?.data?.[0],
        comments: commentsData?.[mainAttribute]?.data,
      });
    }
  }, [contentCommentResponse, commentsData]);

  return (
    <ContentCommentContext.Provider
      value={{
        state,
        handleCommentResponse,
        handleLoadMore,
      }}
    >
      {children}
    </ContentCommentContext.Provider>
  );
};

export default ContentCommentProvider;
