import ReactMarkdown from "react-markdown";
import { RichTextContainer } from "./styles";
import rehypeRaw from "rehype-raw";

export default function RichText({ body, style = {} }): JSX.Element {
  return (
    <RichTextContainer style={style}>
      <ReactMarkdown
        children={body}
        rehypePlugins={[rehypeRaw]}
        components={{
          img: ({ src, alt }) => {
            const fullSrc = src?.startsWith("http")
              ? src
              : `${process.env.REACT_APP_STRAPI_URL}${src}`;
            return <img src={fullSrc} alt={alt} />;
          },
        }}
      />
    </RichTextContainer>
  );
}
