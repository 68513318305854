export enum ArticleContentType {
  RichText = "ComponentArticleContentRichText",
  List = "ComponentArticleContentList",
  OdNewsList = "ComponentArticleContentOdNewsList",
  TaskHighlightList = "ComponentArticleContentTaskHighlightList",
  TaskStepByStep = "ComponentArticleContentTaskStepByStep",
  TaskDocumentList = "ComponentArticleContentTaskDocumentList",
  Button = "ComponentArticleContentButton",
  Video = "ComponentArticleContentVideo",
}
