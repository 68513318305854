import * as Sentry from "@sentry/react";
import { EnvironmentTypes } from "app/enums/environment-type.enum";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ScrollWrapperHandler } from "resources/components/handlers/scroll-wrapper";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENV === EnvironmentTypes.PRODUCTION) {
  Sentry.init({
    dsn: "https://cf590e68bf834cba8205d0cffac753ca@o4505313931952128.ingest.sentry.io/4505320101969920",
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          process.env.REACT_APP_API_URL ?? "",
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollWrapperHandler>
        <App />
      </ScrollWrapperHandler>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
