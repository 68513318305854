import { useContextSelector } from "use-context-selector";
import { AuthenticationContext } from ".";
import { AuthenticationContextOptions } from "./types";

export function useAuthentication() {
  const signIn = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) => authentication.signIn
  );

  const signInByAccessToken = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) =>
      authentication.signInByAccessToken
  );

  const signUp = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) => authentication.signUp
  );

  const signOut = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) => authentication.signOut
  );

  const turnSignOutFlagOff = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) =>
      authentication.turnSignOutFlagOff
  );

  const openSignOutDialog = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) =>
      authentication.openSignOutDialog
  );

  const changeNextBehavior = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) =>
      authentication.changeNextBehavior
  );

  const changeUser = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) => authentication.changeUser
  );

  const state = useContextSelector(
    AuthenticationContext,
    (authentication: AuthenticationContextOptions) => authentication.state
  );

  return {
    state,

    signIn,
    signInByAccessToken,
    signUp,
    signOut,
    turnSignOutFlagOff,
    openSignOutDialog,
    changeNextBehavior,
    changeUser,
  };
}
