import { InputConstants } from "app/constants/input.constants";
import YupSettings from "configuration/yup.configuration";
import {
  findCountryByCallingCode,
  isMaskedPhoneValueValid,
} from "resources/components/form/inputs/phone/helper";
import { PhoneCountryProps } from "resources/components/form/inputs/phone/types";

export const CompleteSignUpYupFormSchema = YupSettings.object().shape({
  [InputConstants.CALLING_CODE]: YupSettings.string().required(),
  [InputConstants.PHONE]: YupSettings.string()
    .required()
    .test(
      "test-is-phone-complete",
      "O número de telefone está incompleto",
      (value, context) => {
        const callingCode: string = context.parent[InputConstants.CALLING_CODE];
        const loadedCountry: PhoneCountryProps | undefined =
          findCountryByCallingCode(callingCode);

        const isPhoneValid: boolean = isMaskedPhoneValueValid(
          value,
          loadedCountry?.mask
        );

        return isPhoneValid;
      }
    ),
});
