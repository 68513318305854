import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 16px;
    background: var(--foundation-gray-gray-100, #f3f4f6);
    border-radius: var(--radius-8, 8px);
    padding: 13px 18px 13px 13px;
    width: 100%;
    max-width: 348px;
    cursor: pointer;
  }
`;

export const IconContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--primria-escuros-preto-cinza-e-branco-100, #fff);
    width: 100%;
    max-width: 40px;
    height: 40px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.14px;
    color: var(--foundation-gray-gray-700, #374151);
    max-width: 210px;
  }
`;
