export const APP_SCHEME = "@AILU:";

export enum StorageKeys {
  AccessToken = `${APP_SCHEME}token` as any,
  User = `${APP_SCHEME}user` as any,

  UtmSource = `${APP_SCHEME}utm_source` as any,
  UtmCampaign = `${APP_SCHEME}utm_campaign` as any,
  Gclid = `${APP_SCHEME}gclid` as any,
  Fbclid = `${APP_SCHEME}fbclid` as any,
  ReferrerId = `${APP_SCHEME}referrer_id` as any,
  Referrer = `${APP_SCHEME}referrer` as any,
  AffiliateRefer = `${APP_SCHEME}affiliate_refer` as any,
  Coupon = `${APP_SCHEME}coupon` as any,
  ContentViewed = `${APP_SCHEME}contentViewed` as any,
}
