import { Stack } from "@mui/material";
import { InputConstants } from "app/constants/input.constants";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";
import { AppRoutes } from "app/enums/app-route.enum";
import { useFormik } from "formik";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AuthenticationFooter } from "resources/components/authentication/footer";
import { Button } from "resources/components/buttons/main";
import { TextInput } from "resources/components/form/inputs/text";
import { HelmetHandler } from "resources/components/handlers/helmet";
import AuthenticationLayout, {
  AuthenticationElement,
} from "resources/layouts/authentication";
import { SignInInitialValues } from "./constants";
import { SignInValidationSchema } from "./schema.yup";
import { SignInFormData } from "./types";

const PAGE_TITLE = "Entrar com e-mail";

export default function SignInPage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const { signIn } = useAuthentication();

  const formik = useFormik<SignInFormData>({
    initialValues: SignInInitialValues,
    onSubmit: handleSubmit,
    validationSchema: SignInValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  /**
   * @returns {Promise<void>}
   */
  async function handleSubmit(data: SignInFormData): Promise<void> {
    try {
      await signIn(data);
    } catch (error) {
      console.error(error);
    }
  }

  function onSignInClick(): void {
    navigate(AppRoutes.SignIn);
  }

  function onSignUpClick(): void {
    navigate(AppRoutes.SignUp);
  }

  function onForgotPasswordClick(): void {
    navigate(AppRoutes.ForgotPassword);
  }

  return (
    <>
      <HelmetHandler
        options={{
          title: PAGE_TITLE,
        }}
      />

      <AuthenticationLayout>
        <AuthenticationElement
          title={PAGE_TITLE}
          onBackButtonClick={onSignInClick}
        >
          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <Stack spacing={"24px"}>
              <TextInput
                label={"E-mail"}
                id={InputConstants.EMAIL}
                name={InputConstants.EMAIL}
                value={formik.values[InputConstants.EMAIL]}
                onChange={formik.handleChange(InputConstants.EMAIL)}
                disabled={formik.isSubmitting}
                type={"email"}
                placeholder={"nome@email.com.br"}
                error={formik.errors[InputConstants.EMAIL]}
              />

              <TextInput
                label={"Senha"}
                secondaryLabel={"Esqueci a senha"}
                onSecondaryLabelClick={onForgotPasswordClick}
                id={InputConstants.PASSWORD}
                name={InputConstants.PASSWORD}
                value={formik.values[InputConstants.PASSWORD]}
                onChange={formik.handleChange(InputConstants.PASSWORD)}
                disabled={formik.isSubmitting}
                type={"password"}
                placeholder={"Digite sua  senha"}
                error={formik.errors[InputConstants.PASSWORD]}
              />

              <Button
                fullWidth
                title={"Acessar minha conta"}
                isLoading={formik.isSubmitting}
                type="submit"
                disabled={formik.isSubmitting}
              />

              <AuthenticationFooter
                gridOptions={{
                  justifyContent: "center",
                }}
                items={[
                  {
                    title: "Ainda não tem uma conta?",
                    subtitle: "Criar Conta",
                    onClick: onSignUpClick,
                  },
                ]}
              />
            </Stack>
          </form>
        </AuthenticationElement>
      </AuthenticationLayout>
    </>
  );
}
