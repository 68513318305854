import { AppRoutes } from "app/enums/app-route.enum";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

const GO_BACK: number = -1;

function useNavigationProvider() {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const isFirstPage: boolean = location.key === "default";

  /**
   * @returns {void}
   */
  function onBackButtonPress(): void {
    if (isFirstPage) {
      navigate(AppRoutes.SignIn);
    } else {
      navigate(GO_BACK);
    }
  }

  return {
    onBackButtonPress,
    isFirstPage,
    navigate,
  };
}

export default useNavigationProvider;
