import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const ComponentContainer: any = styled(Grid)`
  && {
    display: flex;
    gap: 16px;
    margin-top: 24px;
  }
`;

export const ComponentCard: any = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px;
    width: 100%;

    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    justify-content: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
`;

export const HighlightTitle: any = styled(Typography)`
  && {
    font-family: "InterMedium", sans-serif;
    font-size: 20px;
    line-height: 40.5px; /* 202.5% */
    color: var(--Black, #000);
  }
`;

export const HighlightDescription: any = styled(Typography)`
  && {
    font-family: "InterRegular", sans-serif;
    font-size: 16px;
    line-height: 19.6px; /* 122.5% */
    letter-spacing: -0.32px;
    color: var(--primria-escuros-preto-cinza-e-branco-600, #595959);
    text-align: center;
  }
`;
