import RichText from "../rich-text";
import { ComponentContainer } from "../styles";
import {
  Step,
  StepBody,
  StepColumnLeft,
  StepColumnRight,
  StepLine,
  StepNumber,
  StepTitle,
  TitleTypography,
} from "./styles";
import { TaskStepByStepOptions } from "./types";

export default function TaskStepByStep({ steps }): JSX.Element {
  const { steps: stepsList, description } = steps;

  return (
    <ComponentContainer sx={{ gap: "0 !important" }}>
      <TitleTypography>Passo a passo</TitleTypography>

      <RichText style={{ margin: "24px 0px" }} body={description} />

      {stepsList.map((step: TaskStepByStepOptions, index: any) => {
        const lastStep = index === stepsList.length - 1;

        return (
          <Step>
            <StepColumnLeft>
              <StepNumber>
                <p>{index + 1}</p>
              </StepNumber>
              <StepLine className={lastStep ? "lastStep" : ""} />
            </StepColumnLeft>

            <StepColumnRight className={lastStep ? "lastStep" : ""}>
              <StepTitle>{step.title}</StepTitle>

              <StepBody>
                <RichText body={step.body} />
              </StepBody>
            </StepColumnRight>
          </Step>
        );
      })}
    </ComponentContainer>
  );
}
