import axios from "axios";
import { interceptorSentry } from "./event.provider";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    interceptorSentry(error);
    if (error.response.status === 401) {
      if (error.response.data?.code === "token expired") {
      } else {
        localStorage.removeItem("@AILU:token");
        localStorage.removeItem("@AILU:user");
      }
    }

    return Promise.reject(error);
  }
);

export default api;
