import { CircularProgress, circularProgressClasses } from "@mui/material";
import { AnimationHandlerOptions } from "./types";
import { Container } from "./styles";

export function AnimationHandler({}: AnimationHandlerOptions): JSX.Element {
  return <CircularProgress size={24} sx={{ color: "var(--primary-900)" }} />;
}

export function CenteredAnimationHandler({}: AnimationHandlerOptions): JSX.Element {
  return (
    <Container>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "var(--primary-900)",
          opacity: 0.5,
        }}
        size={60}
        thickness={4}
        value={100}
      />
      <CircularProgress
        sx={{
          color: "var(--primary-900)",

          position: "absolute",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={60}
        thickness={4}
      />
    </Container>
  );
}
