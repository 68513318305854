import { AuthenticationContextConstants } from "./constants";
import {
  AuthenticationReducerActionOptions,
  AuthenticationReducerOptions,
} from "./types";

export const authenticationReducer = (
  prevState: AuthenticationReducerOptions,
  action: AuthenticationReducerActionOptions
): AuthenticationReducerOptions => {
  switch (action.type) {
    case AuthenticationContextConstants.RESTORE_TOKEN:
      return {
        ...prevState,
        isAuthenticated: action.isAuthenticated,

        user: action.user,
        isLoading: false,
        isSignOut: false,
        nextBehavior: action.nextBehavior,
      };

    case AuthenticationContextConstants.SIGN_IN:
      return {
        ...prevState,
        isAuthenticated: true,

        isLoading: false,
        isSignOut: false,
        nextBehavior: action.nextBehavior,

        user: action.user,
      };

    case AuthenticationContextConstants.SIGN_OUT:
      return {
        ...prevState,
        isAuthenticated: false,
        isLoading: false,
        isSignOut: true,
        user: undefined,
      };

    case AuthenticationContextConstants.TURN_SIGN_OUT_FLAG_OFF:
      return {
        ...prevState,
        isSignOut: false,
      };

    case AuthenticationContextConstants.CHANGE_NEXT_BEHAVIOR:
      return {
        ...prevState,
        nextBehavior: action.nextBehavior,
      };

    case AuthenticationContextConstants.CHANGE_USER:
      return {
        ...prevState,
        user: action.user,
        nextBehavior: action.nextBehavior,
      };
    default:
      return prevState;
  }
};
