import { Box } from "@mui/material";
import { Container, FeedbackButton, StyledTypography } from "./styles";
import { FeedbackQuestionProps } from "./types";

export function FeedbackQuestion({
  question,
  response,
  onNo,
  onYes,
}: FeedbackQuestionProps): JSX.Element {
  return (
    <Container gap={"8px"}>
      <StyledTypography>{question}</StyledTypography>

      <Box>
        <FeedbackButton
          variant="outlined"
          onClick={onNo}
          sx={{ marginRight: "8px" }}
          selected={response === false}
        >
          {"Não"}
        </FeedbackButton>

        <FeedbackButton
          variant="outlined"
          onClick={onYes}
          selected={response === true}
        >
          {"Sim"}
        </FeedbackButton>
      </Box>
    </Container>
  );
}
