import { ContentFeedbackMainAttributes } from "app/contexts/contents/feedback/enums/main-attribute.enum";
import gql from "graphql-tag";
import { ArticleQueryAttributes } from "resources/packages/contents/enums/article-query-attribute.enum";
import {
  singularize,
  titleCase,
} from "resources/packages/contents/helpers/article.helper";

export function generateFeedbackQuery(
  mainAttribute: ContentFeedbackMainAttributes,
  queryAttribute: ArticleQueryAttributes
) {
  return gql`
    query GetFeedbackByUser($userId: IntFilterInput!, $contentId: ID!) {
      ${mainAttribute}(
        filters: { odgo_user_id: $userId, ${queryAttribute}: { id: { eq: $contentId } } }
        pagination: { limit: 1 }
      ) {
        data {
          id
          attributes {
            comment
            odgo_user_id
            is_liked
            is_wrong
            ${queryAttribute} {
              data {
                id
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  `;
}

export function generateCountLikesQuery(
  mainAttribute: ContentFeedbackMainAttributes,
  queryAttribute: ArticleQueryAttributes
) {
  return gql`
    query CountLikes($contentId: ID!) {
      ${mainAttribute}(
        filters: {
          is_liked: { eq: true }
          ${queryAttribute}: { id: { eq: $contentId } }
        }
      ) {
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;
}

export function generateCountDislikesQuery(
  mainAttribute: ContentFeedbackMainAttributes,
  queryAttribute: ArticleQueryAttributes
) {
  return gql`
    query CountDislikes($contentId: ID!) {
      ${mainAttribute}(
        filters: {
          is_liked: { eq: false }
          ${queryAttribute}: { id: { eq: $contentId } }
        }
      ) {
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;
}

export function generateCreateFeedbackMutation(
  mainAttribute: ContentFeedbackMainAttributes
) {
  const mutationName = titleCase(singularize(mainAttribute));

  return gql`
    mutation CreateFeedback($data: ${mutationName}Input!) {
      create${mutationName}(data: $data) {
        data {
          id
          attributes {
            comment
            odgo_user_id
            is_liked
            is_wrong
            createdAt
            updatedAt
          }
        }
      }
    }
  `;
}

export function generateUpdateFeedbackMutation(
  mainAttribute: ContentFeedbackMainAttributes
) {
  const mutationName = titleCase(singularize(mainAttribute));

  return gql`
    mutation UpdateFeedback($id: ID!, $data: ${mutationName}Input!) {
      update${mutationName}(id: $id, data: $data) {
        data {
          id
          attributes {
            comment
            odgo_user_id
            is_liked
            is_wrong
          }
        }
      }
    }
  `;
}
