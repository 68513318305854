import { FetchResult, useMutation, useQuery } from "@apollo/client";
import {
  generateCountDislikesQuery,
  generateCountLikesQuery,
  generateCreateFeedbackMutation,
  generateFeedbackQuery,
  generateUpdateFeedbackMutation,
} from "app/queries/content-feedback.graphql";
import { dispatchSuccessToastMessage } from "configuration/toast.configuration";
import { FC, useEffect, useReducer } from "react";
import { createContext } from "use-context-selector";
import { useAuthentication } from "../../authentication/use-authentication.hook";
import { ContentFeedbackActions } from "./enums/actions.enum";
import { useTypedFeedbackQuery } from "./hooks/typed-feedback-query.hook";
import { contentFeedbackReducer } from "./reducers/content-feedback.reducer";
import {
  ContentFeedbackContextOptions,
  ContentFeedbackProviderOptions,
  ContentFeedbackReducerOptions,
} from "./types";

export const ContentFeedbackContext =
  createContext<ContentFeedbackContextOptions>(
    {} as ContentFeedbackContextOptions
  );

const contentFeedbackInitialState: ContentFeedbackReducerOptions = {
  contentFeedbackResponse: undefined,
  likes: 0,
  dislikes: 0,
  isLiked: null,
  isDisliked: null,
};

const ContentFeedbackProvider: FC<ContentFeedbackProviderOptions> = ({
  contentId,
  mainAttribute,
  queryAttribute,
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(
    contentFeedbackReducer,
    contentFeedbackInitialState
  );

  const { state: authenticationState } = useAuthentication();

  const getFeedbackByUserQuery = generateFeedbackQuery(
    mainAttribute,
    queryAttribute
  );
  const countLikesQuery = generateCountLikesQuery(
    mainAttribute,
    queryAttribute
  );
  const countDislikesQuery = generateCountDislikesQuery(
    mainAttribute,
    queryAttribute
  );
  const createFeedbackMutation = generateCreateFeedbackMutation(mainAttribute);
  const updateFeedbackMutation = generateUpdateFeedbackMutation(mainAttribute);

  const fetchPayload = {
    skip: !authenticationState.user,
    variables: {
      userId: {
        eq: Number(authenticationState.user?.id),
      },
      contentId: Number(contentId),
    },
  };
  const { data: contentFeedbackResponse, refetch: refetchFeedback } =
    useTypedFeedbackQuery(mainAttribute, getFeedbackByUserQuery, fetchPayload);
  const [createFeedback] = useMutation(createFeedbackMutation);
  const [updateFeedback] = useMutation(updateFeedbackMutation);

  const interactionsPayload = {
    variables: { contentId },
    skip: !contentId,
  };
  const { data: likesData, refetch: refetchLikes } = useQuery(
    countLikesQuery,
    interactionsPayload
  );
  const { data: dislikesData, refetch: refetchDislikes } = useQuery(
    countDislikesQuery,
    interactionsPayload
  );

  const likes: number = likesData?.[mainAttribute].meta.pagination.total ?? 0;
  const dislikes: number =
    dislikesData?.[mainAttribute].meta.pagination.total ?? 0;
  const isLiked: boolean =
    contentFeedbackResponse?.[mainAttribute].data[0]?.attributes.is_liked ===
    true;
  const isDisliked: boolean =
    contentFeedbackResponse?.[mainAttribute].data[0]?.attributes.is_liked ===
    false;

  function handleSuccessfulFeedback() {
    dispatchSuccessToastMessage("Feedback enviado com sucesso!");
    refetchFeedback();
    refetchLikes();
    refetchDislikes();
  }

  async function handleFeedbackResponse(data: any): Promise<void> {
    try {
      if (!authenticationState.user) return;

      const receivedContentId =
        contentFeedbackResponse?.[mainAttribute]?.data?.[0]?.id;
      let feedbackResult: FetchResult<any>;

      if (receivedContentId) {
        feedbackResult = await updateFeedback({
          variables: {
            id: receivedContentId,
            data: { ...data },
          },
        });
      } else {
        feedbackResult = await createFeedback({
          variables: {
            data: {
              odgo_user_id: Number(authenticationState.user.id),
              [queryAttribute]: contentId,
              ...data,
            },
          },
        });
      }

      if (feedbackResult) {
        handleSuccessfulFeedback();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (contentFeedbackResponse) {
      dispatch({
        type: ContentFeedbackActions.RestoreParams,
        contentFeedbackResponse:
          contentFeedbackResponse?.[mainAttribute]?.data?.[0],
        likes,
        dislikes,
        isLiked,
        isDisliked,
      });
    }
  }, [contentFeedbackResponse, likesData, dislikesData]);

  return (
    <ContentFeedbackContext.Provider
      value={{
        state,
        handleFeedbackResponse,
      }}
    >
      {children}
    </ContentFeedbackContext.Provider>
  );
};

export default ContentFeedbackProvider;
