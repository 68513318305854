import { ReactComponent as LinkIcon } from "resources/assets/icons/link-icon.svg";
import { ComponentCard, ComponentContainer } from "../styles";
import {
  ClassifiedsCardHeader,
  ClassifiedsCardLink,
  ClassifiedsCardTitle,
} from "./styles";
import { ClassifiedsOptions } from "./types";
import RichText from "../rich-text";

export default function ClassifiedsList({ items }): JSX.Element {
  return (
    <ComponentContainer>
      {items.map((item: ClassifiedsOptions) => (
        <ComponentCard
          key={item.title}
          sx={{ alignItems: "flex-start !important" }}
        >
          <ClassifiedsCardHeader>
            <ClassifiedsCardTitle>{item.title}</ClassifiedsCardTitle>
          </ClassifiedsCardHeader>

          <RichText body={item.description} />

          <ClassifiedsCardLink
            href={item.link}
            rel="noreferrer"
            target="_blank"
          >
            {"Confira a vaga"}
            <LinkIcon />
          </ClassifiedsCardLink>
        </ComponentCard>
      ))}
    </ComponentContainer>
  );
}
